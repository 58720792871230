import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  InputAdornment,
  TextField,
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  ClickAwayListener,
  Tooltip,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  styled,
  Link,
  Select,
  FormControl,
  InputBase,
} from "@mui/material";
// import BasicModal from "../../Modals/NewFleetDemandModal";
import "./TableCss.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaymentExtraDetails from "../PaymentsExtraDetails";
import "animate.css/animate.min.css";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

export default function TableImplementation() {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);

  const [fleetDemandListData, setFleetDemandListData] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageRange = 5; // Number of pages to display in the pagination
  const [anchorNl, setAnchorNl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseDilog = () => {
    setOpen(false);
  };

  const notificationhandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorNl(event.currentTarget);
  };

  const notificationopen = Boolean(anchorNl);
  const notificationhandleClose = () => {
    setAnchorNl(null);
  };
  //   useEffect(() => {
  //     setLoading(true);
  //     const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
  //     NewFleetDemandApi.api
  //       ?.hubFleetRequestList(page + 1, rowsPerPage, apiUrl)
  //       .then((e) => {
  //         setLoading(false);
  //         if (
  //           Array.isArray(e.fleetRequestList) &&
  //           e.fleetRequestList.length > 0
  //         ) {
  //           setFleetDemandListData(e.fleetRequestList);
  //           setTotalCount(e.totalCount);
  //         } else {
  //           setShowSnackbar(true);
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         console.error("API call error: ", error);
  //       });
  //   }, [searchVal, page, rowsPerPage]);

  const dummyData = [
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
    {
      name: "Dhruv Singh",
      orderDate: "14:05, 12/01/2024",
      mobile: "7463737829",
      remark:
        "Frontend team is working very effeciently and with full dedication",
      Category: "Completed",
    },
  ];

  const pageCount = Math.ceil(dummyData.length / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
    setPage(newPageNumber);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  return (
    <>
      {/* {loading ? (
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          ) : (
            <> */}
      <div className="flex items-center justify-between py-4 px-3 border-b	border-slate-200	border-solid">
        <div className="flex items-center w-1/6 border border-slate-500	border-solid	rounded-full px-2 py-1">
          <div>
            <Typography className="font-inter text-[#727597] text-xs mr-2 font-normal">
              Status:{" "}
            </Typography>
          </div>
          <FormControl fullWidth>
            <select id="merchant">
              <option value="" selected>
                All
              </option>

              <option value="all">All</option>
            </select>
          </FormControl>
        </div>
        <div className="border-2 rounded-2xl px-4">
          <InputBase
            placeholder="Search by Id,name or date"
            className="flex-grow"
            inputProps={{ "aria-label": "search" }}
          />
          <SearchIcon
            style={{ color: "#727597" }}
            className=" cursor-pointer ml-4"
          />
        </div>
      </div>
      <TableContainer className="">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                #
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Rider’s Name
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Created Date & Time
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Rider’s Mobile
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Remarks
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Action
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData
              ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((row: any, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={index}
                    className={
                      expandedRows.includes(row.orderId) ? "expanded-row" : ""
                    }
                  >
                    <TableCell>
                      <Checkbox style={{ color: "#E9E9E9" }} />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.orderDate}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.mobile}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                        maxWidth: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.remark}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={row.Category}
                        size="small"
                        style={{
                          backgroundColor: "#00CB6026",
                          color: "#00CB60",
                          paddingInline: "16px",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <div>
                        <Tooltip
                          title="Notification"
                          sx={{ backgroundColor: "#uuuuu" }}
                        >
                          <IconButton
                            onClick={notificationhandleClick}
                            sx={{ ml: 2 }}
                            aria-controls={
                              notificationopen ? "account-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              notificationopen ? "true" : undefined
                            }
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          anchorEl={anchorNl}
                          id="account-menu"
                          elevation={1}
                          open={notificationopen}
                          onClose={notificationhandleClose}
                          onClick={notificationhandleClose}
                          MenuListProps={{
                            disablePadding: true,
                          }}
                        >
                          <MenuItem
                            sx={{
                              color: "#00CB60",
                              "&:hover": {
                                backgroundColor: "#00CB60",
                                color: "#fff",
                              },
                            }}
                          >
                            <DoneIcon className="mr-3" /> Solved
                          </MenuItem>

                          <MenuItem
                            sx={{
                              color: "#F94932",
                              "&:hover": {
                                backgroundColor: "#F94932",
                                color: "#fff",
                              },
                            }}
                          >
                            <ClearIcon className="mr-3" /> Reject
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pt-4 pb-2 pr-5">
        <div
          className="pagination"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {/* <select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            style={{
              backgroundColor: "#EBEBEB",
              fontFamily: "Rubik",
              fontSize: "16px",
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select> */}
          <div className="flex">
            <img
              src="/assets/icons/leftArrow.svg"
              alt=""
              onClick={() => handlePageChange(page - 1)}
            />
            {renderPageNumbers()}
            <img
              src="/assets/icons/rightArrow.svg"
              alt=""
              onClick={() => handlePageChange(page + 1)}
              className="ml-1"
            />
          </div>
        </div>
      </div>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert sx={{ width: "10" }} onClose={handleClose} severity="error">
          No data found
        </Alert>
      </Snackbar>
      {/* </>
          )} */}
    </>
  );
}
