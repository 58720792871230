import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Chip,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RiderNominationApi } from "../../API/RiderNomination";
import ReturnToDashboardModal from "./ReturnToDashboardModal";
import { OrderStatusApi } from "../../API/OrderStatus";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

interface UpdateOrderModalProps {
  orderNumber: number;
  sourceLat: number;
  sourceLng: number;
  destinationLat: number;
  destinationLng: number;
}
export default function UpdateOrderModal({
  orderNumber,
  sourceLat,
  sourceLng,
  destinationLat,
  destinationLng,
}: UpdateOrderModalProps) {
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState<number | undefined>();
  const [comments, setComments] = useState<string>("");
  const [sourceLatitude, setSourceLatitude] = useState<number>(sourceLat);
  const [sourceLongitude, setSourceLongitude] = useState<number>(sourceLng);
  const [destinationLatitude, setDestinationLatitude] =
    useState<number>(destinationLat);
  const [destinationLongitude, setDestinationLongitude] =
    useState<number>(destinationLng);

  // State to hold the status

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  const handleComments = (e: any) => {
    setComments(e.target.value);
  };
  const handleSourceLat = (e: any) => {
    setSourceLatitude(e.target.value);
  };
  const handleSourceLng = (e: any) => {
    setSourceLongitude(e.target.value);
  };
  const handleDestinationLat = (e: any) => {
    setDestinationLatitude(e.target.value);
  };
  const handleDestinationLng = (e: any) => {
    setDestinationLongitude(e.target.value);
  };

  const getStatusLabel = (status: number | undefined) => {
    if (status === 1) {
      return "Approved";
    } else if (status === 2) {
      return "Rejected";
    } else {
      return "Not Serviceable";
    }
  };
  const getStatusColor = (status: number | undefined) => {
    if (status === 1) {
      return "#00CB60";
    } else {
      return "#F94932";
    }
  };
  const getStatusBgColor = (status: number | undefined) => {
    if (status === 1) {
      return "#00CB6026";
    } else {
      return "#F9493226";
    }
  };

  const handleUpdateOrder = () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const remark = comments;
    OrderStatusApi.api
      .updateOrder(
        orderNumber,
        sourceLat,
        sourceLng,
        destinationLat,
        destinationLng,
        remark
      )
      .then((response) => {
        console.log(response);
        setShowSuccessSnackbar(true);
        setSnackbarMessage("Order updated successfully");
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Failed to update order";
        setShowErrorSnackbar(true);
        setSnackbarMessage(errorMessage);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <div
        className="bg-[#171F21] px-4 py-2 rounded-3xl flex mt-32 cursor-pointer justify-center"
        onClick={handleOpen}
      >
        <span className="text-[#FFFFFF] text-sm font-medium font-inter">
          Update
        </span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-end mb-1">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="px-6">
              <Typography
                id="modal-modal-description"
                sx={{ pb: 1 }}
                className="text-[#414141] text-xl"
              >
                Source
              </Typography>
              <div className="flex pb-4">
                <div className="w-1/2">
                  <TextField
                    placeholder="Lat"
                    size="small"
                    fullWidth
                    onChange={handleSourceLat}
                  />
                </div>
                <div className="w-1/2">
                  <TextField
                    placeholder="Long"
                    size="small"
                    sx={{ ml: 2 }}
                    fullWidth
                    onChange={handleSourceLng}
                  />
                </div>
              </div>
              <Typography
                id="modal-modal-description"
                sx={{ pb: 1 }}
                className="text-[#414141] text-xl"
              >
                Destination
              </Typography>
              <div className="flex pb-4">
                <div className="w-1/2">
                  <TextField
                    placeholder="Lat"
                    size="small"
                    fullWidth
                    onChange={handleDestinationLat}
                  />
                </div>
                <div className="w-1/2">
                  <TextField
                    placeholder="Long"
                    size="small"
                    sx={{ ml: 2 }}
                    fullWidth
                    onChange={handleDestinationLng}
                  />
                </div>
              </div>
              <div className="py-3">
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={5}
                  placeholder="Remarks"
                  fullWidth
                  onChange={handleComments}
                />
              </div>
            </div>
            <div className="flex justify-center mt-6 items-center pb-4">
              <Button
                variant="contained"
                onClick={handleUpdateOrder}
                disabled={
                  isSubmitting ||
                  !sourceLatitude ||
                  !sourceLongitude ||
                  !destinationLatitude ||
                  !destinationLongitude ||
                  !comments
                }
                sx={{
                  ml: 3,
                  color: "white",
                  bgcolor: "#171F21",
                  borderRadius: "50px",
                  textTransform: "none",
                  py: 1,
                  px: 6,
                  "&:hover": { bgcolor: "#171F2180" },
                }}
              >
                Update Order
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
