import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  InputAdornment,
  TextField,
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  InputBase
} from "@mui/material";
// import BasicModal from "../../Modals/NewFleetDemandModal";
import "./TableCss.css";
import PaymentExtraDetails from "../PaymentsExtraDetails";
import "animate.css/animate.min.css";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";

export default function WalletTransactionTable() {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const handleExpandToggle = (orderId: string) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(orderId)
        ? prevExpandedRows.filter((id) => id !== orderId)
        : [...prevExpandedRows, orderId]
    );
  };

  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);

  const [fleetDemandListData, setFleetDemandListData] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageRange = 5; // Number of pages to display in the pagination

  //   useEffect(() => {
  //     setLoading(true);
  //     const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
  //     NewFleetDemandApi.api
  //       ?.hubFleetRequestList(page + 1, rowsPerPage, apiUrl)
  //       .then((e) => {
  //         setLoading(false);
  //         if (
  //           Array.isArray(e.fleetRequestList) &&
  //           e.fleetRequestList.length > 0
  //         ) {
  //           setFleetDemandListData(e.fleetRequestList);
  //           setTotalCount(e.totalCount);
  //         } else {
  //           setShowSnackbar(true);
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         console.error("API call error: ", error);
  //       });
  //   }, [searchVal, page, rowsPerPage]);

  const dummyData = [
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      credit: "2345",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      credit: "324",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      debit: "324",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      credit: "324",
      debit: "324",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      credit: "324",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      debit: "324",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      credit: "324",
      debit: "324",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      credit: "324",
      debit: "324",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      debit: "324",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      credit: "324",
    },
    {
      riderId: "GGN - 1212792734",
      transactionDate: "13 Dec, 2023",
      mobile: "27346824384",
      transactionId: "15 %",
      credit: "324",
      debit: "324",
    },
  ];

  const pageCount = Math.ceil(dummyData.length / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
    setPage(newPageNumber);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <>
      {/* {loading ? (
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          ) : (
            <> */}
      <TableContainer className="">
      <div className="flex justify-between mx-4 mt-4 mb-4">
          <div className="border-2 rounded-2xl px-4 mt-1">
            <label className="text-[#727597] text-sm font-normal font-inter">
              Status :{" "}
            </label>
            <select
              name="cars"
              id="cars"
              className="text-[#727597] text-sm font-normal font-inter"
            >
              <option value="volvo">Volvo</option>
              <option value="saab">Saab</option>
              <option value="opel">Opel</option>
              <option value="audi">Audi</option>
            </select>
          </div>
          <div className="border-2 rounded-2xl px-4">
            <InputBase
              placeholder="Search by Id,name or date"
              className="flex-grow"
              inputProps={{ "aria-label": "search" }}
            />
            <SearchIcon
              style={{ color: "#727597" }}
              className=" cursor-pointer ml-4"
            />
          </div>
        </div>
        <hr />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                #
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Rider ID
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Transaction Date
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Mobile No.
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Transaction ID
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Credit
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Debit
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData
              ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((row: any, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={index}
                    className={
                      expandedRows.includes(row.transactionId)
                        ? "expanded-row"
                        : ""
                    }
                  >
                    <TableCell>
                      <Checkbox />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.riderId}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.transactionDate}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.mobile}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.transactionId}
                    </TableCell>
                    <TableCell>
                      {row.credit !== null &&
                      row.credit !== undefined &&
                      row.credit !== 0 ? (
                        <Chip
                          label={`₹ |  ${row.credit}`}
                          size="small"
                          style={{
                            backgroundColor: "#00CB6026",
                            color: "#00CB60",
                            paddingRight: "20px",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        />
                      ) : (
                        <img src="/assets/icons/dashIcon.svg" alt="" />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.debit !== null &&
                      row.debit !== undefined &&
                      row.debit !== 0 ? (
                        <Chip
                          label={`₹ |  ${row.debit}`}
                          size="small"
                          style={{
                            backgroundColor: "#F9493226",
                            color: "#F94932",
                            paddingRight: "20px",
                          }}
                        />
                      ) : (
                        <img src="/assets/icons/dashIcon.svg" alt="" />
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pt-4 pb-2 pr-5">
        <div
          className="pagination"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="flex">
            <img
              src="/assets/icons/leftArrow.svg"
              alt=""
              onClick={() => handlePageChange(page - 1)}
            />
            {renderPageNumbers()}
            <img
              src="/assets/icons/rightArrow.svg"
              alt=""
              onClick={() => handlePageChange(page + 1)}
              className="ml-1"
            />
          </div>
        </div>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
          No data found
        </Alert>
      </Snackbar>
      {/* </>
          )} */}
    </>
  );
}
