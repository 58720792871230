import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import TrackOrderModal from "../../Modals/TrackOrderModal";
import CallDriverModal from "../../Modals/CallDriverModal";
import {
  Alert,
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TicketDetails from "../TicketDetails";
import UpdateTicketModal from "../../Modals/UpdateTicketModal";
import { SosTicketingApi } from "../../../API/SosTicketing";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default function TicketExtraDetails({
  ticketId,
  riderUserId,
  orderNumber,
  status,
  searchTicket,
}: {
  ticketId: number;
  riderUserId: number;
  orderNumber: number;
  status: number;
  searchTicket: string | null;
}) {
  const navigate = useNavigate();
  const [extraTicketData, setExtraTicketData] = useState<any>();
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const debouncedHandleAssignTicket = useCallback(
    _.debounce(
      () => {
        SosTicketingApi.api
          .assignTicket(ticketId)
          .then((response) => {
            console.log(response);
            setShowSuccessSnackbar(true);
            setSnackbarMessage("Ticket has been assigned to you");
          })
          .catch((error) => {
            console.error(error);
            const errorMessage =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : "Failed to assign ticket";
            setShowErrorSnackbar(true);
            setSnackbarMessage(errorMessage);
          });
      },
      5000,
      { leading: true, trailing: true }
    ),
    [ticketId]
  );

  useEffect(() => {
    SosTicketingApi.api
      .extraTicketDetailsList(ticketId)
      .then((response) => {
        setExtraTicketData(response.data.data);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, [ticketId, searchTicket]);

  if (!extraTicketData) {
    return (
      <div className="flex justify-center py-4">
        <CircularProgress sx={{ color: "#00cd5b" }} />
      </div>
    );
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    navigate(`/ticketing?searchTicket=${ticketId}`);
  };

  const handleAssignTicket = () => {
    debouncedHandleAssignTicket();
  };

  return (
    <>
      <div className="flex">
        <div className="w-5/6 mt-2">
          <TicketDetails
            ticketId={ticketId}
            subTypeListId={extraTicketData?.subTypeListId}
            ticketType={extraTicketData?.ticketType}
            orderCreatedTime={extraTicketData?.orderCreatedTime}
            orderCode={extraTicketData?.orderCode}
            status={status}
            customerName={extraTicketData?.customerName}
            destinationAddress={extraTicketData?.destinationAddress}
            orderReferenceNumber={extraTicketData?.orderReferenceNumber}
            pickupDistance={extraTicketData?.pickupDistance}
            deliveryDistance={extraTicketData?.deliveryDistance}
            orderAmount={extraTicketData?.orderAmount}
            riderAmount={extraTicketData?.riderAmount}
          />
        </div>
        <div className="ml-4">
          <TrackOrderModal
            orderNumber={orderNumber}
            sourceLat={extraTicketData?.sourceLat}
            sourceLong={extraTicketData?.sourceLong}
            destinationLat={extraTicketData?.destinationLat}
            destinationLong={extraTicketData?.destinationLong}
            riderLat={extraTicketData?.riderLat}
            riderLong={extraTicketData?.riderLong}
          />
          {status !== 0 && status !== 2 && (
            <>
              {riderUserId && (
                <CallDriverModal
                  userId={riderUserId}
                  orderNo={orderNumber}
                  contactType={3}
                  modalButton="riderTicket"
                />
              )}
              {riderUserId && (
                <CallDriverModal
                  userId={riderUserId}
                  orderNo={orderNumber}
                  contactType={1}
                  modalButton="customerTicket"
                />
              )}
            </>
          )}
          {status !== 2 ? (
            <>
              {extraTicketData?.agentId && extraTicketData?.agentName ? (
                <>
                  <Tooltip
                    title={`Agent ID: ${extraTicketData?.agentId}`}
                    arrow
                  >
                    <div className="bg-[#fff] border-[1px] border-[#171F21] mt-4 px-4 py-2 rounded-3xl flex items-center justify-center cursor-pointer">
                      <span className="text-[#171F21] text-sm font-medium font-inter">
                        {extraTicketData?.agentName}
                      </span>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <div
                  className="bg-[#fff] border-[1px] border-[#171F21] mt-4 px-4 py-2 rounded-3xl flex items-center justify-center cursor-pointer"
                  onClick={handleAssignTicket}
                >
                  <SupportAgentIcon
                    fontSize="small"
                    sx={{ color: "#171F21", mr: 1 }}
                  />
                  <span className="text-[#171F21] text-sm font-medium font-inter">
                    My Ticket
                  </span>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
          <Snackbar
            open={showSuccessSnackbar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {SnackbarMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={showErrorSnackbar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {SnackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
      <TableContainer className="mt-6">
        <div className="font-semibold py-1 font-inter">Contents</div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Comment
              </TableCell>

              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Commented By
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Created Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {extraTicketData?.commentsList?.map((row: any, index: any) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.comment}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      wordBreak: "break-word",
                      textOverflow: "ellipsis",
                      maxWidth: "140px",
                    }}
                  >
                    {row.commentBy}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.createdTime}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {status !== 0 && status !== 2 && (
        <div>
          <UpdateTicketModal
            ticketId={ticketId}
            modalType="Add Comments"
            ticketType={extraTicketData?.ticketType}
            disabled={false}
            subTypeListId={extraTicketData?.subTypeListId}
          />
        </div>
      )}
      {status !== 0 && status !== 2 && (
        <div className="mt-6 flex justify-end items-center">
          <div className="mr-2">
            <UpdateTicketModal
              ticketId={ticketId}
              modalType="Close Ticket"
              ticketType={extraTicketData?.ticketType}
              disabled={false}
              subTypeListId={extraTicketData?.subTypeListId}
            />
          </div>
          <div>
            <UpdateTicketModal
              ticketId={ticketId}
              modalType="Cancel Order/Ticket"
              ticketType={extraTicketData?.ticketType}
              disabled={false}
              subTypeListId={extraTicketData?.subTypeListId}
            />
          </div>
        </div>
      )}
    </>
  );
}
