import React, { useState } from "react";
import { Divider, List, ListItem, Popover, Typography } from "@mui/material";
import ModalComponent from "./UpdateStatusModal";

type StatusKey = -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

interface StatusPopoverProps {
  orderNumber: number;
  orderStatus: StatusKey;
}

const statusData: Record<
  StatusKey,
  { text: string; color: string; bgcolor: string }
> = {
  "-3": { text: "System Cancel", color: "#FF725E", bgcolor: "#FF725E33" },
  "-2": { text: "Rider Cancel", color: "#FF725E", bgcolor: "#FF725E33" },
  "-1": { text: "Source cancel", color: "#FF725E", bgcolor: "#FF725E33" },
  0: { text: "Unassigned", color: "#FFB846", bgcolor: "#FFF6E8" },
  1: { text: "Assigned", color: "#FFB846", bgcolor: "#FFF6E8" },
  2: { text: "Going for Pickup", color: "#FFB846", bgcolor: "#FFF6E8" },
  3: { text: "Reached Pickup", color: "#FFB846", bgcolor: "#FFF6E8" },
  4: { text: "Order Picked", color: "#FFB846", bgcolor: "#FFF6E8" },
  5: { text: "Reached Gate", color: "#FFB846", bgcolor: "#FFF6E8" },
  6: { text: "Order Delivered", color: "#00CB60", bgcolor: "#00CB6026" },
  7: { text: "Mark Undelivered", color: "#FF725E", bgcolor: "#FF725E33" },
  8: { text: "Back to Hub", color: "#FF725E", bgcolor: "#FF725E33" },
  9: { text: "RTO Reached Hub", color: "#FF725E", bgcolor: "#FF725E33" },
  10: { text: "Order Undelivered", color: "#FF725E", bgcolor: "#FF725E33" },
};

const StatusPopover: React.FC<StatusPopoverProps> = ({
  orderNumber,
  orderStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const [selectedText, setSelectedText] = useState<string>("");
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [selectedBgcolor, setSelectedBgcolor] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (
    selectedStatus: number,
    selectedText: string,
    selectedColor: string,
    selectedBgcolor: string
  ) => {
    setModalOpen(true);
    handleClose();
    setSelectedStatus(selectedStatus);
    setSelectedText(selectedText);
    setSelectedColor(selectedColor);
    setSelectedBgcolor(selectedBgcolor);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { text, color, bgcolor } = statusData[orderStatus] || {
    text: "None",
    color: "#171F21",
    bgcolor: "#171F2126",
  };

  return (
    <div>
      <div
        className="flex items-center justify-between cursor-pointer rounded-[50px] px-2 py-1 text-nowrap"
        style={{ backgroundColor: bgcolor, color: color }}
        onClick={handleClick}
      >
        {text || "Open Popover"}
        <svg
          width="16"
          height="10"
          viewBox="0 0 16 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.17711 8.79702C8.41664 9.48102 7.26264 9.48102 6.50217 8.79702L0.876442 3.737C-0.486595 2.51103 0.38064 0.25 2.21391 0.25L13.4654 0.25C15.2986 0.25 16.1659 2.51103 14.8028 3.737L9.17711 8.79702Z"
            fill={color}
          />
        </svg>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List sx={{ py: 0 }}>
          {Object.keys(statusData)
            .filter(
              (key) => ![0, 1, orderStatus, -3, -2, -1].includes(parseInt(key))
            )
            .map((key) => (
              <React.Fragment key={key}>
                <ListItem
                  className="hover:bg-[#29CE57] cursor-pointer hover:text-[#fff] text-black"
                  onClick={() =>
                    handleListItemClick(
                      parseInt(key) as StatusKey,
                      statusData[parseInt(key) as StatusKey].text,
                      statusData[parseInt(key) as StatusKey].color,
                      statusData[parseInt(key) as StatusKey].bgcolor
                    )
                  }
                >
                  {statusData[parseInt(key) as StatusKey].text}
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
        </List>
      </Popover>

      <ModalComponent
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        text={selectedText}
        color={selectedColor}
        bgcolor={selectedBgcolor}
        status={selectedStatus}
        orderNumber={orderNumber}
        orderStatus={orderStatus}
      />
    </div>
  );
};

export default StatusPopover;
