import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Chip,
  Typography,
  Snackbar,
  Alert,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RiderNominationApi } from "../../API/RiderNomination";
import ReturnToDashboardModal from "./ReturnToDashboardModal";
import { OrderStatusApi } from "../../API/OrderStatus";
import { ONDCApi } from "../../API/ONDC";
import { SelectChangeEvent } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

interface CancelOrderModalProps {
  orderNumber: number;
  orderReference: string;
  merchantName: string;
}
const remarksData = [
  { value: "008", label: "Order / fulfillment not ready for pickup" },
  { value: "011", label: "Buyer not found or cannot be contacted" },
  { value: "012", label: "Buyer does not want product any more" },
  { value: "013", label: "Buyer refused to accept delivery" },
  { value: "014", label: "Address not found" },
  { value: "015", label: "Buyer not available at location" },
  { value: "016", label: "Accident / rain / strike / vehicle issues" },
  { value: "017", label: "Order delivery delayed or not possible" },
  { value: "020", label: "Order lost in transit" },
  { value: "021", label: "Packed order not complete" },
  { value: "997", label: "Order confirmation failure" },
];
export default function CancelOrderModal({
  orderNumber,
  merchantName,
  orderReference,
}: CancelOrderModalProps) {
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState<number | undefined>();
  const [comments, setComments] = useState<string>("");

  // State to hold the status

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setComments("");
  };
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setComments(e.target.value);
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComments(e.target.value);
  };
  const handleCancelOrder = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const remark = comments;
    const apiCall =
      merchantName === "ONDC"
        ? ONDCApi.api.cancelOrderONDC(
            orderReference,
            remark,
            remarksData.find((remark) => remark.value === comments)?.label
          )
        : OrderStatusApi.api.cancelOrder(orderNumber, remark);

    apiCall
      .then((response) => {
        setShowSuccessSnackbar(true);
        setSnackbarMessage("Order cancelled successfully");
        handleClose();
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to cancel order";
        console.error(error);
        setShowErrorSnackbar(true);
        setSnackbarMessage(errorMessage);
      })
      .finally(() => setIsSubmitting(false));
  };
  // console.log(comments, "comments");
  return (
    <div>
      <div
        className="bg-[#F94932] px-4 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
        onClick={handleOpen}
      >
        <span className="text-[#FFFFFF] text-sm font-medium font-inter">
          Cancel Order
        </span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-end mb-4">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            {merchantName === "ONDC" ? (
              <>
                <Typography
                  id="modal-modal-description"
                  sx={{ pb: 2 }}
                  className="text-[#414141] text-xl"
                >
                  Remarks
                </Typography>
                <Select
                  value={comments}
                  onChange={handleSelectChange}
                  fullWidth
                >
                  {remarksData.map((remark) => (
                    <MenuItem key={remark.value} value={remark.value}>
                      {remark.label}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <TextField
                id="outlined-multiline-static"
                multiline
                sx={{ pb: 2 }}
                rows={5}
                placeholder="Remarks"
                fullWidth
                onChange={handleTextFieldChange}
              />
            )}
            <div className="flex justify-center mt-4 items-center pb-4">
              <Button
                sx={{ textTransform: "none", color: "#171F21", py: 1, px: 8 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleCancelOrder}
                disabled={isSubmitting || !comments}
                sx={{
                  ml: 3,
                  color: "white",
                  bgcolor: "#F94932",
                  borderRadius: "50px",
                  textTransform: "none",
                  py: 1,
                  px: 8,
                  "&:hover": { bgcolor: "#F9493280" },
                }}
              >
                Cancel Order
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
