import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Tooltip,
  Menu,
  ListItemIcon,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from "react";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AccountIcon from "@mui/icons-material/AccountCircleOutlined";
import { useNavigate } from "react-router";

interface HeaderProps {
  merchantLogoUrl: string;
}

const Header: React.FC<HeaderProps> = ({ merchantLogoUrl }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <AppBar
      position="static"
      style={{ background: "#ffffff", boxShadow: "none", width: "100%" }}
    >
      <Toolbar
        className="justify-between"
        sx={{ "&&": { padding: 0, minHeight: 0 } }}
      >
        <div className="flex items-center">
          {/* <div className="flex flex-col items-center">
            {merchantLogoUrl && merchantLogoUrl !== "" ? (
              <div>
                <img src={merchantLogoUrl} alt="Logo" className="h-20 w-20" />
              </div>
            ) : (
              <div>
                {" "}
                <img
                  src="/assets/icons/zyppItLogo.png"
                  alt="zyppIt Logo"
                />{" "}
              </div>
            )}
          </div> */}
        </div>
        <div className="flex items-center">
          <Tooltip title="Logout">
            <IconButton
              onClick={handleLogout}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Logout color="success" fontSize="large" />
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
          >
            {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <AccountIcon fontSize="small" />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem> */}
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
