import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const libraries: "places"[] = ["places"];

function GoogleSpecificMapApi({
  selectedOrder,
  mapProps = {},
  children = <></>,
}: any) {
  const [isApiLoaded, setIsApiLoaded] = useState(false);

  useEffect(() => {
    const scriptLoaded = window.google && window.google.maps;
    setIsApiLoaded(!!scriptLoaded);
  }, []);
  const center = {
    lat: selectedOrder?.sourceLat,
    lng: selectedOrder?.sourceLong,
  };
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg"
      libraries={libraries}
      onLoad={() => setIsApiLoaded(true)}
    >
      {isApiLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          {...mapProps}
        >
          <Marker
            position={{
              lat: parseFloat(selectedOrder?.sourceLat),
              lng: parseFloat(selectedOrder?.sourceLong),
            }}
            icon={{ url: "/assets/icons/yellow-map-icon.svg" }}
          />
          {selectedOrder?.riderLat ? (
            <Marker
              position={{
                lat: parseFloat(selectedOrder?.riderLat),
                lng: parseFloat(selectedOrder?.riderLong),
              }}
              icon={{ url: "/assets/icons/scooter-icon.svg" }}
            />
          ) : (
            <></>
          )}
          <Marker
            position={{
              lat: parseFloat(selectedOrder?.destinationLat),
              lng: parseFloat(selectedOrder?.destinationLong),
            }}
            icon={{ url: "/assets/icons/green-map-icon.svg" }}
          />
        </GoogleMap>
      ) : (
        <div>Loading map...</div>
      )}
      {children}
    </LoadScript>
  );
}

export default GoogleSpecificMapApi;
