import React, { useEffect, useState } from "react";
import {
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  InputBase,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import "./TableCss.css";
import "animate.css/animate.min.css";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import ActionModal from "../../Modals/NominationApproveModal";
import { OrderSummaryApi } from "../../../API/OrderSummaries";
import { CSVLink } from "react-csv";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

export default function OnlineUserReportTable() {
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [onlineUserCSVList, setOnlineUserCSVList] = useState<any>([]);
  const [onlineUserList, setOnlineUserList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageRange = 5;
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${searchVal ? `?user_id=${searchVal}` : ""}`;
    OrderSummaryApi.api
      .OnlineUsersListApi(page + 1, apiUrl)
      .then((e) => {
        setLoading(false);
        if (parseInt(e.data.total_rows) > 0) {
          setOnlineUserList(e.data.data);
          setTotalCount(parseInt(e.data.total_rows));
        } else {
          setShowSnackbar(true);
          setOnlineUserList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowSnackbar(true);
        console.error("API call error: ", error);
      });
    const apiUrl2 =
      "https://data.bcykal.com/tracker/ondemand/online/users/report";

    fetch(apiUrl2)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (parseInt(data.data.total_rows) > 0) {
          setOnlineUserCSVList(data.data.data);
        } else {
          setOnlineUserCSVList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching ongoing orders: ", error);
      });
  }, [page, searchVal, refresh]);
  const header = [
    { label: "User ID", key: "userId" },
    { label: "Rider Name", key: "riderName" },
    { label: "Rider Mobile No", key: "riderMobile" },
    { label: "QR Code", key: "qrCode" },
    { label: "Merchant Name", key: "merchantName" },
    { label: "Hub Name", key: "hubName" },
    { label: "City", key: "cityName" },
  ];
  const pageCount = Math.ceil(totalCount / 20);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
    setPage(newPageNumber);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
    setShowSuccessSnackbar(false);
  };
  return (
    <>
      <TableContainer className="">
        <div className="flex items-center justify-between py-4 px-3 border-slate-200 border-solid">
          <div className="flex">
            <Typography className="font-bold text-[#414141]">
              Online Riders Report
            </Typography>
            <Tooltip title="Refresh">
              <RefreshOutlinedIcon
                sx={{ color: "#06B681", ml: 1, cursor: "pointer" }}
                onClick={() => {
                  setRefresh(!refresh);
                }}
              />
            </Tooltip>
          </div>
          <div className="flex">
            <div className="border-2 rounded-2xl px-4">
              <InputBase
                type="number"
                placeholder="Search by User ID"
                className="flex-grow"
                inputProps={{ "aria-label": "search" }}
                value={searchVal}
                onChange={(search) => setSearchVal(search.target.value)}
              />
              <SearchIcon
                style={{ color: "#727597" }}
                className=" cursor-pointer ml-4"
              />
            </div>
            <CSVLink
              data={onlineUserCSVList || []}
              headers={header}
              filename={`Online_user_data.csv`}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  bgcolor: "#06B681",
                  px: 2,
                  ml: 2,
                  "&:hover": { bgcolor: "#06B681" },
                  borderRadius: "50px",
                  fontSize: "14px",
                }}
              >
                Export
              </Button>
            </CSVLink>
          </div>
        </div>
        {/* {loading ? (
        <LinearProgress
          sx={{
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#0079FF",
            },
          }}
        />
      ) : ( */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                User ID
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                QR Code
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Rider’s Name
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Rider’s Mobile
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Merchant
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Shop Name
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Hub Name
              </TableCell>

              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                City
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {onlineUserList?.map((row: any) => (
              <TableRow key={row.userId}>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {row.userId}
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {row.qrCode}
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {row.riderName}
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {row.riderMobile}
                </TableCell>
                <TableCell>
                  <Chip
                    label={row.merchantName}
                    size="small"
                    style={{
                      backgroundColor: "#F9493226",
                      color: "#F94932",
                      paddingInline: "20px",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginLeft: "5px",
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {row.shopName}
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {row.hubName}
                </TableCell>
                <TableCell
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {row.cityName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* )} */}
      <div className="pt-4 pb-2 pr-5">
        <div
          className="pagination"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {/* <select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            style={{
              backgroundColor: "#EBEBEB",
              fontFamily: "Rubik",
              fontSize: "16px",
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select> */}
          <div className="flex">
            <img
              src="/assets/icons/leftArrow.svg"
              alt=""
              onClick={() => handlePageChange(page - 1)}
            />
            {renderPageNumbers()}
            <img
              src="/assets/icons/rightArrow.svg"
              alt=""
              onClick={() => handlePageChange(page + 1)}
              className="ml-1"
            />
          </div>
        </div>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert sx={{ width: "10" }} onClose={handleClose} severity="error">
          No data found
        </Alert>
      </Snackbar>
    </>
  );
}
