import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GoogleMap, MarkerF as Marker } from "@react-google-maps/api";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { ModalMapProps } from "../../@Types/ComponentsInterface/OrderSelectProps";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};
const containerStyle = {
  width: "40vw",
  height: "19.5rem",
};
const DropMarkerModal: React.FC<ModalMapProps> = ({
  latit = 28.7136,
  lngit = 77.1294,
  onPinChange = () => {},
}) => {
  let center = {
    lat: latit,
    lng: lngit,
  };
  const geocoder = new google.maps.Geocoder();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [markerPosition, setMarkerPosition] = useState({
    lat: latit,
    lng: lngit,
  });

  return (
    <div>
      <Link
        sx={{ cursor: "pointer", textDecoration: "none" }}
        onClick={handleOpen}
      >
        <MyLocationIcon sx={{ color: "#06B681" }} />
      </Link>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between mb-4">
            <div className="flex">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Pickup Location
              </Typography>
            </div>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            <Marker
              position={markerPosition}
              opacity={1}
              icon={"/assets/icons/location-pin.png"}
              draggable={true}
              onDragEnd={(e) => {
                const newMarkerPosition = {
                  lat: e.latLng?.lat() || 0,
                  lng: e.latLng?.lng() || 0,
                };

                setMarkerPosition(newMarkerPosition);

                // Reverse geocode to get address details
                geocoder
                  .geocode({ location: newMarkerPosition })
                  .then((locationDetails) => {
                    if (locationDetails.results.length > 0) {
                      onPinChange({
                        lat: newMarkerPosition.lat,
                        lng: newMarkerPosition.lng,
                        city:
                          locationDetails.results[0].address_components.find(
                            (e) => e.types.includes("political")
                          )?.long_name || "",
                        address: locationDetails.results[0].formatted_address,
                        administrative_area_level_1:
                          locationDetails.results[0].address_components.find(
                            (e) =>
                              e.types.includes("administrative_area_level_1")
                          )?.long_name || "",
                        country:
                          locationDetails.results[0].address_components.find(
                            (e) => e.types.includes("country")
                          )?.long_name || "",
                        postcode:
                          locationDetails.results[0].address_components.find(
                            (e) => e.types.includes("postal_code")
                          )?.long_name || "postal_code",
                        utc_offset: 0,
                      });
                    }
                  });
              }}
            />
          </GoogleMap>
        </Box>
      </Modal>
    </div>
  );
};
export default DropMarkerModal;
