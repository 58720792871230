import React, { useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { ForgotPasswordApi } from "../../../API/ForgotPassword";
import MailOutline from "@mui/icons-material/MailOutline";

function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [loader, setLoader] = useState(false);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleForget();
  };
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccessSnackbar(false);
  };
  const handleForget = async () => {
    // const forgotPasswordApi = new ForgotPasswordApi();
    // setLoader(true);
    // try {
    //   const response = await forgotPasswordApi.forgotPassApi(
    //     username
    //   );
    // } catch (error) {
    // setShowSuccessSnackbar(false);
    //   console.error("Error:", error);
    //   setLoader(false);
    // }
  };
  return (
    <Box sx={{ minHeight: "60vh" }}>
      <Grid container>
        <Grid item xs={false} sm={4} md={6}>
          <div className="flex items-center">
            <img
              src={"/assets/icons/Logo-zyppit.svg"}
              alt="Logo"
              className="ml-4 mt-4"
            />
          </div>
          <Box
            component="img"
            className="w-full mt-2"
            sx={{
              height: 800,
              maxHeight: { xs: 200, md: 467 },
              maxWidth: { xs: 800, md: 1150 },
            }}
            alt="The house from the offer."
            src="/assets/icons/Login.svg"
          />
        </Grid>
        <Grid
          item
          sm={8}
          md={6}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={8} md={7}>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                className="w-full flex justify-between items-center"
              >
                <Grid item xs={12}>
                  <p className="text-left font-bold text-3xl text-gray-500">
                    Forgot Password ?
                  </p>
                  <p className="text-left	mb-8 mt-2 text-gray-500">
                    Please enter your valid email, we will send you an Auto
                    generated password on your email inbox.{" "}
                  </p>

                  <Stack spacing={1}>
                    <InputLabel className="text-left" htmlFor="email">
                      Enter Username
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="emailInstant"
                      name="emailInstant"
                      placeholder="Enter email address"
                      className="text-slate-300"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="text-slate-300"
                          >
                            <MailOutline />
                          </InputAdornment>
                        ),
                      }}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  {/* {loader ? (
              <Button
                sx={{
                  backgroundColor: "#0079FF",
                  "&:hover": {
                    backgroundColor: "#0079FF",
                  },
                }}
                className="bg-[#0079FF] hover:bg-[#0079FF]"
                fullWidth
                size="large"
                variant="contained"
                type="submit"
              >
                <CircularProgress sx={{ color: "white" }} />
              </Button>
            ) : ( */}
                  <Button
                    sx={{
                      backgroundColor: "#0079FF",
                      "&:hover": {
                        backgroundColor: "#0079FF",
                      },
                    }}
                    className="bg-[#0079FF] hover:bg-[#0079FF]"
                    fullWidth
                    size="large"
                    variant="contained"
                    type="submit"
                  >
                    Send Password
                  </Button>
                  {/* )} */}
                </Grid>
              </Grid>
            </form>
            <Snackbar
              open={showSuccessSnackbar}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                sx={{ width: "100%" }}
                onClose={handleClose}
                severity="success"
              >
                Password Sent
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default ForgotPassword;
