import React, { useEffect, useState } from "react";
import { Typography, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../../Layouts/TrackOrder/TrackOrder.css";
import { Tab, Tabs, Box } from "@mui/material";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import TimelineCard from "../../Layouts/TrackOrder/TimelineCard";
import GoogleMapApi from "../../Layouts/Maps/AllOrderMaps";
import Header from "../../Layouts/AppHeader/Header";
import { TrackOrderApi } from "../../../API/TrackOrder";
import GoogleSpecificMapApi from "../../Layouts/Maps/SpecificOrdersMap";

export default function TrackOrder(): JSX.Element {
  const [tabValue, setTabValue] = useState<number>(0);
  const [trackOrdersData0, setTrackOrdersData0] = useState<any>([]);
  const [trackOrdersData1, setTrackOrdersData1] = useState<any>([]);
  const [trackOrdersData2, setTrackOrdersData2] = useState<any>([]);
  const [trackOrdersData3, setTrackOrdersData3] = useState<any>([]);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [page0, setPage0] = useState<number>(0);
  const [page1, setPage1] = useState<number>(0);
  const [page2, setPage2] = useState<number>(0);
  const [page3, setPage3] = useState<number>(0);
  const [totalCount0, setTotalCount0] = useState<any>();
  const [totalCount1, setTotalCount1] = useState<any>();
  const [totalCount2, setTotalCount2] = useState<any>();
  const [totalCount3, setTotalCount3] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState(3);

  // const pageCount: number = 3;
  const pageRange: number = 5;

  const handlePageChange = (
    newPage: number,
    tabValue: number,
    pageCount: number
  ): void => {
    const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
    switch (tabValue) {
      case 0:
        setPage0(newPageNumber);
        break;
      case 1:
        setPage1(newPageNumber);
        break;
      case 2:
        setPage2(newPageNumber);
        break;
      default:
      case 3:
        setPage3(newPageNumber);
        break;
        break;
    }
  };

  useEffect(() => {
    TrackOrderApi.api
      .trackOrderList(0, page0 + 1)
      .then((e) => {
        setTrackOrdersData0(e.data.data.trackOrderList);
        setTotalCount0(e.data.data.listCount);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, [page0]);
  useEffect(() => {
    TrackOrderApi.api
      .trackOrderList(1, page1 + 1)
      .then((e) => {
        setTrackOrdersData1(e.data.data.trackOrderList);
        setTotalCount1(e.data.data.listCount);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, [page1]);
  useEffect(() => {
    TrackOrderApi.api
      .trackOrderList(2, page2 + 1)
      .then((e) => {
        setTrackOrdersData2(e.data.data.trackOrderList);
        setTotalCount2(e.data.data.listCount);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, [page2]);
  useEffect(() => {
    TrackOrderApi.api
      .trackOrderList(3, page3 + 1)
      .then((e) => {
        setTrackOrdersData3(e.data.data.trackOrderList);
        setTotalCount3(e.data.data.listCount);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, [page3]);
  const pageCount0 = Math.ceil(totalCount0 / rowsPerPage);
  const pageCount1 = Math.ceil(totalCount1 / rowsPerPage);
  const pageCount2 = Math.ceil(totalCount2 / rowsPerPage);
  const pageCount3 = Math.ceil(totalCount3 / rowsPerPage);

  const renderPageNumbers = (
    page: number,
    tabValue: number,
    pageCount: number
  ): JSX.Element[] => {
    const pagesToShow: number = Math.min(pageCount, pageRange);
    const startPage: number = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber: number = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber, tabValue, pageCount)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };

  const handleChangeTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setTabValue(newValue);
  };

  const handleTimelineCardClick = (order: any): void => {
    if (order === selectedOrder) {
      setSelectedOrder(null);
    } else {
      setSelectedOrder(order);
    }
  };

  return (
    <>
      <div className="flex pb-8 justify-between">
        <div className="w-1/6 mr-3">
          <SideBarNavigation />
        </div>
        <div className="w-5/6">
          <div className="pb-4 pl-2 pr-4 mt-6">
            <Header merchantLogoUrl="" />
            <div className="font-medium ml-4 text-[32px] text-[#1E1E1E] text-left font-inter mt-8">
              Track Orders
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mt: 4 }}
              >
                <Typography
                  color="#ACACAC"
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                >
                  Dashboard
                </Typography>
                {/* <Typography
                  color="#ACACAC"
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                >
                  Orders
                </Typography> */}
                <Typography
                  color="#717171"
                  sx={{ fontSize: "12px", fontWeight: "700" }}
                >
                  Track Orders
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className="flex ml-4 w-full">
            <div className="w-1/3 border rounded-lg border border-[#ECEEFF] mr-3">
              <Tabs value={tabValue} onChange={handleChangeTab} centered>
                <Tab label="All" sx={{ textTransform: "none" }} />
                <Tab label="Delivered" sx={{ textTransform: "none" }} />
                <Tab label="Pending" sx={{ textTransform: "none" }} />
                <Tab label="RTO" sx={{ textTransform: "none" }} />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                {trackOrdersData0.map((order: any, index: number) => (
                  <TimelineCard
                    key={index}
                    selectedOrder={
                      selectedOrder === order ? selectedOrder : null
                    }
                    onClick={handleTimelineCardClick}
                    order={order}
                    status={0}
                  />
                ))}
                <div className="">
                  <div
                    className="pagination"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="flex">
                      <img
                        src="/assets/icons/leftArrow.svg"
                        alt=""
                        onClick={() =>
                          handlePageChange(page0 - 1, 0, pageCount0)
                        }
                      />
                      {renderPageNumbers(page0, 0, pageCount0)}
                      <img
                        src="/assets/icons/rightArrow.svg"
                        alt=""
                        onClick={() =>
                          handlePageChange(page0 + 1, 0, pageCount0)
                        }
                        className="ml-1 mr-6"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {trackOrdersData1.map((order: any, index: number) => (
                  <TimelineCard
                    key={index}
                    selectedOrder={
                      selectedOrder === order ? selectedOrder : null
                    }
                    onClick={handleTimelineCardClick}
                    order={order}
                    status={1}
                  />
                ))}
                <div className="">
                  <div
                    className="pagination"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="flex">
                      <img
                        src="/assets/icons/leftArrow.svg"
                        alt=""
                        onClick={() =>
                          handlePageChange(page1 - 1, 1, pageCount1)
                        }
                      />
                      {renderPageNumbers(page1, 1, pageCount1)}
                      <img
                        src="/assets/icons/rightArrow.svg"
                        alt=""
                        onClick={() =>
                          handlePageChange(page1 + 1, 1, pageCount1)
                        }
                        className="ml-1 mr-6"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {trackOrdersData2.map((order: any, index: number) => (
                  <TimelineCard
                    key={index}
                    selectedOrder={
                      selectedOrder === order ? selectedOrder : null
                    }
                    onClick={handleTimelineCardClick}
                    order={order}
                    status={2}
                  />
                ))}
                <div className="">
                  <div
                    className="pagination"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="flex">
                      <img
                        src="/assets/icons/leftArrow.svg"
                        alt=""
                        onClick={() =>
                          handlePageChange(page2 - 1, 2, pageCount2)
                        }
                      />
                      {renderPageNumbers(page2, 2, pageCount2)}
                      <img
                        src="/assets/icons/rightArrow.svg"
                        alt=""
                        onClick={() =>
                          handlePageChange(page2 + 1, 2, pageCount2)
                        }
                        className="ml-1 mr-6"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                {trackOrdersData3.map((order: any, index: number) => (
                  <TimelineCard
                    key={index}
                    selectedOrder={
                      selectedOrder === order ? selectedOrder : null
                    }
                    onClick={handleTimelineCardClick}
                    order={order}
                    status={3}
                  />
                ))}
                <div className="">
                  <div
                    className="pagination"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="flex">
                      <img
                        src="/assets/icons/leftArrow.svg"
                        alt=""
                        onClick={() =>
                          handlePageChange(page3 - 1, 0, pageCount3)
                        }
                      />
                      {renderPageNumbers(page3, 0, pageCount3)}
                      <img
                        src="/assets/icons/rightArrow.svg"
                        alt=""
                        onClick={() =>
                          handlePageChange(page3 + 1, 0, pageCount3)
                        }
                        className="ml-1 mr-6"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
            <div className="w-2/3">
              {selectedOrder ? (
                <GoogleSpecificMapApi selectedOrder={selectedOrder} />
              ) : (
                <GoogleMapApi
                  trackOrdersData={
                    tabValue === 0
                      ? trackOrdersData0
                      : tabValue === 1
                      ? trackOrdersData1
                      : trackOrdersData2
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({
  children,
  value,
  index,
  ...other
}: TabPanelProps): JSX.Element {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}
