import * as React from "react";
import { Card, Chip } from "@mui/material";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import {
  Timeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import "./TrackOrder.css";

import Typography from "@mui/material/Typography";

interface TimelineCardProps {
  order: any;
  status: number;
  selectedOrder: any;
  onClick: (order: any) => void;
}

const TimelineCard: React.FC<TimelineCardProps> = ({
  order,
  status,
  selectedOrder,
  onClick,
}) => {
  const isSelected = selectedOrder && selectedOrder.id === order.id;
  let chipLabel = "";
  let chipColor = "";
  let chipBgColor = "";

  if (order.orderStatus === -3) {
    chipLabel = "System Cancel";
    chipColor = "#FF725E";
    chipBgColor = "#FF725E33";
  } else if (order.orderStatus === -2) {
    chipLabel = "Rider Cancel";
    chipColor = "#FF725E";
    chipBgColor = "#FF725E33";
  } else if (order.orderStatus === -1) {
    chipLabel = "Source cancel";
    chipColor = "#FF725E";
    chipBgColor = "#FF725E33";
  } else if (order.orderStatus === 0) {
    chipLabel = "Unassigned";
    chipColor = "#FFB846";
    chipBgColor = "#FFB84626";
  } else if (order.orderStatus === 1) {
    chipLabel = "Assigned";
    chipColor = "#FFB846";
    chipBgColor = "#FFB84626";
  } else if (order.orderStatus === 2) {
    chipLabel = "Going For Pickup";
    chipColor = "#FFB846";
    chipBgColor = "#FFB84626";
  } else if (order.orderStatus === 3) {
    chipLabel = "Reached Pickup Location";
    chipColor = "#FFB846";
    chipBgColor = "#FFB84626";
  } else if (order.orderStatus === 4) {
    chipLabel = "Order Picked";
    chipColor = "#FFB846";
    chipBgColor = "#FFB84626";
  } else if (order.orderStatus === 5) {
    chipLabel = "Reached Gate";
    chipColor = "#FFB846";
    chipBgColor = "#FFB84626";
  } else if (order.orderStatus === 6) {
    chipLabel = "Order Delivered";
    chipColor = "#00CB60";
    chipBgColor = "#00CB6026";
  } else if (order.orderStatus === 7) {
    chipLabel = "Mark Undelivered";
    chipColor = "#FF725E";
    chipBgColor = "#FF725E33";
  } else if (order.orderStatus === 8) {
    chipLabel = "Back to Hub";
    chipColor = "#FF725E";
    chipBgColor = "#FF725E33";
  } else if (order.orderStatus === 9) {
    chipLabel = "RTO Reached Hub";
    chipColor = "#FF725E";
    chipBgColor = "#FF725E33";
  } else if (order.orderStatus === 10) {
    chipLabel = "Order Undelivered";
    chipColor = "#FF725E";
    chipBgColor = "#FF725E33";
  } else {
    chipLabel = "None";
    chipColor = "#171F21";
    chipBgColor = "#171F2126";
  }

  const renderConnector = () => {
    // Use your custom connector for status 0 and 2
    if (order?.orderStatus === 6) {
      // Use default blue connector for status 1
      return <TimelineConnector sx={{ bgcolor: "#06B681", height: "40px" }} />;
    } else {
      // Default to the default connector
      return <CustomTimelineConnector />;
    }
  };

  const renderThirdDot = () => {
    if (order?.orderStatus === 6) {
      return <CustomCompleteTimelineDot />;
    } else {
      return <TimelineDot sx={{ margin: 0 }} />;
    }
  };

  const renderSecondDot = () => {
    if (order?.orderStatus === 6) {
      return <TimelineDot sx={{ margin: 0, bgcolor: "#06B681" }} />;
    } else {
      // Default to the default connector
      return <CustomTimelineDot status={order?.orderStatus} />;
    }
  };

  return (
    <Card
      onClick={() => onClick(order)}
      sx={{
        minWidth: 270,
        elevation: 0,
        mb: 2,
        boxShadow: "none",
      }}
      className={` rounded-lg border ${
        isSelected ? "border-2 border-[#06B681]" : "border border-[#ECEEFF]"
      }`}
    >
      <div className="border-b flex justify-between align-center">
        <div className="p-2">
          <Typography className="text-[#3B4472] font-inter text-base font-medium mb-2">
            # {order.orderNumber}
          </Typography>
        </div>

        <div className="p-2">
          <Chip
            label={chipLabel}
            size="small"
            sx={{ color: chipColor, bgcolor: chipBgColor }}
          />
        </div>
      </div>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ bgcolor: "#06B681", margin: 0 }} />
            <TimelineConnector sx={{ bgcolor: "#06B681", height: "40px" }} />
          </TimelineSeparator>
          <TimelineContent>
            <div className="grid grid-cols-5 gap-x-2 gap-y-1 pt-1">
              <div className="text-xs text-[#ACACAC] col-span-3 ...">From:</div>
              <div className="text-xs text-[#ACACAC] col-span-2 ...">
                Rider Name
              </div>
              <div className="text-sm text-[#414141] col-span-3 ...">
                {order.sourceAddress}
              </div>
              <div className="text-sm text-[#414141] col-span-2 ...">
                {order.riderName}
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            {renderSecondDot()}
            {renderConnector()}
          </TimelineSeparator>
          <TimelineContent>
            <div className="grid grid-cols-5 gap-x-2 gap-y-1 pt-1">
              <div className="text-xs text-[#ACACAC] col-span-3 ...">To:</div>
              <div className="text-xs text-[#ACACAC] col-span-2 ...">Price</div>
              <div className="text-sm text-[#414141] col-span-3 ...">
                {order.destinationAddress}
              </div>
              <div className="text-sm text-[#414141] col-span-2 ...">
                ₹ {order.price}
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ minHeight: 0 }}>
          <TimelineSeparator>{renderThirdDot()}</TimelineSeparator>
        </TimelineItem>
      </Timeline>
    </Card>
  );
};

const CustomTimelineConnector = () => (
  <div style={{ height: "100%" }}>
    <svg
      width="3"
      height="100%"
      viewBox="0 0 3 100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.4916"
        y1="0.406738"
        x2="1.4916"
        y2="100%"
        stroke="#ACACAC"
        strokeWidth="1.84746"
        strokeDasharray="3.69 3.69"
      />
    </svg>
  </div>
);

const CustomTimelineDot = ({ status }: { status: number }) => (
  <div
    className={`custom-timeline-dot-${
      status === 0 || status === 6 ? "yellow" : "green"
    }`}
  >
    <div
      className={`solid-dot-${
        status === 0 || status === 6 ? "yellow" : "green"
      }`}
    />
  </div>
);

const CustomCompleteTimelineDot = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7" cy="7" r="7" fill="#00CB60" />
    <path
      d="M6.02498 9.80156C5.86404 9.80156 5.7031 9.74062 5.57966 9.61719L3.18435 7.22188C2.93904 6.97656 2.93904 6.57812 3.18435 6.33281C3.42966 6.0875 3.8281 6.0875 4.07341 6.33281L6.02498 8.28438L9.92654 4.38125C10.1719 4.13594 10.5703 4.13594 10.8156 4.38125C11.0609 4.62656 11.0609 5.025 10.8156 5.27031L6.46873 9.61719C6.34685 9.73906 6.18591 9.80156 6.02498 9.80156Z"
      fill="white"
    />
  </svg>
);

export default TimelineCard;
