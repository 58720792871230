import React, { useEffect, useState } from "react";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import "react-datepicker/dist/react-datepicker.css";
import {
  Typography,
  Breadcrumbs,
  Button,
  OutlinedInput,
  MenuItem,
  Card,
  Avatar,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { OrderSummaryApi } from "../../../API/OrderSummaries";
import { RiderSupplyHealthApi } from "../../../API/RiderSupplyHealth";
import Header from "../../Layouts/AppHeader/Header";
import DashboardCardRiderSupply from "../../Layouts/DashboardCardRiderSupply";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Checkbox, ListItemText } from "@mui/material";
import GoogleRiderSupplyMapApi from "../../Layouts/Maps/RiderSupplyHealthMap";
import { styled } from "@mui/system";
import RiderSupplyTable from "../../Layouts/RiderSupplyTable";
interface CardData {
  heading: string;
  backgroundColor: string;
  data: number;
  comparison: string;
  imgSrc: string;
  increment: boolean;
  id: number;
}
function RiderSupplyHealth() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [localityData, setLocalityData] = useState<any>([]);
  const [cityData, setCityData] = useState<any>([]);
  const [riderData, setRiderData] = useState<any>([]);
  const [orderCardDetails, setOrderCardDetails] = useState<any>([]);
  const [selectedCity, setSelectedCity] = useState<any>("GGN");
  const [selectedUser, setSelectedUser] = useState<any>("-1");
  const [riderInfo, setRiderInfo] = useState<any>();
  const [selectAll, setSelectAll] = useState(false);

  const [localityKeys, setLocalityKeys] = useState<string[]>(["kherla"]);
  const [selectedLocalityID, setSelectedLocalityID] = useState<string[]>([
    "11",
  ]);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    let selectedValues = typeof value === "string" ? value.split(",") : value;

    // Toggle "Select All" / "Unselect All"
    if (selectedValues.includes("All Localities")) {
      if (!selectAll) {
        const allLocalities = localityData
          .filter((locality: any) => locality.city === selectedCity)
          .map((locality: any) => locality.locality);
        const allLocalityIds = localityData
          .filter((locality: any) => locality.city === selectedCity)
          .map((locality: any) => locality.polygonId);
        setLocalityKeys(allLocalities);
        setSelectedLocalityID(allLocalityIds);
        setSelectAll(true); // Update state to reflect "All" are selected
      } else {
        // If all are currently selected, unselect all
        setLocalityKeys([]);
        setSelectedLocalityID([]);
        setSelectAll(false);
      }
    } else {
      // Handle individual locality selection
      const selectedLocalityIds = localityData
        .filter((locality: any) => selectedValues.includes(locality.locality))
        .map((locality: any) => locality.polygonId);
      setLocalityKeys(selectedValues);
      setSelectedLocalityID(selectedLocalityIds);
      // If not all localities are selected, ensure "selectAll" is false
      setSelectAll(
        selectedValues.length ===
          localityData.filter((locality: any) => locality.city === selectedCity)
            .length
      );
    }
  };

  const StyledAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: "#8B97FF",
    width: theme.spacing(14),
    height: theme.spacing(14),
    fontSize: "30px",
    fontWeight: "bold",
    color: "#FFFFFF",
    alignItems: "center",
    marginBottom: "6px",
    borderStyle: "solid",
    borderColor: "white",
    borderRadius: "50%",
  }));
  function avatarName(fullName: string | null) {
    if (fullName === null || fullName === undefined) {
      return "Default";
    }
    const names = fullName?.split(" ");
    if (!names || names.length === 0) {
      return "";
    }
    if (names.length === 1) {
      return names[0]?.charAt(0) || "";
    } else {
      const firstNameInitial = names[0]?.charAt(0) || "";
      const lastNameInitial = names[names.length - 1]?.charAt(0) || "";
      return firstNameInitial + lastNameInitial;
    }
  }
  const [selectedCard, setSelectedCard] = useState<number>(2);

  const handleCardClick = (cardId: number) => {
    setSelectedCard(cardId);
  };

  useEffect(() => {
    OrderSummaryApi.api
      .localityListApi()
      .then((e) => {
        setLocalityData(e.bifurcations);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
    OrderSummaryApi.api
      .cityListApi()
      .then((e) => {
        setCityData(e.bifurcations);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    RiderSupplyHealthApi.api
      .orderCardDetails(selectedLocalityID)
      .then((e) => {
        setOrderCardDetails(e.bifurcations[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API call error: ", error);
        setLoading(false);
      });
    RiderSupplyHealthApi.api
      .onDutyRiders(selectedLocalityID)
      .then((e) => {
        setRiderData(e.bifurcations);
        // setLoading(false);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, [selectedLocalityID]);
  useEffect(() => {
    RiderSupplyHealthApi.api
      .riderData(selectedUser)
      .then((e) => {
        setRiderInfo(e.bifurcations[0]);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, [selectedUser]);

  // console.log(riderInfo, "mk");
  const handleMarkerClick = (rider: any) => {
    setSelectedUser(rider.userid);
  };
  const cardData: CardData[] = [
    {
      heading: "Total On Duty Riders",
      backgroundColor: "#00CB6033",
      data: orderCardDetails?.on_duty_riders || "--",
      comparison: "50",
      imgSrc: "/assets/icons/greenRiderIcon.svg",
      increment: false,
      id: 1,
    },
    {
      heading: "Total Active Riders",
      backgroundColor: "#8B97FF33",
      data: orderCardDetails?.active_free_riders || "--",
      comparison: "50",
      imgSrc: "/assets/icons/lavenderRiderIcon.svg",
      increment: true,
      id: 2,
    },
    {
      heading: "Total Dedicated Riders",
      backgroundColor: "#171F2133",
      data: orderCardDetails?.dedicated_rider_count || "--",
      comparison: "50",
      imgSrc: "/assets/icons/blackRiderIcon.svg",
      increment: false,
      id: 3,
    },
    {
      heading: "Total Stale Riders",
      backgroundColor: "#FFB84633",
      data: orderCardDetails?.stale_riders || "--",
      comparison: "50",
      imgSrc: "/assets/icons/yellowRiderIcon.svg",
      increment: true,
      id: 4,
    },
    {
      heading: "Total Busy Riders",
      backgroundColor: "#F9493233",
      data: orderCardDetails?.active_busy_riders || "--",
      comparison: "50",
      imgSrc: "/assets/icons/redRiderIcon.svg",
      increment: false,
      id: 5,
    },
  ];

  return (
    <>
      <div className="flex">
        <div className="w-1/6 mr-3 ml-3">
          <SideBarNavigation />
        </div>
        <div className="w-5/6">
          <div className="pb-4 pr-4 pl-2 mt-6">
            <Header merchantLogoUrl="" />
            <div className="flex justify-between mt-8">
              <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-inter">
                Rider Supply Health
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  sx={{ mt: 4 }}
                >
                  <Typography
                    color="#ACACAC"
                    sx={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    Dashboard
                  </Typography>
                  <Typography
                    color="#717171"
                    sx={{ fontSize: "12px", fontWeight: "700" }}
                  >
                    Rider Supply Health
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="flex mb-1 justify-between items-center pr-5">
            {cardData.map((card) => (
              <div
                key={card.id}
                className="w-full mr-2"
                onClick={() => handleCardClick(card.id)}
              >
                <DashboardCardRiderSupply
                  heading={card.heading}
                  backgroundColor={card.backgroundColor}
                  data={card.data}
                  comparison={card.comparison}
                  imgSrc={card.imgSrc}
                  increment={card.increment}
                  isSelected={selectedCard === card.id}
                  loading={loading}
                />
              </div>
            ))}
          </div>
          <div
            className="ml-2 mb-4 w-[98%] pb-3"
            style={{
              boxShadow: "0px 7px 29px 0px #64646F33",
              borderRadius: "8px",
            }}
          >
            <div className="w-full flex justify-between mr-2 ml-2 p-2">
              <div className="flex">
                <div className="mr-3">
                  <div className="border-2 flex rounded-3xl flex items-center px-1">
                    <label className="text-[#727597] items-center text-sm font-normal font-inter">
                      City:
                    </label>
                    <select
                      name="locality"
                      id="locality"
                      className="text-[#727597] text-sm font-normal font-inter p-2 min-w-[100px]"
                      value={selectedCity}
                      onChange={(e) => {
                        setSelectedCity(e.target.value);
                        setLocalityKeys([]); // Clear the selected localities
                        setSelectAll(false);
                      }}
                    >
                      {cityData.map((city: any) => (
                        <option key={city.city_code} value={city.city_code}>
                          {city.city_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <FormControl sx={{ border: "none", width: 300 }}>
                    <Select
                      multiple
                      displayEmpty
                      value={localityKeys}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          sx={{ borderRadius: "50px", borderWidth: "2px" }}
                          startAdornment={
                            <label className="text-[#727597] text-sm font-normal font-inter mr-2">
                              Locality:
                            </label>
                          }
                          size="small"
                        />
                      }
                      renderValue={(selected) => {
                        return selected.join(", ");
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="All Localities">
                        <Checkbox
                          checked={
                            selectAll ||
                            localityKeys.length ===
                              localityData.filter(
                                (locality: any) =>
                                  locality.city === selectedCity
                              ).length
                          }
                        />
                        <ListItemText
                          primary={selectAll ? "Unselect All" : "Select All"}
                        />
                      </MenuItem>

                      {localityData.filter(
                        (locality: any) => locality.city === selectedCity
                      ).length === 0 ? (
                        <MenuItem value="-1" disabled>
                          No Localities Available
                        </MenuItem>
                      ) : (
                        localityData
                          .filter(
                            (locality: any) => locality.city === selectedCity
                          )
                          .map((locality: any) => (
                            <MenuItem
                              key={locality.polygonId}
                              value={locality.locality}
                            >
                              <Checkbox
                                checked={localityKeys.includes(
                                  locality.locality
                                )}
                              />
                              <ListItemText primary={locality.locality} />
                            </MenuItem>
                          ))
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-2/3">
                {/* {loading ? (
                  <>
                    <LinearProgress
                      sx={{
                        backgroundColor: "white",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#00CD5B",
                        },
                      }}
                    />
                  </>
                ) : ( */}
                <>
                  <GoogleRiderSupplyMapApi
                    riderData={riderData}
                    onMarkerClick={handleMarkerClick}
                  />
                </>
                {/* )} */}
              </div>
              <div className="w-1/3 min-h-[80vh] pl-2">
                <Card
                  sx={{
                    elevation: 0,
                    mb: 2,
                    mr: 2,
                    boxShadow: "none",
                    height: "100%",
                  }}
                  className={` rounded-lg border border border-[#ECEEFF]"
      }`}
                >
                  <div className="border-b flex justify-between align-center">
                    <div className="p-2">
                      <Typography className="text-[#263238] font-inter text-lg font-semibold mb-2">
                        Rider Details
                      </Typography>
                    </div>
                  </div>
                  <div className="p-2">
                    {selectedUser !== "-1" ? (
                      <>
                        <StyledAvatar
                          aria-label="recipe"
                          className="my-1 mb-3 p-0.5"
                        >
                          {avatarName(riderInfo?.rider_name)}
                        </StyledAvatar>
                        <div className="flex items-center m-2 mb-4">
                          <Typography className="font-inter font-medium text-[#727597]">
                            User ID -
                          </Typography>
                          <Typography className="font-inter font-semibold  text-[#263238]">
                            {riderInfo?.user_id}
                          </Typography>
                        </div>
                        <div className="flex items-center m-2 mb-4">
                          <Typography className="font-inter font-medium mr-2 text-[#727597]">
                            Rider Name -
                          </Typography>
                          <Typography className="font-inter font-semibold mr-2 text-[#263238]">
                            {riderInfo?.rider_name}
                          </Typography>
                        </div>
                        <div className="flex items-center m-2 mb-4">
                          <Typography className="font-inter font-medium mr-2 text-[#727597]">
                            Mobile No. -
                          </Typography>
                          <Typography className="font-inter font-semibold mr-2 text-[#263238]">
                            {riderInfo?.rider_mobile}
                          </Typography>
                        </div>
                        <div className="flex items-center m-2">
                          <Typography className="font-inter font-medium text-[#727597]">
                            Locality -
                          </Typography>
                          <Typography className="font-inter font-semibold  text-[#263238]">
                            {riderInfo?.locality}
                          </Typography>
                        </div>
                      </>
                    ) : (
                      <Typography className="text-[#727597] font-inter font-semibold mb-2">
                        Please select a rider from the map to view its details
                        here.
                      </Typography>
                    )}
                  </div>
                </Card>
              </div>
            </div>
            <div>
              {selectedCard && (
                <div>
                  {selectedCard === 1 && (
                    <div>
                      <RiderSupplyTable
                        riderStatus={selectedCard}
                        polygonIds={selectedLocalityID}
                      />
                    </div>
                  )}
                  {selectedCard === 2 && (
                    <div>
                      <RiderSupplyTable
                        riderStatus={selectedCard}
                        polygonIds={selectedLocalityID}
                      />
                    </div>
                  )}
                  {selectedCard === 3 && (
                    <div>
                      <RiderSupplyTable
                        riderStatus={selectedCard}
                        polygonIds={selectedLocalityID}
                      />
                    </div>
                  )}
                  {selectedCard === 4 && (
                    <div>
                      <RiderSupplyTable
                        riderStatus={selectedCard}
                        polygonIds={selectedLocalityID}
                      />
                    </div>
                  )}
                  {selectedCard === 5 && (
                    <div>
                      <RiderSupplyTable
                        riderStatus={selectedCard}
                        polygonIds={selectedLocalityID}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RiderSupplyHealth;
