import React from "react";
import {
  Card,
  Chip,
  TextField,
  InputLabel,
  NativeSelect,
  FormControl,
  Button,
  InputAdornment,
  Breadcrumbs,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import UploadFiles from "../../Layouts/UploadFiles/UploadFiles";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import PickupMarkerModal from "../../Modals/PickupMarkerModal";
import DropMarkerModal from "../../Modals/DropMarkerModal";
import Header from "../../Layouts/AppHeader/Header";
export default function CreateOrder() {
  return (
    <>
      <div className="flex pb-8 justify-between">
        <div className="w-1/6 mr-3">
          <SideBarNavigation />
        </div>

        <div className="w-5/6">
          <div className="pb-4 pl-2 pr-4">
            <Header merchantLogoUrl="" />

            <div className="font-medium	text-[32px] text-[#1E1E1E] text-left font-inter">
              Create Bulk Order
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mt: 4 }}
              >
                <Typography
                  color="#ACACAC"
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                >
                  <a href="/home">Dashboard</a>
                </Typography>
                <Typography
                  color="#ACACAC"
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                >
                  Orders
                </Typography>
                <Typography
                  color="#ACACAC"
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                >
                  <a href="/create-orders">Create Order</a>
                </Typography>
                <Typography
                  color="#717171"
                  sx={{ fontSize: "12px", fontWeight: "700" }}
                >
                  Create Bulk Order
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <Card
            className="w-full p-4 mt-4"
            sx={{ boxShadow: "0px 7px 29px 0px #64646F33" }}
          >
            <div className="p-4 pr-4 w-full font-inter font-medium text-lg text-[#000000]">
              {/* <UploadFiles width="28%" /> */}
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
