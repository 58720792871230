import React, { useState, useEffect } from "react";
import GPlace from "./DropGPlace";
import { Place } from "../../../../@Types/ComponentsInterface/OrderSelectProps";

const GOOGLE_MAP_API_KEY = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";

type LoadGoogleMapCallback = () => void;

const loadGoogleMapScript = (callback: LoadGoogleMapCallback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

interface MapAutoCompleteProps {
  onPlaceSelect: (place: Place | null) => void;
  value?: Place;
}

const MapAutoCompleteDrop: React.FC<MapAutoCompleteProps> = ({
  onPlaceSelect,
  value,
}) => {
  const [loadMap, setLoadMap] = useState(false);
  const [pinLocation, setPinLocation] = useState<Place | null>(null);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  const onPinChange = (changedLocationDetails: Place) => {
    setPinLocation(changedLocationDetails);
  };

  return (
    <div className="auto-complete">
      {!loadMap ? (
        <div>Loading...</div>
      ) : (
        <>
          <GPlace
            value={value}
            onPlaceSelect={onPlaceSelect}
            onPinChange={onPinChange}
          />
        </>
      )}
    </div>
  );
};

export default MapAutoCompleteDrop;
