import React, { useEffect, useState } from "react";
import {
  Card,
  Chip,
  TextField,
  InputLabel,
  NativeSelect,
  FormControl,
  Button,
  Divider,
  InputAdornment,
  Breadcrumbs,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import UploadFiles from "../../Layouts/UploadFiles/UploadFiles";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import PickupMarkerModal from "../../Modals/PickupMarkerModal";
import DropMarkerModal from "../../Modals/DropMarkerModal";
import Header from "../../Layouts/AppHeader/Header";
import FullFeaturedCrudGrid from "../../Layouts/EditableBillTable";
import MapAutoCompleteDrop from "../../Layouts/OrderAddressSelect/DropLocation/FormDrop";
import { Place } from "../../../@Types/ComponentsInterface/OrderSelectProps";
import MapAutoCompletePickup from "../../Layouts/OrderAddressSelect/PickUpLocation/FormPickUp";
export default function CreateOrder() {
  const [selectedPlaceDrop, setSelectedPlaceDrop] = useState<
    Place | undefined
  >();

  const handlePlaceSelectDrop = (place: Place | null) => {
    setSelectedPlaceDrop(place || undefined);
  };
  const [selectedPlacePickup, setSelectedPlacePickup] = useState<
    Place | undefined
  >();

  const handlePlaceSelectPickup = (place: Place | null) => {
    setSelectedPlacePickup(place || undefined);
  };
  return (
    <>
      <div className="flex pb-8 justify-between ml-8">
        <div className="w-1/6 mr-3">
          <SideBarNavigation />
        </div>

        <div className="w-5/6 ml-4">
          <div className="pb-4 pl-2 pr-4 mt-6">
            <Header merchantLogoUrl="" />

            <div className="font-medium	text-[32px] text-[#1E1E1E] text-left font-inter mt-8">
              Create Order
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mt: 4 }}
              >
                <Typography
                  color="#ACACAC"
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                >
                  <a href="/home">Dashboard</a>
                </Typography>
                <Typography
                  color="#ACACAC"
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                >
                  Orders
                </Typography>
                <Typography
                  color="#717171"
                  sx={{ fontSize: "12px", fontWeight: "700" }}
                >
                  Create Order
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <Card
            className="w-full mt-4"
            sx={{ boxShadow: "0px 7px 29px 0px #64646F33" }}
          >
            <div className="grid grid-cols-5 gap-x-12 gap-y-12 p-4">
              <div>
                <TextField
                  label="Merchant Name"
                  id="standard-size-small"
                  defaultValue=""
                  size="small"
                  InputLabelProps={{
                    style: { color: "#ACACAC" }, // Change label color here
                    shrink: true,
                  }}
                  variant="standard"
                />
              </div>
              <div className="">
                <TextField
                  label="Order Type"
                  id="standard-size-small"
                  defaultValue=""
                  size="small"
                  InputLabelProps={{
                    style: { color: "#ACACAC" }, // Change label color here
                    shrink: true,
                  }}
                  InputProps={{
                    style: { borderBottomColor: "#E9E9E9" }, // Change border color here
                  }}
                  variant="standard"
                />
              </div>
              <div className="">
                <TextField
                  label="Merchant Pincode"
                  id="standard-size-small"
                  defaultValue=""
                  size="small"
                  InputLabelProps={{
                    style: { color: "#ACACAC" }, // Change label color here
                    shrink: true,
                  }}
                  variant="standard"
                />
              </div>
              <div className="">
                <FormControl fullWidth size="small">
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    sx={{ color: "#ACACAC" }}
                  >
                    Merchant City
                  </InputLabel>
                  <NativeSelect
                    defaultValue={30}
                    inputProps={{
                      name: "age",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                  </NativeSelect>
                </FormControl>
              </div>

              <div className="">
                <FormControl fullWidth size="small" sx={{ color: "#ACACAC" }}>
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    sx={{ color: "#ACACAC" }}
                  >
                    Merchant State
                  </InputLabel>
                  <NativeSelect
                    defaultValue={30}
                    inputProps={{
                      name: "age",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="col-span-2">
                <MapAutoCompletePickup
                  value={selectedPlacePickup}
                  onPlaceSelect={handlePlaceSelectPickup}
                />
              </div>
              <div className="col-span-2">
                <MapAutoCompleteDrop
                  value={selectedPlaceDrop}
                  onPlaceSelect={handlePlaceSelectDrop}
                />
              </div>
              <div className="">
                <FormControl fullWidth size="small" sx={{ color: "#ACACAC" }}>
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    sx={{ color: "#ACACAC" }}
                  >
                    Capacity
                  </InputLabel>
                  <NativeSelect
                    defaultValue={30}
                    inputProps={{
                      name: "age",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={5}>5Kg</option>
                    <option value={10}>10Kg</option>
                    <option value={50}>50Kg</option>
                    <option value={100}>100Kg</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div>
                <TextField
                  label="Customer's Name"
                  id="standard-size-small"
                  defaultValue=""
                  size="small"
                  InputLabelProps={{
                    style: { color: "#ACACAC" }, // Change label color here
                    shrink: true,
                  }}
                  variant="standard"
                />
              </div>
              <div className="">
                <TextField
                  label="Customer's Mobile"
                  id="standard-size-small"
                  defaultValue=""
                  size="small"
                  InputLabelProps={{
                    style: { color: "#ACACAC" }, // Change label color here
                    shrink: true,
                  }}
                  InputProps={{
                    style: { borderBottomColor: "#E9E9E9" }, // Change border color here
                  }}
                  variant="standard"
                />
              </div>
              <div className="">
                <TextField
                  label="Amount"
                  id="standard-size-small"
                  defaultValue=""
                  size="small"
                  InputLabelProps={{
                    style: { color: "#ACACAC" }, // Change label color here
                    shrink: true,
                  }}
                  variant="standard"
                />
              </div>
              <div className="">
                <TextField
                  label="Payment Method"
                  id="standard-size-small"
                  defaultValue=""
                  size="small"
                  InputLabelProps={{
                    style: { color: "#ACACAC" }, // Change label color here
                    shrink: true,
                  }}
                  variant="standard"
                />
              </div>
            </div>
            <Divider sx={{ mt: 3 }} />
            <div className="p-4">
              <FullFeaturedCrudGrid />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
