import React from "react";
import Modal from "@mui/material/Modal";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import NearbyRiderModalMap from "./NearbyRiderModalMap";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 960,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

export default function NearbyRiderModal({
  orderNumber,
  sourceLat,
  sourceLong,
}: any) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <div>
      <div
        className="bg-[#8B97FF] px-4 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
        onClick={handleOpen}
      >
        <span className="text-[#FFFFFF] text-sm font-medium font-inter">
          Nearby Rider
        </span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-between mb-1">
              <Typography className="font-medium font-inter">
                Nearby Riders
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="flex w-full">
              <NearbyRiderModalMap
                orderNumber={orderNumber}
                sourceLat={sourceLat}
                sourceLong={sourceLong}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
