import { TextField, FormControl } from "@mui/material";

import UpdateTicketModal from "../../Modals/UpdateTicketModal";

interface TicketExtraDetailsProps {
  ticketId: number;
  subTypeListId: number;
  ticketType: number;
  orderCreatedTime: string;
  orderCode: number;
  status: number;
  customerName: string;
  destinationAddress: string;
  orderReferenceNumber: string;
  pickupDistance: number;
  deliveryDistance: number;
  orderAmount: number;
  riderAmount: number;
}

export default function TicketDetails(props: TicketExtraDetailsProps) {
  const {
    ticketId,
    subTypeListId,
    ticketType,
    orderCreatedTime,
    orderCode,
    status,
    customerName,
    destinationAddress,
    orderReferenceNumber,
    pickupDistance,
    deliveryDistance,
    orderAmount,
    riderAmount,
  } = props;

  const extractTime = (datetime: string) => {
    const timePart = datetime?.split(" ")[1];
    return timePart;
  };
  return (
    <>
      <div className="flex">
        <div className="w-2/5">
          <UpdateTicketModal
            ticketId={ticketId}
            modalType="Update Ticket Type"
            ticketType={ticketType}
            disabled={status === 0 || status === 2}
            subTypeListId={subTypeListId}
          />
        </div>
        <div className="ml-2 w-1/5">
          <FormControl fullWidth>
            <TextField
              label="Order Creation Time"
              id="standard-size-small"
              defaultValue={extractTime(orderCreatedTime) || 0}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-2 w-1/5">
          <FormControl>
            <TextField
              label="Order Code"
              id="standard-size-small"
              defaultValue={orderCode || "0"}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-2 w-1/5">
          <FormControl>
            <TextField
              label="Customer Name"
              id="standard-size-small"
              defaultValue={customerName || ""}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
      </div>

      <div className="flex mt-8">
        <div className="w-2/5">
          <FormControl fullWidth>
            <TextField
              id="standard-number"
              label="Destination"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={destinationAddress || ""}
              disabled={true}
              multiline
            />
          </FormControl>
        </div>

        <div className="ml-2 w-1/5">
          <FormControl>
            <TextField
              id="standard-number"
              label="Order Reference No"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={orderReferenceNumber || "0"}
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-2 w-1/5">
          <FormControl>
            <TextField
              label="Pickup Distance"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={pickupDistance || "0"}
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-2 w-1/5">
          <FormControl fullWidth>
            <TextField
              id="standard-number"
              label="Delivery Distance"
              type="number"
              defaultValue={deliveryDistance || "0"}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
      </div>

      <div className="flex mt-8">
        <div className="w-1/5">
          <FormControl>
            <TextField
              label="Order Amount"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={orderAmount || "zypp"}
              disabled={true}
            />
          </FormControl>
        </div>

        <div className="ml-2 w-1/5">
          <FormControl>
            <TextField
              id="standard-number"
              label="Rider Amount"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={riderAmount || "0"}
              disabled={true}
            />
          </FormControl>
        </div>
      </div>
    </>
  );
}
