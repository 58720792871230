import React from "react";
import { TextField, FormControl, Typography } from "@mui/material";

export default function AllOrderAndBroadcast({ extraDetails }: any) {
  return (
    <>
      <div className="grid grid-cols-6 gap-2 gap-x-4">
        <div className="border-b-[2.5px] border-[#E9E9E9]">
          <Typography
            className="font-inter text-[#ACACAC]"
            sx={{ fontSize: "14px" }}
          >
            Order Reference No
          </Typography>
          <Typography
            className="font-inter text-[#414141] pt-2"
            sx={{
              fontSize: "14px",
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {extraDetails?.orderReferenceNumber}
          </Typography>
        </div>
        <div className="border-b-[2.5px] border-[#E9E9E9]">
          <Typography
            className="font-inter text-[#ACACAC]"
            sx={{ fontSize: "14px" }}
          >
            First Mile Distance
          </Typography>
          <Typography
            className="font-inter text-[#414141] pt-2"
            sx={{ fontSize: "14px" }}
          >
            {extraDetails?.firstMileDistance}
          </Typography>
        </div>
        <div className="border-b-[2.5px] border-[#E9E9E9]">
          <Typography
            className="font-inter text-[#ACACAC]"
            sx={{ fontSize: "14px" }}
          >
            Last Mile Distance
          </Typography>
          <Typography
            className="font-inter text-[#414141] pt-2"
            sx={{ fontSize: "14px" }}
          >
            {extraDetails?.lastMileDistance}
          </Typography>
        </div>
        <div className="border-b-[2.5px] border-[#E9E9E9]">
          <Typography
            className="font-inter text-[#ACACAC]"
            sx={{ fontSize: "14px" }}
          >
            City
          </Typography>
          <Typography
            className="font-inter text-[#414141] pt-2"
            sx={{ fontSize: "14px" }}
          >
            {extraDetails?.city}
          </Typography>
        </div>
        <div className="border-b-[2.5px] border-[#E9E9E9]">
          <Typography
            className="font-inter text-[#ACACAC]"
            sx={{ fontSize: "14px" }}
          >
            Broadcast Status
          </Typography>
          <Typography
            className="font-inter text-[#414141] pt-2"
            sx={{ fontSize: "14px" }}
          >
            {extraDetails?.broadcastStatus}
          </Typography>
        </div>
        <div className="border-b-[2.5px] border-[#E9E9E9]">
          <Typography
            className="font-inter text-[#ACACAC]"
            sx={{ fontSize: "14px" }}
          >
            Cancelled By
          </Typography>
          <Typography
            className="font-inter text-[#414141] pt-2"
            sx={{ fontSize: "14px" }}
          >
            {extraDetails?.cancelledByMerchant}
          </Typography>
        </div>
        <div className="border-b-[2.5px] border-[#E9E9E9]">
          <Typography
            className="font-inter text-[#ACACAC]"
            sx={{ fontSize: "14px" }}
          >
            Hub Name
          </Typography>
          <Typography
            className="font-inter text-[#414141] pt-2"
            sx={{ fontSize: "14px" }}
          >
            {extraDetails?.hubName}
          </Typography>
        </div>
        <div className="border-b-[2.5px] col-span-3 border-[#E9E9E9]">
          <Typography
            className="font-inter text-[#ACACAC]"
            sx={{ fontSize: "14px" }}
          >
            Hub Address
          </Typography>
          <Typography
            className="font-inter text-[#414141] pt-2"
            sx={{
              fontSize: "14px",
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {extraDetails?.hubAddress}
          </Typography>
        </div>
        <div className="border-b-[2.5px] border-[#E9E9E9]">
          <Typography
            className="font-inter text-[#ACACAC]"
            sx={{ fontSize: "14px" }}
          >
            Final Order User ID
          </Typography>
          <Typography
            className="font-inter text-[#414141] pt-2"
            sx={{ fontSize: "14px" }}
          >
            {extraDetails?.finalOrderAllotedUserId}
          </Typography>
        </div>
      </div>
    </>
  );
}
