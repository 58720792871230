import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Select,
  Divider,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadFiles from "../Layouts/UploadFiles/UploadFiles";
import { FormControl } from "@mui/base";
import { BlacklistedRidersApi } from "../../API/BlacklistedRiders";
import Papa from "papaparse";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
};

export default function BanRidersModal() {
  const [open, setOpen] = React.useState(false);
  const [remarks, setRemarks] = useState<string>("");
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [fileData, setFileData] = useState<
    { userId: number; restrictRemark: string }[]
  >([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFileUploaded(false);
    setFileData([]);
    setBulkBannedStatus("TEMPORARY");
    setSingleBannedStatus("TEMPORARY");
  };
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [bulkBannedStatus, setBulkBannedStatus] = useState("TEMPORARY");
  const [singleBannedStatus, setSingleBannedStatus] = useState("TEMPORARY");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [disableFirstHalf, setDisableFirstHalf] = useState(false);
  const [disableSecondHalf, setDisableSecondHalf] = useState(false);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };

  const handleBulkSelectChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setBulkBannedStatus(value);
    // setSingleInputUsed(true);
  };
  const handleSingleSelectChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setSingleBannedStatus(value);
    // setSingleInputUsed(true);
  };

  const handleUserIdTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserId(e.target.value);
  };
  const handleRemarksTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRemarks(e.target.value);
  };

  useEffect(() => {
    if (
      fileUploaded &&
      (bulkBannedStatus === "PERMANENT" || bulkBannedStatus === "TEMPORARY")
    ) {
      setDisableFirstHalf(true);
    } else if (!fileUploaded && bulkBannedStatus === "PERMANENT") {
      setDisableFirstHalf(true);
    } else if (!fileUploaded && bulkBannedStatus === "TEMPORARY") {
      setDisableFirstHalf(false);
    }
  }, [fileUploaded, bulkBannedStatus]);
  useEffect(() => {
    if (
      (userId !== "" || remarks !== "") &&
      (singleBannedStatus === "PERMANENT" || singleBannedStatus === "TEMPORARY")
    ) {
      setDisableSecondHalf(true);
    } else if (
      (userId === "" || remarks === "") &&
      singleBannedStatus === "PERMANENT"
    ) {
      setDisableSecondHalf(true);
    } else if (
      (userId === "" || remarks === "") &&
      singleBannedStatus === "TEMPORARY"
    ) {
      setDisableSecondHalf(false);
    }
  }, [userId, remarks, singleBannedStatus]);

  const handleFileDrop = (file: File | null) => {
    if (file) {
      setFileUploaded(true);
      parseCSV(file).then((data) => {
        const keyValuePairs = transformData(data);
        setFileData(keyValuePairs.data);
      });
    } else {
      setFileUploaded(false);
      setFileData([]);
    }
  };

  const parseCSV = (
    file: File
  ): Promise<{ userId: string; restrictRemark: string }[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          resolve(results.data as { userId: string; restrictRemark: string }[]);
        },
        error: (error) => {
          reject(error.message);
        },
      });
    });
  };

  const transformData = (
    data: { userId: string; restrictRemark: string }[]
  ): { data: { userId: number; restrictRemark: string }[] } => {
    return {
      data: data.map(({ userId, restrictRemark }) => ({
        userId: parseInt(userId, 10),
        restrictRemark: restrictRemark?.trim(),
      })),
    };
  };

  const handleBanning = () => {
    if (userId !== "") {
      const data = [
        {
          userId: userId,
          restrictRemark: remarks,
        },
      ];
      BlacklistedRidersApi.api
        .banRiders(singleBannedStatus, data)
        .then((response) => {
          if (response.code === 2000) {
            setShowSuccessSnackbar(true);
            setSnackbarMessage("Rider banned successfully");
            handleClose();
          } else {
            setSnackbarMessage(response.message);
            setShowErrorSnackbar(true);
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Failed to ban rider";
          setShowErrorSnackbar(true);
          setSnackbarMessage(errorMessage);
        });
    } else {
      BlacklistedRidersApi.api
        .banRiders(bulkBannedStatus, fileData)
        .then((response) => {
          if (response.code === 2000) {
            setShowSuccessSnackbar(true);
            setSnackbarMessage("Rider banned successfully");
            handleClose();
          } else {
            setSnackbarMessage(response.message);
            setShowErrorSnackbar(true);
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Failed to ban riders";
          setShowErrorSnackbar(true);
          setSnackbarMessage(errorMessage);
        });
    }
  };

  return (
    <div>
      <Button
        className="mb-2"
        variant="contained"
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          px: 3,
          py: 1,
          bgcolor: "#171F21",
          "&:hover": { bgcolor: "#171F2180" },
          borderRadius: "30px",
        }}
      >
        <img src="/assets/icons/whitePlusIcon.svg" alt="" className="mr-2" />
        Ban User(s)
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-between mb-2">
              <Typography className="font-bold text-xl text-[#000000] font-inter">
                Ban Single or Multiple Users
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="flex">
              <div className="w-2/5 p-2 px-4">
                <FormControl>
                  <TextField
                    label="User ID"
                    id="standard-size-small"
                    type="number"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    sx={{ my: 2 }}
                    onChange={handleUserIdTextFieldChange}
                    disabled={disableFirstHalf || false}
                  />
                </FormControl>
                <div className="w-4/5">
                  <FormControl>
                    <InputLabel
                      shrink={true}
                      style={{
                        color: "#00000099",
                        fontSize: "16px",
                        fontWeight: "400",
                        marginTop: "30px",
                      }}
                    >
                      Selected Status
                    </InputLabel>
                    <Select
                      size="small"
                      fullWidth
                      value={singleBannedStatus}
                      onChange={handleSingleSelectChange}
                      disabled={disableFirstHalf || false}
                    >
                      <MenuItem value="PERMANENT">Permanently Banned</MenuItem>
                      <MenuItem value="TEMPORARY">Temporarily Banned</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <TextField
                  label="Remarks"
                  id="standard-size-small"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                  fullWidth
                  sx={{ my: 2, mt: 4 }}
                  onChange={handleRemarksTextFieldChange}
                  disabled={disableFirstHalf || false}
                />
              </div>
              <div className="p-2 px-4 w-3/5">
                <div className="flex justify-start">
                  <Divider
                    orientation="vertical"
                    style={{ height: "90%", color: "#ECEBEB" }}
                  />
                </div>
                <div className="flex justify-center">
                  <UploadFiles
                    width="60%"
                    onFileDrop={handleFileDrop}
                    disableUpload={disableSecondHalf}
                  />
                </div>
                <div className="flex justify-center w-full mt-4">
                  <FormControl className="w-3/5">
                    <InputLabel
                      shrink={true}
                      style={{
                        color: "#00000099",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      Ban Type
                    </InputLabel>
                    <Select
                      size="small"
                      fullWidth
                      value={bulkBannedStatus}
                      onChange={handleBulkSelectChange}
                      disabled={disableSecondHalf}
                    >
                      <MenuItem value="PERMANENT">Permanently Banned</MenuItem>
                      <MenuItem value="TEMPORARY">Temporarily Banned</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-4 items-center pb-4">
              <Button
                sx={{ textTransform: "none", color: "#FE7062", py: 1, px: 8 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="mb-2"
                variant="contained"
                onClick={handleBanning}
                sx={{
                  ml: 3,
                  color: "white",
                  borderRadius: "50px",
                  textTransform: "none",
                  px: 8,
                  py: 1,
                  bgcolor: "#171F21",
                  "&:hover": { bgcolor: "#171F2133" },
                  fontSize: "14px",
                }}
              >
                Ban Users
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
