import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  Tooltip,
} from "@mui/material";
// import BasicModal from "../../Modals/NewFleetDemandModal";
import "./TableCss.css";
import "animate.css/animate.min.css";
import Chip from "@mui/material/Chip";
import { OrderSummaryApi } from "../../../API/OrderSummaries";
import SearchIcon from "@mui/icons-material/Search";
import AllOrderAndBroadcast from "../OrderSummaryExpandedRows/AllOrderAndBroadcast";
import { CSVLink } from "react-csv";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

interface OngoingOrdersTableProps {
  selectedLocality: string[];
  startDate: string | undefined;
  endDate: string | undefined;
  selectedMerchant: number;
}
export default function AllOrderAndBroadcastTable({
  selectedLocality,
  startDate,
  endDate,
  selectedMerchant,
}: OngoingOrdersTableProps) {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const handleExpandToggle = (orderNumber: string, riderId: string) => {
    const uniqueId = `${orderNumber}-${riderId}`;
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(uniqueId)
        ? prevExpandedRows.filter((id) => id !== uniqueId)
        : [...prevExpandedRows, uniqueId]
    );
  };
  interface OrderStatusColor {
    text: string;
    color: string;
    bgcolor: string;
  }
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [ordersCSVData, setOrdersCSVData] = useState<any>([]);
  const [ordersData, setOrdersData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [refresh, setRefresh] = useState(true);
  const pageRange = 5;
  const header = [
    { label: "Order Number", key: "orderNumber" },
    { label: "Order Creation Time", key: "orderCreationTime" },
    { label: "Order Reference Number", key: "orderReferenceNumber" },
    { label: "Order Status", key: "orderStatus" },
    { label: "Merchant ID", key: "merchantId" },
    { label: "Broadcast Status", key: "broadcastStatus" },
    { label: "First Mile Distance", key: "firstMileDistance" },
    { label: "Last Mile Distance", key: "lastMileDistance" },
    { label: "Cancelled By", key: "cancelledBy" },
    { label: "Rider ID", key: "riderId" },
    { label: "Final Order Alloted User ID", key: "finalOrderAllotedUserId" },
    { label: "Hub Name", key: "hubName" },
    { label: "Hub Address", key: "hubAddress" },
    { label: "City", key: "city" },
    { label: "Rider Name", key: "name" },
    { label: "Rider Mobile No", key: "mobile" },
    { label: "Merchant Name", key: "merchantName" },
    { label: "Cancelled By Merchant", key: "cancelledByMerchant" },
  ];
  useEffect(() => {
    setLoading(true);
    const apiUrl = `${searchVal ? `?order_id=${searchVal}` : ""}`;
    if (endDate) {
      OrderSummaryApi.api
        ?.orderAndBroadcastListApi(
          selectedMerchant,
          startDate,
          endDate,
          selectedLocality,
          page + 1,
          apiUrl
        )
        .then((e) => {
          setLoading(false);
          if (parseInt(e.data.total_rows) > 0) {
            setOrdersData(e.data.data);
            setTotalCount(parseInt(e.data.total_rows));
          } else {
            setOrdersData([]);
            setTotalCount(parseInt(e.data.total_rows));
            setShowSnackbar(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          setShowSnackbar(true);
          console.error("API call error: ", error);
        });
      const daysParams =
        selectedLocality && selectedLocality.length > 0
          ? selectedLocality.map((loc) => `locality_id=${loc}`).join("&")
          : "";
      const apiUrl2 = `https://data.bcykal.com/tracker/ondemand/orders/broadcasts/details?start_date=${startDate}&end_date=${endDate}&merchant_id=${selectedMerchant}&${daysParams}`;

      fetch(apiUrl2)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // setLoading(false);
          if (parseInt(data.data.total_rows) > 0) {
            setOrdersCSVData(data.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching all orders: ", error);
        });
    }
  }, [searchVal, refresh, page, selectedLocality, endDate, selectedMerchant]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
    setPage(newPageNumber);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  const orderStatusColors: Record<number, OrderStatusColor> = {
    0: { text: "Unassigned", color: "#FF725E", bgcolor: "#FF725E33" },
    1: { text: "Going for Pickup", color: "#FFB846", bgcolor: "#FFF6E8" },
    2: {
      text: "Reached Pickup Location",
      color: "#FFB846",
      bgcolor: "#FFF6E8",
    },
    3: { text: "Order Picked", color: "#00CB60", bgcolor: "#00CB6026" },
    4: { text: "Reached Gate", color: "#FFB846", bgcolor: "#FFF6E8" },
    5: { text: "Order Delivered", color: "#00CB60", bgcolor: "#00CB6026" },
    6: { text: "Mark Undelivered", color: "#FF725E", bgcolor: "#FF725E33" },
    7: { text: "Assigned", color: "#00CB60", bgcolor: "#00CB6026" },
    8: { text: "RTO Reached Hub", color: "#FF725E", bgcolor: "#FF725E33" },
    9: { text: "Order Undelivered", color: "#FF725E", bgcolor: "#FF725E33" },
    10: { text: "Back to Hub", color: "#FF725E", bgcolor: "#FF725E33" },
  };

  const getOrderStatusStyle = (statusText: string) => {
    for (const key in orderStatusColors) {
      if (orderStatusColors[key].text === statusText) {
        const { color, bgcolor } = orderStatusColors[key];
        return { color, backgroundColor: bgcolor };
      }
    }
    return { color: "", backgroundColor: "" };
  };
  // console.log(ordersData, "orders data:");

  return (
    <>
      {/* {loading ? (
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          ) : (
            <> */}
      <TableContainer className="">
        <div className="flex items-center justify-between py-4 px-3	border-slate-200 border-solid">
          <div className="flex">
            <Typography className="font-bold text-[#414141]">
              All Order and Broadcast details
            </Typography>
            <Tooltip title="Refresh">
              <RefreshOutlinedIcon
                sx={{ color: "#06B681", ml: 1, cursor: "pointer" }}
                onClick={() => {
                  setRefresh(!refresh);
                }}
              />
            </Tooltip>
          </div>
          <div className="flex">
            <div className="border-2 rounded-2xl px-4">
              <InputBase
                type="number"
                placeholder="Search by Order ID"
                className="flex-grow"
                value={searchVal}
                inputProps={{ "aria-label": "search" }}
                onChange={(search) => setSearchVal(search.target.value)}
              />
              <SearchIcon
                style={{ color: "#727597" }}
                className=" cursor-pointer ml-4"
              />
            </div>
            <CSVLink
              data={ordersCSVData || []}
              headers={header}
              filename={`All_orders_and_broadcast_data.csv`}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  bgcolor: "#06B681",
                  px: 2,
                  ml: 2,
                  "&:hover": { bgcolor: "#06B681" },
                  borderRadius: "50px",
                  fontSize: "14px",
                }}
              >
                Export
              </Button>
            </CSVLink>
          </div>
        </div>
        <hr />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Order ID
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Order Date & Time
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Rider ID
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Rider Name
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Rider Mobile No
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                City
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Primary Client
              </TableCell>

              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Status
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersData.map((row: any, index: any) => (
              <React.Fragment key={index}>
                <TableRow
                  key={index}
                  className={
                    expandedRows.includes(row.orderNumber) ? "expanded-row" : ""
                  }
                >
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.orderNumber}
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.orderCreationTime}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.riderId}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.riderName === "nan" ? (
                      <span>--</span>
                    ) : (
                      <span>{row.riderName}</span>
                    )}{" "}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.mobile === "nan" ? (
                      <span>--</span>
                    ) : (
                      <span>{row.mobile}</span>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.city}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.merchantName === "nan" ? (
                      <Chip
                        label="None"
                        size="small"
                        style={{
                          backgroundColor: "#E9E9E966",
                          color: "#414141",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      />
                    ) : (
                      <Chip
                        label={`${row.merchantName}`}
                        size="small"
                        style={{
                          backgroundColor: "#E9E9E966",
                          color: "#414141",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <Chip
                      label={row.orderStatus}
                      size="small"
                      style={{
                        ...getOrderStatusStyle(row.orderStatus),
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <button
                      onClick={() =>
                        handleExpandToggle(row.orderNumber, row.riderId)
                      }
                    >
                      {expandedRows.includes(
                        `${row.orderNumber}-${row.riderId}`
                      ) ? (
                        <img
                          src="/assets/icons/dropupIcon.svg"
                          alt=""
                          className="mt-2"
                        />
                      ) : (
                        <img
                          src="/assets/icons/dropdownIcon.svg"
                          alt=""
                          className="mt-2"
                        />
                      )}
                    </button>
                  </TableCell>
                </TableRow>
                {expandedRows.includes(`${row.orderNumber}-${row.riderId}`) && (
                  <TableRow className="expanded-content  animate__slideInDown">
                    <TableCell colSpan={8}>
                      <div className="w-full">
                        <AllOrderAndBroadcast extraDetails={row} />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pt-4 pb-2 pr-5">
        <div
          className="pagination"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="flex">
            <img
              src="/assets/icons/leftArrow.svg"
              alt=""
              onClick={() => handlePageChange(page - 1)}
            />
            {renderPageNumbers()}
            <img
              src="/assets/icons/rightArrow.svg"
              alt=""
              onClick={() => handlePageChange(page + 1)}
              className="ml-1 mr-6"
            />
          </div>
        </div>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
          No data found
        </Alert>
      </Snackbar>
      {/* </>
          )} */}
    </>
  );
}
