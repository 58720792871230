import React, { useEffect, useState } from "react";
import TableImplementation from "../../Layouts/Table";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import DashboardCard from "../../Layouts/DashboardCard";
import Header from "../../Layouts/AppHeader/Header";
import {
  Typography,
  Breadcrumbs,
  Button,
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
  Menu,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router";
import { dashBoardFrontCardResponse } from "../../../@Types/DRO/HomePageResponse";
import { OrderOnDemandApi } from "../../../API/ZyppIt";
import DatePicker from "react-datepicker";
import { Logout } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

function OrderManagement() {
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("search");
  // const navigate = useNavigate();
  // const handleCreateOrder = () => {
  //   navigate("/create-orders");
  // };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const [defaultStartDate, defaultEndDate] = getDefaultDateRange();
  const [dateRange, setDateRange] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);
  function getDefaultDateRange() {
    const today = new Date();
    const lastWeekStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const lastWeekEnd = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    return [lastWeekStart, lastWeekEnd];
  }
  const [dashboardData, setDashboardData] = useState<
    dashBoardFrontCardResponse["data"] | null
  >(null);
  const formatDate = (date: Date | undefined) => {
    if (!date) return undefined;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setLoading(true);
      const api = new OrderOnDemandApi();
      api
        .dashboardFrontCardDetails(
          formatDate(dateRange[0]),
          formatDate(dateRange[1])
        )
        .then((response) => {
          setDashboardData(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch dashboard data", error);
          setLoading(false);
        });
    }
  }, [dateRange]);
  // if (!dashboardData) {
  //   return <div>Loading...</div>;
  // }
  function formatGMV(amount: any) {
    if (amount == null) {
      return "N/A"; // Handle null or undefined values
    }
    if (amount >= 10000000) {
      return `${(amount / 10000000).toFixed(1)} Cr`; // Crores
    } else if (amount >= 100000) {
      return `${(amount / 100000).toFixed(1)} Lakhs`; // Lakhs
    } else if (amount >= 1000) {
      return `${(amount / 1000).toFixed(1)}k`; // Thousands
    } else {
      return amount.toString(); // Less than thousand
    }
  }
  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType("navigation");
    const navigationType = navigationEntries[0] as PerformanceNavigationTiming;

    if (
      navigationType?.type === "reload" ||
      window.performance.navigation.type === 1
    ) {
      if (orderId) {
        setSearchParams({});
      }
    }
  }, [orderId, setSearchParams]);
  return (
    <>
      <div className="flex">
        <div className="w-1/6 mr-3 ml-4">
          <SideBarNavigation />
        </div>
        <div className="w-5/6">
          <div className="pb-4 pr-4 pl-2 mt-6">
            <AppBar
              position="static"
              style={{
                background: "#ffffff",
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Toolbar
                className="justify-between"
                sx={{ "&&": { padding: 0, minHeight: 0 } }}
              >
                <div className="flex items-center">
                  {/* <div className="flex flex-col items-center">
            {merchantLogoUrl && merchantLogoUrl !== "" ? (
              <div>
                <img src={merchantLogoUrl} alt="Logo" className="h-20 w-20" />
              </div>
            ) : (
              <div>
                {" "}
                <img
                  src="/assets/icons/zyppItLogo.png"
                  alt="zyppIt Logo"
                />{" "}
              </div>
            )}
          </div> */}
                </div>
                <div className="flex items-center">
                  <Typography className="text-black pr-1">
                    Date Range:
                  </Typography>
                  <div className="border-[2.5px] rounded-md">
                    <div className="">
                      <DatePicker
                        selectsRange={true}
                        className="text-black text-sm p-1"
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        maxDate={new Date()}
                        dateFormat="yyyy/MM/dd"
                        onChange={(update: any) => {
                          setDateRange(update);
                        }}
                      />
                    </div>
                  </div>
                  <Tooltip title="Logout">
                    <IconButton
                      onClick={handleLogout}
                      size="small"
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Logout color="success" fontSize="large" />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                  ></Menu>
                </div>
              </Toolbar>
            </AppBar>{" "}
            <div className="flex justify-between mt-8">
              <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-inter">
                Order Management
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  sx={{ mt: 4 }}
                >
                  <Typography
                    color="#ACACAC"
                    sx={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    Dashboard
                  </Typography>
                  <Typography
                    color="#717171"
                    sx={{ fontSize: "12px", fontWeight: "700" }}
                  >
                    Order Management
                  </Typography>
                </Breadcrumbs>
              </div>
              <div className="mt-1">
                {/* <Button
                  className="mb-2"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    px: 2,
                    mb: 2,
                    bgcolor: "#0079FF",
                    "&:hover": { bgcolor: "#0077F" },
                    fontSize: "18px",
                    borderRadius: "30px",
                  }}
                  onClick={handleCreateOrder}
                >
                  <img
                    src="/assets/icons/whitePlusIcon.svg"
                    alt=""
                    className="mr-2"
                  />
                  Create Order
                </Button> */}
              </div>
            </div>
          </div>
          <div className="flex mb-1 justify-between pr-5">
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Placed Orders"
                backgroundColor="#21316F33"
                data={dashboardData?.totalPlacedOrders.toString() || "--"}
                comparison="50"
                imgSrc="/assets/icons/greyBagIcon.svg"
                increment={false}
                loading={loading}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Delivered Orders"
                backgroundColor="#00CB6033"
                data={dashboardData?.totalDeliveredOrders.toString() || "--"}
                comparison="50"
                imgSrc="/assets/icons/bagIcon.svg"
                increment={true}
                loading={loading}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Cancelled Orders"
                backgroundColor="#F9493233"
                data={dashboardData?.totalCancelledOrders.toString() || "--"}
                comparison="50"
                imgSrc="/assets/icons/redBagIcon.svg"
                increment={false}
                loading={loading}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Returned Orders"
                backgroundColor="#171F2133"
                data={dashboardData?.totalReturnedOrders.toString() || "--"}
                comparison="50"
                imgSrc="/assets/icons/blackBagIcon.svg"
                increment={true}
                loading={loading}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Pending Orders"
                backgroundColor="#FFB84633"
                data={dashboardData?.totalPendingOrders.toString() || "--"}
                comparison="50"
                imgSrc="/assets/icons/yellowBagIcon.svg"
                increment={false}
                loading={loading}
              />
            </div>
          </div>
          <div className="flex mb-3 justify-center pr-5">
            <div className="flex w-5/6">
              <div className="w-full mr-2">
                <DashboardCard
                  heading="Total Fulfilled %"
                  backgroundColor="#8B97FF33"
                  data={`${
                    dashboardData?.fulfillmentPercentage !== undefined
                      ? dashboardData?.fulfillmentPercentage + " %"
                      : "--"
                  }`}
                  comparison="50"
                  imgSrc="/assets/icons/lavenderBagIcon.svg"
                  increment={false}
                  loading={loading}
                />
              </div>
              <div className="w-full mr-2">
                <DashboardCard
                  heading="Cancelled After Assignment"
                  backgroundColor="#FF950033"
                  data={
                    dashboardData?.totalcancelledOrderAfterAssignment.toString() ||
                    "--"
                  }
                  comparison="50"
                  imgSrc="/assets/icons/cancelledAfterAssignment.svg"
                  increment={false}
                  loading={loading}
                />
              </div>
              <div className="w-full mr-2">
                <DashboardCard
                  heading="Order Cancelled in 60s"
                  backgroundColor="#D02A0033"
                  data={
                    dashboardData?.totalcancelledOrderWithin60Seconds.toString() ||
                    "--"
                  }
                  comparison="50"
                  imgSrc="/assets/icons/orderCancelledIcon.svg"
                  increment={false}
                  loading={loading}
                />
              </div>
              <div className="w-full mr-2">
                <DashboardCard
                  heading="Total GMV"
                  backgroundColor="#00875E33"
                  data={`${
                    formatGMV(dashboardData?.gmv) !== "N/A"
                      ? "₹ " + formatGMV(dashboardData?.gmv)
                      : "--"
                  }`}
                  comparison="50"
                  imgSrc="/assets/icons/gmvIcon.svg"
                  increment={false}
                  loading={loading}
                />
              </div>
            </div>
          </div>
          <div
            className="ml-2 w-[98%]"
            style={{
              boxShadow: "0px 7px 29px 0px #64646F33",
              borderRadius: "8px",
            }}
          >
            <TableImplementation
              startDate={formatDate(dateRange[0])}
              endDate={formatDate(dateRange[1])}
              navigateSearch={orderId}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderManagement;
