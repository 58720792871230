import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  Tooltip,
} from "@mui/material";
import "./TableCss.css";
import "animate.css/animate.min.css";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import { OrderSummaryApi } from "../../../API/OrderSummaries";
import { CSVLink } from "react-csv";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

interface OngoingOrdersTableProps {
  selectedLocality: string[];
  startDate: string | undefined;
  endDate: string | undefined;
  selectedMerchant: number;
}

export default function OrderTimeStampTable({
  selectedLocality,
  startDate,
  endDate,
  selectedMerchant,
}: OngoingOrdersTableProps) {
  const header = [
    { label: "Order Number", key: "order_number" },
    { label: "Order Reference Number", key: "order_reference_number" },
    { label: "Final Order Status", key: "final_order_status" },
    { label: "Final Broadcast Status", key: "final_broadcast_status" },
    { label: "Order UserId", key: "order_user_id" },
    { label: "Order Creation Time", key: "order_creation_time" },
    { label: "Broadcast Created Time", key: "broadcast_created_time" },
    { label: "Assigned to Rider", key: "assigned_to_rider" },
    { label: "Going for Pickup", key: "going_for_pickup" },
    { label: "Reached Pickup Location", key: "reached_pickup_location" },
    { label: "Order Picked", key: "order_picked" },
    { label: "Reached Gate", key: "reached_gate" },
    { label: "Order Delivered", key: "order_delivered" },
    { label: "Mark Undelivered", key: "mark_undelivered" },
    { label: "Reassign", key: "reassigned" },
    { label: "Reassign Request", key: "reassign_request" },
    { label: "Rejected", key: "rejected" },
    { label: "Unassign Request", key: "unassign_request" },
    { label: "Cancelled by Source", key: "cancelled_by_source" },
    { label: "Cancelled by Rider", key: "cancelled_by_rider" },
    { label: "Cancelled by System", key: "cancelled_by_system" },
    { label: "Back to Hub", key: "back_to_hub" },
    { label: "RTO Reached Hub", key: "rto_reached_hub" },
    { label: "Order Undelivered", key: "order_undelivered" },
  ];

  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);

  const [orderTimeStampData, setOrderTimeStampData] = useState<any>([]);
  const [orderTimeStampCSVData, setOrderTimeStampsCSVData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [refresh, setRefresh] = useState(true);

  const pageRange = 5;

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${searchVal ? `?order_id=${searchVal}` : ""}`;
    if (endDate) {
      OrderSummaryApi.api
        .orderTimeStampList(
          selectedMerchant,
          startDate,
          endDate,
          selectedLocality,
          page + 1,
          apiUrl
        )
        .then((e) => {
          setLoading(false);
          if (e.bifurcations.total_rows > 0) {
            setOrderTimeStampData(e.bifurcations.data);
            setTotalCount(e.bifurcations.total_rows);
          } else {
            setShowSnackbar(true);
            setOrderTimeStampData([]);
            setTotalCount(e.bifurcations.total_rows);
          }
        })
        .catch((error) => {
          setLoading(false);
          setShowSnackbar(true);
          console.error("API call error: ", error);
        });
      const daysParams =
        selectedLocality && selectedLocality.length > 0
          ? selectedLocality.map((loc) => `locality_id=${loc}`).join("&")
          : "";
      const apiUrl2 = `https://data.bcykal.com/tracker/ondemand/order/timestamp/details?start_date=${startDate}&end_date=${endDate}&merchant_id=${selectedMerchant}&${daysParams}`;

      fetch(apiUrl2)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // setLoading(false);
          if (data.bifurcations.total_rows > 0) {
            setOrderTimeStampsCSVData(data.bifurcations.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching ongoing orders: ", error);
        });
    }
  }, [page, searchVal, refresh, selectedLocality, endDate, selectedMerchant]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
    setPage(newPageNumber);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  return (
    <>
      {/* {loading ? (
        <LinearProgress
          sx={{
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#00CD5B",
            },
          }}
        />
      ) : ( */}
      <>
        <TableContainer className="">
          <div className="flex items-center justify-between py-4 px-3 border-slate-200 border-solid">
            <div className="flex">
              <Typography className="font-bold text-[#414141]">
                Order Timestamp Report{" "}
              </Typography>
              <Tooltip title="Refresh">
                <RefreshOutlinedIcon
                  sx={{ color: "#06B681", ml: 1, cursor: "pointer" }}
                  onClick={() => {
                    setRefresh(!refresh);
                  }}
                />
              </Tooltip>
            </div>
            <div className="flex">
              <div className="border-2 rounded-2xl px-4">
                <InputBase
                  type="number"
                  placeholder="Search by Order ID"
                  className="flex-grow"
                  inputProps={{ "aria-label": "search" }}
                  value={searchVal}
                  onChange={(search) => setSearchVal(search.target.value)}
                />
                <SearchIcon
                  style={{ color: "#727597" }}
                  className=" cursor-pointer ml-4"
                />
              </div>
              <CSVLink
                data={orderTimeStampCSVData || []}
                headers={header}
                filename={`Order_timestamp_data.csv`}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    bgcolor: "#06B681",
                    px: 2,
                    ml: 2,
                    "&:hover": { bgcolor: "#06B681" },
                    borderRadius: "50px",
                    fontSize: "14px",
                  }}
                >
                  Export
                </Button>
              </CSVLink>
            </div>
          </div>
          <hr />
          <div style={{ overflow: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Order Number
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Order Reference Number
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Final Order Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Final Broadcast Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Order UserId
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Order Creation Time
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Broadcast Created Time
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Assigned to Rider
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Going for Pickup
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Reached Pickup Location
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Order Picked
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Reached Gate
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Order Delivered
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Mark Undelivered
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Reassign
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Reassign Request
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Rejected
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Unassign Request
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Cancelled by Source
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Cancelled by Rider
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Cancelled by System
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Back to Hub
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    RTO Reached Hub
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Order Undelivered
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderTimeStampData.map((row: any, index: any) => (
                  <React.Fragment key={index}>
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.order_number}
                      </TableCell>

                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.order_reference_number}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.final_order_status}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.final_broadcast_status}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.order_user_id}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.order_creation_time}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.broadcast_created_time}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.assigned_to_rider ? (
                          <span>{row.assigned_to_rider}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.going_for_pickup ? (
                          <span>{row.going_for_pickup}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.reached_pickup_location ? (
                          <span>{row.reached_pickup_location}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.order_picked ? (
                          <span>{row.order_picked}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.reached_gate ? (
                          <span>{row.reached_gate}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.order_delivered ? (
                          <span>{row.order_delivered}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.mark_undelivered ? (
                          <span>{row.mark_undelivered}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.reassigned ? (
                          <span>{row.reassigned}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.reassign_request ? (
                          <span>{row.reassign_request}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.rejected ? (
                          <span>{row.rejected}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.unassign_request ? (
                          <span>{row.unassign_request}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.cancelled_by_source ? (
                          <span>{row.cancelled_by_source}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.cancelled_by_rider ? (
                          <span>{row.cancelled_by_rider}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.cancelled_by_system ? (
                          <span>{row.cancelled_by_system}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.back_to_hub ? (
                          <span>{row.back_to_hub}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.rto_reached_hub ? (
                          <span>{row.rto_reached_hub}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {row.order_undelivered ? (
                          <span>{row.order_undelivered}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
        <div className="pt-4 pb-2 pr-5">
          <div
            className="pagination"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="flex">
              <img
                src="/assets/icons/leftArrow.svg"
                alt=""
                onClick={() => handlePageChange(page - 1)}
              />
              {renderPageNumbers()}
              <img
                src="/assets/icons/rightArrow.svg"
                alt=""
                onClick={() => handlePageChange(page + 1)}
                className="ml-1 mr-6"
              />
            </div>
          </div>
        </div>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
      {/* )} */}
    </>
  );
}
