import React, { useState } from "react";
import {
  TextField,
  FormControl,
  Typography,
  Snackbar,
  Alert,
  Tooltip,
  IconButton,
} from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { OrderStatusApi } from "../../../API/OrderStatus";
import { OrderListApi } from "../../../API/FrontPageTable";
import { useNavigate } from "react-router-dom";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import CallDriverModal from "../../Modals/CallDriverModal";

interface OrderExtraDetailsProps {
  orderId: number;
  orderCreationTime: string;
  riderUserId: number;
  riderName: string;
  city: string;
  merchantName: string;
  orderStatus: number;
  assignedTime: string;
  reachTime: string;
  deliveryTime: string;
  endTime: string;
  orderType: string | null;
  expectedDistance: string;
  endInterval: string;
  expectedTime: string;
  weightLimit: string;
  actualWeight: string;
  state: string;
  pincode: string;
  riderMobile: string;
  riderAmount: number;
  orderAmount: number;
  destinationAddress: string;
  sourceAddress: string;
  paymentMode: string;
  customerName: string;
  customerMobile: string;
  orderReferenceNumber: string;
  orderCode: string;
  sourceLat: number;
  sourceLong: number;
  destinationLat: number;
  destinationLong: number;
  agentId: number;
  agentName: string;
}

export default function OrderExtraDetails(props: OrderExtraDetailsProps) {
  const {
    orderId,
    assignedTime,
    reachTime,
    deliveryTime,
    orderStatus,
    endTime,
    actualWeight,
    expectedDistance,
    endInterval,
    expectedTime,
    orderAmount,
    paymentMode,
    riderName,
    riderMobile,
    city,
    state,
    pincode,
    destinationAddress,
    destinationLong,
    destinationLat,
    sourceAddress,
    sourceLong,
    sourceLat,
    customerMobile,
    customerName,
    orderReferenceNumber,
    orderCode,
    orderType,
    weightLimit,
    riderAmount,
    agentId,
    agentName,
    riderUserId,
  } = props;
  const navigate = useNavigate();

  const extractTime = (datetime: string) => {
    const timePart = datetime?.split(" ")[1];
    return timePart;
  };
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    navigate(`/order-management?search=${orderId}`);
  };
  const handleTagAgent = () => {
    OrderListApi.api
      .tagAgent(orderId)
      .then((response) => {
        console.log(response);
        setShowSuccessSnackbar(true);
        setSnackbarMessage("Order has been tagged to you");
      })
      .catch((error) => {
        console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Failed to tag order";
        setShowErrorSnackbar(true);
        setSnackbarMessage(errorMessage);
      });
  };
  return (
    <>
      <div className="flex">
        <div>
          <FormControl>
            <TextField
              label="Assigned Time"
              id="standard-size-small"
              defaultValue={extractTime(assignedTime) || "0"}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-2">
          <FormControl>
            <TextField
              label="Reach Time"
              id="standard-size-small"
              defaultValue={extractTime(reachTime) || 0}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-2">
          <FormControl>
            <TextField
              label="Delivery Time"
              id="standard-size-small"
              defaultValue={extractTime(deliveryTime) || "0"}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-2">
          <FormControl>
            <TextField
              label="End Time"
              id="standard-size-small"
              defaultValue={extractTime(endTime) || "0"}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
              variant="standard"
            />
          </FormControl>
        </div>

        <div className="ml-2">
          <FormControl>
            <TextField
              label="Order Type"
              id="standard-size-small"
              defaultValue={orderType || "zypp"}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
      </div>

      <div className="flex mt-8">
        <div>
          <FormControl>
            <TextField
              id="standard-number"
              label="Expected Distance"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={expectedDistance || "0"}
              disabled={true}
            />
          </FormControl>
        </div>

        <div className="ml-2">
          <FormControl>
            <TextField
              id="standard-number"
              label="End Interval (min)"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={endInterval || "0"}
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-2">
          <FormControl>
            <TextField
              label="Expected Time"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={expectedTime || "0"}
              disabled={true}
            />
          </FormControl>
        </div>

        <div className="ml-2">
          <FormControl>
            <TextField
              id="standard-number"
              label="Weight Limit"
              type="number"
              defaultValue={weightLimit || "10"}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-2">
          <FormControl>
            <TextField
              id="standard-number"
              label="Actual Weight"
              type="number"
              defaultValue={actualWeight || "0"}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={true}
            />
          </FormControl>
        </div>
      </div>

      <div className="flex mt-8">
        <div className="">
          <FormControl>
            <TextField
              label="Rider Name"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={riderName || "zypp"}
              disabled={true}
              multiline
            />
          </FormControl>
        </div>

        <div className="ml-2">
          <FormControl>
            <TextField
              id="standard-number"
              label="Rider Mobile"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={riderMobile || "0"}
              disabled={true}
            />
          </FormControl>
        </div>

        <div className="ml-2">
          <FormControl>
            <TextField
              id="standard-number"
              label="Pincode"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={pincode || "0"}
              disabled={true}
            />
          </FormControl>
        </div>

        <div className="ml-2">
          <FormControl>
            <TextField
              id="standard-number"
              label="Rider Amount"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={riderAmount || "0"}
              disabled={true}
            />
          </FormControl>
        </div>

        <div className="ml-2">
          <FormControl>
            <TextField
              id="standard-number"
              label="Order Amount"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={orderAmount || "0"}
              disabled={true}
            />
          </FormControl>
        </div>

        <div className="ml-2">
          {/* <FormControl>
            <TextField
              label="Client Type"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={customerName || "client"} //to be changed after confirmation
            />
          </FormControl> */}
        </div>
      </div>

      <div className="flex mt-8">
        <div className="">
          <FormControl fullWidth>
            <TextField
              label="Destination"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={destinationAddress || "zypp"}
              disabled={true}
              multiline
            />
          </FormControl>
        </div>

        <div className="ml-6">
          <FormControl fullWidth>
            <TextField
              label="Source"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={sourceAddress || "gurgaon"}
              disabled={true}
              multiline
            />
          </FormControl>
        </div>

        {/* <div className="ml-2">
          <FormControl>
            <TextField
              label="City"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={city || "gurgaon"}
            />
          </FormControl>
        </div> */}
        <div className="ml-10">
          <FormControl>
            <TextField
              label="Payment Method"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={paymentMode || "payment"}
              disabled={true}
            />
          </FormControl>
        </div>
        <div className="ml-8">
          {orderStatus !== -1 &&
          orderStatus !== -2 &&
          orderStatus !== -3 &&
          orderStatus !== 6 &&
          orderStatus !== 10 ? (
            <>
              {agentId && agentName ? (
                <>
                  <div>
                    <Typography className="text-[#171F21] text-sm font-medium font-inter">
                      Agent
                    </Typography>
                  </div>
                  <Tooltip title={`Agent ID: ${agentId}`} arrow>
                    <div
                      className="bg-[#fff] border-2 border-[#171F21] mt-1 px-4 py-2 rounded-3xl flex items-center justify-center cursor-pointer"
                      style={{ boxShadow: "0px 7px 29px 0px #64646F33" }}
                    >
                      <span className="text-[#171F21] text-sm font-medium font-inter">
                        {agentName}
                      </span>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <div
                  className="bg-[#fff] border-2 border-[#171F21] px-4 py-2 rounded-3xl flex items-center justify-center cursor-pointer"
                  onClick={handleTagAgent}
                  style={{ boxShadow: "0px 7px 29px 0px #64646F33" }}
                >
                  <SupportAgentIcon
                    fontSize="small"
                    sx={{ color: "#171F21", mr: 1 }}
                  />
                  <span className="text-[#171F21] text-sm font-medium font-inter">
                    My Order
                  </span>
                </div>
              )}
            </>
          ) : (
            <>
              {agentId && agentName ? (
                <>
                  <div>
                    <Typography className="text-[#171F21] text-sm font-medium font-inter">
                      Agent
                    </Typography>
                  </div>
                  <Tooltip title={`Agent ID: ${agentId}`} arrow>
                    <div
                      className="bg-[#fff] border-2 border-[#171F21] px-4 mt-1 py-2 rounded-3xl flex items-center justify-center cursor-pointer"
                      style={{ boxShadow: "0px 7px 29px 0px #64646F33" }}
                    >
                      <span className="text-[#171F21] text-sm font-medium font-inter">
                        {agentName}
                      </span>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <div className="flex mt-8">
        <div className="">
          <FormControl>
            <TextField
              label="State"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={state || "haryana"}
              disabled={true}
            />
          </FormControl>
        </div>

        <div className="ml-2">
          <FormControl>
            <TextField
              label="Customer Name"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={customerName || "client"}
              disabled={true}
              multiline
            />
          </FormControl>
        </div>

        <div className="ml-2 flex items-end">
          <FormControl>
            <TextField
              label="Customer Mobile"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={customerMobile || "0"}
              disabled={true}
            />
          </FormControl>
          {orderStatus !== -1 &&
          orderStatus !== -2 &&
          orderStatus !== -3 &&
          orderStatus !== 6 &&
          orderStatus !== 10 ? (
            <>
              {customerMobile && (
                <CallDriverModal
                  orderNo={orderId}
                  contactType={1}
                  modalButton="orderManagementCust"
                  userId={null}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="ml-4">
          <FormControl>
            <TextField
              label="Order Code"
              id="standard-size-small"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              defaultValue={orderCode || "0"}
              disabled={true}
            />
          </FormControl>
        </div>
      </div>
    </>
  );
}
