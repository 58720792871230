import React, { useEffect, useState } from "react";
import TableImplementation from "../../Components/Layouts/Table";
import SideBarNavigation from "../../Components/Layouts/Sidebar/Sidebar";
import DashboardCard from "../../Components/Layouts/DashboardCard";
import Header from "../../Components/Layouts/AppHeader/Header";
import AllZyppRidersCard from "../../Components/Layouts/AllZyppRidersCard";
import HomeTrackOrders from "../../Components/Layouts/HomeTrackOrders";
import { Breadcrumbs, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OrderOnDemandApi } from "../../API/ZyppIt";
import { HomeDashboardInfo } from "../../@Types/ComponentsInterface/HomePageResponseProps";

function Home() {
  const navigate = useNavigate();
  const handleCreateOrder = () => {
    navigate("/create-orders");
  };

  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [dashboardInfo, setDashBoardInfo] = useState<HomeDashboardInfo>();
  const [totalRiders, setTotalRiders] = useState<number | undefined>();
  const [totalAvailableRiders, setTotalAvailableRiders] = useState<
    number | undefined
  >();
  const [totalOrderDelivered, setTotalOrderDelivered] = useState<
    number | undefined
  >();
  const [totalAmountCollected, setTotalAmountCollected] = useState<
    number | undefined
  >();
  const [totalPendingOrders, setTotalPendingOrders] = useState<
    number | undefined
  >();
  const [totalCancelledOrders, setTotalCancelledOrders] = useState<
    number | undefined
  >();
  const [totalReturnedOrders, setTotalReturnedOrders] = useState<
    number | undefined
  >();
  const [totalCount, setTotalCount] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  let page = 1;

  // useEffect(() => {
  //   setLoading(true);
  //   OrderOnDemandApi.api
  //     ?.homePageApi(page)
  //     .then((e) => {
  //       setLoading(false);
  //       if (e.data) {
  //         setDashBoardInfo(e.data.dashboardInfo);
  //       } else {
  //         setShowSnackbar(true);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.error("API call error: ", error);
  //     });
  // }, []);

  // setTotalAmountCollected(dashboardInfo?.totalAmountCollected);
  // setTotalPendingOrders(dashboardInfo?.totalPendingOrders);
  // setTotalAvailableRiders(dashboardInfo?.totalAvailableRiders);
  // setTotalRiders(dashboardInfo?.totalRider);
  // setTotalOrderDelivered(dashboardInfo?.totalOrdersDelivered);
  // setTotalCancelledOrders(dashboardInfo?.totalCancelledOrders);
  // setTotalReturnedOrders(dashboardInfo?.totalReturnedOrders);
  return (
    <>
      <div className="flex">
        <div className="w-1/6 mr-3">
          <SideBarNavigation />
        </div>
        <div className="w-5/6">
          <div className="pb-4 pl-2 pr-4 mt-6">
            <Header merchantLogoUrl="" />
            <div className="flex justify-between mt-8">
              <div className="font-medium	text-[32px] text-[#1E1E1E] text-left font-inter">
                Dashboard
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    color="#717171"
                    sx={{ fontSize: "12px", fontWeight: "700", mt: 4 }}
                  >
                    Dashboard
                  </Typography>
                </Breadcrumbs>
              </div>
              <div className="">
                <Button
                  className="mb-2"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    px: 2,
                    mb: 2,
                    bgcolor: "#0079FF",
                    "&:hover": { bgcolor: "#0077F" },
                    fontSize: "18px",
                    borderRadius: "30px",
                  }}
                  onClick={handleCreateOrder}
                >
                  <img
                    src="/assets/icons/whitePlusIcon.svg"
                    alt=""
                    className="mr-2"
                  />
                  Create Order
                </Button>
              </div>
            </div>
          </div>
          <div className="flex mb-1 justify-between pr-5">
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Zypp Riders"
                backgroundColor="#00CB6033"
                data={1000}
                comparison="50"
                imgSrc="/assets/icons/greenRiderIcon.svg"
                increment={true}
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Available Riders"
                backgroundColor="#8B97FF33"
                data={1000}
                comparison="50"
                imgSrc="/assets/icons/blueRiderIcon.svg"
                increment={false}
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Orders Delivered"
                backgroundColor="#0079FF33"
                data={1000}
                comparison="50"
                imgSrc="/assets/icons/blueBagIcon.svg"
                increment={false}
                loading={false}
              />
            </div>
            <div className="w-full">
              <DashboardCard
                heading="Total Amount Collected"
                backgroundColor="#FFB84633"
                data={1000}
                comparison="50"
                imgSrc="/assets/icons/yellowWalletIcon.svg"
                increment={true}
                loading={false}
              />
            </div>
          </div>

          <div className="flex items-center justify-between ml-1">
            <div>
              <AllZyppRidersCard />
            </div>

            <div className="mr-2">
              <HomeTrackOrders />
            </div>
          </div>

          <div
            className="ml-2 mt-1 w-[98%]"
            style={{
              boxShadow: "0px 7px 29px 0px #64646F33",
              borderRadius: "8px",
            }}
          >
            {/* <TableImplementation /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
