import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Card,
  Chip,
  Button,
  Snackbar,
  Alert,
  Popover,
  TextField,
} from "@mui/material";
import { PolygonStressApi } from "../../../API/PolygonStress";

interface Locality {
  polygonId: number;
  city: string;
  locality: string;
  orderThreshold: number;
}

interface polygonLocations {
  cityCode: string;
  cityName: string;
  data: Locality[];
}

interface CityCardProps {
  polygonLocations: polygonLocations;
}

const PolygonStressCard: React.FC<CityCardProps> = ({ polygonLocations }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentLocality, setCurrentLocality] = useState<Locality | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showEqualValueSnackbar, setShowEqualValueSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");

  const handleChipClick = (
    event: React.MouseEvent<HTMLElement>,
    locality: Locality
  ) => {
    setCurrentLocality(locality);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCurrentLocality(null);
    setInputValue("");
  };

  const updateThreshold = () => {
    if (parseInt(inputValue) !== currentLocality?.orderThreshold) {
      PolygonStressApi.api
        .updatePolygonStress(
          currentLocality?.polygonId,
          currentLocality?.city,
          currentLocality?.locality,
          inputValue
        )
        .then((response) => {
          setShowSuccessSnackbar(true);
          setSnackbarMessage("Threshold Updated");
          handlePopoverClose();
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Failed";
          console.error(error);
          setShowErrorSnackbar(true);
          setSnackbarMessage(errorMessage);
          handlePopoverClose();
        });
    } else {
      setShowEqualValueSnackbar(true);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  const handleErrorSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorSnackbar(false);
  };
  const handleEqualSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowEqualValueSnackbar(false);
  };

  return (
    <>
      <div className="flex">
        <Card
          sx={{
            mb: 2,
            mr: 2,
            boxShadow: "0px 7px 29px 0px #64646F33",
            height: "100%",
            width: "100%",
          }}
          className={`rounded-lg border border-[#ECEEFF]`}
        >
          <div className="border-b flex w-full justify-between align-center">
            <div className="p-2 flex items-center">
              <span className="text-xl ml-2 font-bold text-[#414141]">
                {polygonLocations.cityName}
              </span>
            </div>
          </div>
          <div className="p-2">
            <>
              {polygonLocations.data?.map((locality) => (
                <Chip
                  key={locality.polygonId}
                  label={`${locality.locality} (${locality.orderThreshold})`}
                  clickable
                  onClick={(event) => handleChipClick(event, locality)}
                  sx={{
                    m: 1,
                    backgroundColor:
                      currentLocality?.polygonId === locality.polygonId
                        ? "#06B681"
                        : "#FFFFFF",
                    color:
                      currentLocality?.polygonId === locality.polygonId
                        ? "#FFFFFF"
                        : "#171F21",
                    border: `1px solid ${
                      currentLocality?.polygonId === locality.polygonId
                        ? "#06B681"
                        : "#171F21"
                    }`,
                  }}
                />
              ))}
            </>
          </div>
        </Card>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div style={{ padding: 10, width: "200px" }}>
            <div className="py-1">
              <span className="font-semibold [#414141]">
                {currentLocality?.locality} ({currentLocality?.orderThreshold})
              </span>
            </div>
            <span className="text-xs text-[#414141]">Enter Value</span>
            <TextField
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              margin="normal"
              size="small"
            />
            <Button
              variant="contained"
              onClick={updateThreshold}
              sx={{
                bgcolor: "#171F21",
                textTransform: "none",
                borderRadius: "50px",
                color: "white",
                "&:hover": { bgcolor: "#171F2180" },
                mt: 2,
                width: "100%",
              }}
            >
              Update
            </Button>
          </div>
        </Popover>
        <Snackbar
          open={showSuccessSnackbar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {SnackbarMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={showErrorSnackbar}
          autoHideDuration={2000}
          onClose={handleErrorSnackbarClose}
        >
          <Alert
            onClose={handleErrorSnackbarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {SnackbarMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={showEqualValueSnackbar}
          autoHideDuration={2000}
          onClose={handleEqualSnackbarClose}
        >
          <Alert
            onClose={handleEqualSnackbarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Old and new values are same
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default PolygonStressCard;
