import React, { useEffect, useState } from "react";
import { Paper, Box, Typography } from "@mui/material";
import { BreachedOrdersApi } from "../../../API/BreachedOrders";
const BreachedOrderCard: React.FC = () => {
  const [breachedOrderCardDetails, setBreachedOrderCardDetails] = useState<any>(
    []
  );
  useEffect(() => {
    // setLoading(true);
    BreachedOrdersApi.api
      .breachedOrdersHeaders()
      .then((e) => {
        setBreachedOrderCardDetails(e.data.data);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, []);
  return (
    <>
      <Box
        sx={{
          "& > :not(style)": {
            m: 1,
            width: "100%",
            height: "100%",
          },
        }}
      >
        <Paper
          elevation={6}
          sx={{
            boxShadow: "0px 7px 29px 0px #64646F33",
            borderRadius: "8px",
            p: 1,
          }}
        >
          <div className="flex items-start">
            <div className="mt-2 ml-3 mb-2">
              <svg
                width="5"
                height="100%"
                viewBox="0 0 7 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L1.99999 140"
                  stroke="#FFB846"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="w-1/5 p-1 py-2">
              <div className="flex justify-between items-start pt-1 px-2">
                {/* <div className="border-2 border-[#00CB60] h-11/12"></div> */}
                <p className="text-[#727597] font-medium font-inter">
                  Total Breached Orders
                </p>
              </div>
              <p className="px-2 mt-2 text-left font-inter text-4xl font-semibold text-[#1E1E1E]">
                {breachedOrderCardDetails?.totalBreachedOrders || 0}
              </p>
            </div>
            <img
              src="/assets/icons/coloured-line.svg"
              className="my-2"
              style={{ height: "105px" }}
            />
            <div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-0.5 mt-2 ml-5">
                <div className="flex items-center font-inter text-sm text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#8B97FF" />
                  </svg>{" "}
                  <span className="ml-2">Unassigned to Assigned</span>
                </div>
                <div className="flex items-center font-inter text-sm text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#0079FF" />
                  </svg>{" "}
                  <span className="ml-2">Pickup to Reached Hub</span>
                </div>
                <div className="flex items-center font-inter text-sm text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#41D0A5" />
                  </svg>{" "}
                  <span className="ml-2">Order Picked to Reached Gate</span>
                </div>
                <div className="flex items-center font-inter font-semibold text-xl text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#fff" />
                  </svg>{" "}
                  <span className="ml-2">
                    {breachedOrderCardDetails.unassignedBreachedOrders || 0}
                  </span>
                </div>
                <div className="flex items-center font-inter font-semibold text-xl text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#fff" />
                  </svg>{" "}
                  <span className="ml-2">
                    {breachedOrderCardDetails.gfpBreachedOrders || 0}
                  </span>
                </div>
                <div className="flex items-center font-inter font-semibold text-xl text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#fff" />
                  </svg>{" "}
                  <span className="ml-2">
                    {breachedOrderCardDetails.opBreachedOrders || 0}
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-x-3.5 gap-y-0.5 mt-2 ml-5">
                <div className="flex items-center font-inter text-sm text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#3C96FF" />
                  </svg>{" "}
                  <span className="ml-2">Assigned to Pickup</span>
                </div>
                <div className="flex items-center font-inter text-sm text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#B2FFE8" />
                  </svg>{" "}
                  <span className="ml-2">Reached Hub to Order Picked</span>
                </div>
                <div className="flex items-center font-inter text-sm text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#009367" />
                  </svg>{" "}
                  <span className="ml-2">Reached Gate to Order Delivered</span>
                </div>
                <div className="flex items-center font-inter font-semibold text-xl text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#fff" />
                  </svg>{" "}
                  <span className="ml-2">
                    {breachedOrderCardDetails.assignedBreachedOrders || 0}
                  </span>
                </div>
                <div className="flex items-center font-inter font-semibold text-xl text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#fff" />
                  </svg>{" "}
                  <span className="ml-2">
                    {breachedOrderCardDetails.rhBreachedOrders || 0}
                  </span>
                </div>
                <div className="flex items-center font-inter font-semibold text-xl text-[#171F21]">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#fff" />
                  </svg>{" "}
                  <span className="ml-2">
                    {breachedOrderCardDetails.rdBreachedOrders || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default BreachedOrderCard;
