import React, { useEffect, useState } from "react";
import {
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  InputBase,
} from "@mui/material";
import "./TableCss.css";
import "animate.css/animate.min.css";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import ActionModal from "../../Modals/NominationApproveModal";
import { RiderNominationApi } from "../../../API/RiderNomination";
import { NominationList } from "../../../@Types/DRO/NominationListResponse";

export default function RiderNominationTable() {
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);

  const [nominationListData, setNomiantionListData] = useState<
    NominationList[]
  >([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageRange = 5;
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
    RiderNominationApi.api
      ?.nominationListApi(page + 1, apiUrl)
      .then((e) => {
        setLoading(false);
        if (Array.isArray(e.data) && e.data.length > 0) {
          setNomiantionListData(e.data);
          // console.log("listData", nominationListData);
        } else {
          setShowSnackbar(true);
          setNomiantionListData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, [page, searchVal]);

  // console.log("listData1", nominationListData);

  const pageCount = 5;

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
    setPage(newPageNumber);
  };

  const renderPageNumbers = () => {
    const pagesToShow = 5;
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
    setShowSuccessSnackbar(false);
  };

  const getNameByCityCode = (city: string) => {
    if (city === "DL") {
      return "Delhi";
    } else if (city === "Noida") {
      return "Noida";
    } else if (city === "GGN") {
      return "Gurugram";
    } else if (city === "BLR") {
      return "Bangalore";
    } else if (city === "HYD") {
      return "Hyderabad";
    } else if (city === "FDB") {
      return "Faridabad";
    } else if (city === "MUM") {
      return "Mumbai";
    }
  };

  function formatName(name: string) {
    const words = name?.split(" ");
    if (words?.length > 2) {
      return `${words[0]} ${words[words.length - 1]}`;
    } else {
      return name;
    }
  }

  function capitalizeFirstLetter(str: string): string {
    const words: string[] = str.split(" ");
    const capitalizedWords: string[] = words.map((word: string) => {
      return formatName(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    });
    return formatName(capitalizedWords.join(" "));
  }

  return (
    <>
      <div className="flex items-center justify-end py-4 px-3 border-b	border-slate-200	border-solid">
        {/* <div className="flex items-center w-1/6 border border-slate-500	border-solid	rounded-full px-2 py-1">
          <div>
            <Typography className="font-inter text-[#727597] text-xs mr-2 font-normal">Status: </Typography>
          </div>
          <FormControl fullWidth>
            <select>
              <option value="" selected>
                All
              </option>

              <option value="all">Resolved</option>
            </select>
          </FormControl>
        </div> */}
        <div className="border-2 rounded-2xl px-4">
          <InputBase
            placeholder="Search by ID, date or name"
            className="flex-grow"
            inputProps={{ "aria-label": "search" }}
            onChange={(search) => setSearchVal(search.target.value)}
          />
          <SearchIcon
            style={{ color: "#727597" }}
            className=" cursor-pointer ml-4"
          />
        </div>
      </div>
      {loading ? (
        <LinearProgress
          sx={{
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#0079FF",
            },
          }}
        />
      ) : (
        <TableContainer className="">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Rider ID
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Rider’s Name
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Rider’s Mobile
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  City
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Interest Category
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Action
                </TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nominationListData?.map((row) => (
                <TableRow key={row.nominationId}>
                  <TableCell>
                    <Checkbox style={{ color: "#E9E9E9" }} />
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.riderId}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {capitalizeFirstLetter(row.name)}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row.mobile}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={`${getNameByCityCode(row.city)}`}
                      size="small"
                      style={{
                        backgroundColor: "#8B97FF26",
                        color: "#8B97FF",
                        paddingInline: "3px",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={row.categories[0]}
                      size="small"
                      style={{
                        backgroundColor: "#FFB84626",
                        color: "#FFB846",
                        paddingInline: "16px",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    />
                    <Chip
                      label={row.categories[1]}
                      size="small"
                      style={{
                        backgroundColor: "#F9493226",
                        color: "#F94932",
                        paddingInline: "20px",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginLeft: "5px",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionModal rowData={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className="pt-4 pb-2 pr-5">
        <div
          className="pagination"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {/* <select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            style={{
              backgroundColor: "#EBEBEB",
              fontFamily: "Rubik",
              fontSize: "16px",
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select> */}
          <div className="flex">
            <img
              src="/assets/icons/leftArrow.svg"
              alt=""
              onClick={() => handlePageChange(page - 1)}
            />
            {renderPageNumbers()}
            <img
              src="/assets/icons/rightArrow.svg"
              alt=""
              onClick={() => handlePageChange(page + 1)}
              className="ml-1"
            />
          </div>
        </div>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert sx={{ width: "10" }} onClose={handleClose} severity="error">
          No data found
        </Alert>
      </Snackbar>
    </>
  );
}
