import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Chip,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RiderNominationApi } from "../../API/RiderNomination";
import ReturnToDashboardModal from "./ReturnToDashboardModal";
import { OrderStatusApi } from "../../API/OrderStatus";
import { BlacklistedRidersApi } from "../../API/BlacklistedRiders";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

export default function UnbanRidersModal({ row }: { row: any }) {
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = useState<string>("");

  // State to hold the status

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setComments("");
  };
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  const handleComments = (e: any) => {
    setComments(e.target.value);
  };

  const handleUnban = () => {
    BlacklistedRidersApi.api
      .unbanRiders(row.restrictType, row.userId, comments)
      .then((response) => {
        console.log(response);
        if (response.code === 2000) {
          setShowSuccessSnackbar(true);
          setSnackbarMessage("Rider Unbanned successfully");
          handleClose();
        } else {
          setSnackbarMessage(response.message);
          setShowErrorSnackbar(true);
        }
      })
      .catch((error) => {
        console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Failed to unban rider";
        setShowErrorSnackbar(true);
        setSnackbarMessage(errorMessage);
      });
  };

  return (
    <div>
      <div
        className="bg-[#171F21] px-4 py-2 rounded-3xl flex cursor-pointer justify-center"
        onClick={handleOpen}
      >
        <span className="text-[#FFFFFF] text-sm font-medium font-inter">
          Unban
        </span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-end mb-1">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="px-6">
              <div className="flex pb-4">
                <div className="w-1/2">
                  <TextField
                    label="Rider Name"
                    id="standard-size-small"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    sx={{ my: 2 }}
                    value={row.riderName}
                    disabled
                  />
                </div>
                <div className="w-1/2">
                  <TextField
                    label="User ID"
                    id="standard-size-small"
                    type="number"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    sx={{ my: 2 }}
                    value={row.userId}
                    disabled
                  />
                </div>
              </div>
              <div className="flex pb-4"></div>
              <div className="py-3">
                <TextField
                  label="Remarks"
                  id="standard-size-small"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                  fullWidth
                  sx={{ my: 2 }}
                  onChange={handleComments}
                  required
                />
              </div>
            </div>
            <div className="flex justify-center mt-6 items-center pb-4">
              <Button
                sx={{
                  textTransform: "none",
                  bgcolor: "#F94932",
                  py: 1,
                  px: 6,
                  color: "white",
                  borderRadius: "50px",
                  "&:hover": { bgcolor: "#F9493280" },
                }}
                onClick={handleClose}
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleUnban}
                sx={{
                  ml: 3,
                  color: "white",
                  bgcolor: "#171F21",
                  borderRadius: "50px",
                  textTransform: "none",
                  py: 1,
                  px: 6,
                  "&:hover": { bgcolor: "#171F2180" },
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
