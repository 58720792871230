import axios, { AxiosInstance, AxiosResponse } from "axios";
import { polygonStressResponseProps } from "../@Types/DRO/PolygonStressResponse";
export class PolygonStressApi {
  public static api = new PolygonStressApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://stag-ood.bcykal.com/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }

  polygonStressList(): Promise<AxiosResponse<polygonStressResponseProps>> {
    let apiUrl = "mobycy/on-demand/dashboard/v1/location/polygon";
    return this.instance.get<polygonStressResponseProps>(apiUrl, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  updatePolygonStress(
    polygonId: number | undefined,
    city: string | undefined,
    locality: string | undefined,
    newValue: string
  ): Promise<any> {
    const orderThreshold = parseInt(newValue);
    return this.instance
      .post<any>(
        `mobycy/on-demand/dashboard/v1/update/polygon/threshold`,
        {
          polygonId,
          city,
          locality,
          orderThreshold,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}
