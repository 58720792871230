import * as React from "react";
import { useState, useEffect } from "react";
import { Card, CircularProgress } from "@mui/material";
import {
  Timeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import Typography from "@mui/material/Typography";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import "./TrackOrderTimeline.css";

interface TimelineCardProps {
  data: {
    terminalFlag: number;
    events: {
      statusName: string;
      createdAt: string;
      riderId?: number;
      riderName?: string;
      latitude?: number;
      longitude?: number;
      createdByName?: string;
      createdBy?: number;
      remarks?: string;
    }[];
  };
}

const CustomTimelineConnector: React.FC<{ terminalFlag: number }> = ({
  terminalFlag,
}) => (
  <TimelineConnector
    sx={{
      bgcolor: terminalFlag === 0 ? "#ffb846" : "#f94932", // Yellow if terminalFlag is 0, Red otherwise
    }}
  />
);

const CustomTimelineDot: React.FC<{ terminalFlag: number }> = ({
  terminalFlag,
}) => (
  <div
    className={`custom-timeline-dot-${terminalFlag === 0 ? "yellow" : "red"}`}
  >
    <div className={`solid-dot-${terminalFlag === 0 ? "yellow" : "red"}`} />
  </div>
);

const TrackOrderTimeline: React.FC<TimelineCardProps> = ({ data }) => {
  const { events } = data;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the timeout duration as needed

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <Card
        sx={{
          minWidth: 270,
          elevation: 0,
          mb: 2,
          boxShadow: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 400,
        }}
        className="rounded-lg border border-[#ECEEFF]"
      >
        <CircularProgress sx={{ color: "#06B681" }} />
      </Card>
    );
  }

  return (
    <Card
      sx={{
        minWidth: 270,
        elevation: 0,
        mb: 2,
        boxShadow: "none",
        maxHeight: 400, // Adjust this value as needed
        overflowY: "auto",
      }}
      className="rounded-lg border border-[#ECEEFF]"
    >
      <Timeline
        sx={{
          pt: 1,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {events.map((event, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              {index === events.length - 1 ? (
                <CustomTimelineDot terminalFlag={data.terminalFlag} />
              ) : (
                <TimelineDot sx={{ bgcolor: "#06B681", margin: 0 }} />
              )}
              {index !== events.length - 1 &&
                (index === events.length - 2 ? (
                  <CustomTimelineConnector terminalFlag={data.terminalFlag} />
                ) : (
                  <TimelineConnector sx={{ bgcolor: "#06B681" }} />
                ))}
            </TimelineSeparator>
            <TimelineContent>
              <div className="">
                <div className="text-sm text-[#414141] font-semibold">
                  Status: {event.statusName}
                </div>
                <div className="text-xs text-[#06B681]">
                  Timestamp: {event.createdAt}
                </div>
                {event.riderId && (
                  <div className="text-xs text-[#575F6B]">
                    Rider: {event.riderName} ({event.riderId})
                  </div>
                )}

                {event.createdBy && (
                  <div className="text-xs text-[#575F6B]">
                    Created by: {event.createdByName} ({event.createdBy})
                  </div>
                )}
                {event.remarks && (
                  <div className="text-xs text-[#575F6B]">
                    Remarks: {event.remarks}
                  </div>
                )}
                {/* Additional event details can be displayed here if needed */}
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Card>
  );
};

export default TrackOrderTimeline;
