import React from "react";
import RiderNominationTable from "../../Layouts/RiderNominationTable/index";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import DashboardCard from "../../Layouts/DashboardCard";
import Header from "../../Layouts/AppHeader/Header";
import { Typography, Breadcrumbs, Button, TextField } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";

function RiderNomination() {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex pr-8 ml-4">
        <div className="w-1/6 mr-3">
          <SideBarNavigation />
        </div>

        <div className="w-5/6">
          <div className="pb-4 pl-2 pr-4 mt-6">
            <Header merchantLogoUrl="" />

            <div className="font-medium	text-[32px] text-[#1E1E1E] text-left font-inter mt-8">
              Rider Nomination
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mt: 4 }}
              >
                <Typography
                  color="#717171"
                  sx={{ fontSize: "12px", fontWeight: "700" }}
                >
                  Riders Nomination
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className="flex mb-1 justify-between pr-5">
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Zypp Riders"
                backgroundColor="#00CB6033"
                data={1000}
                comparison="50"
                imgSrc="/assets/icons/greenRiderIcon.svg"
                increment={true}
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Available Riders"
                backgroundColor="#171F2133"
                data={1000}
                comparison="5"
                imgSrc="/assets/icons/blackRiderIcon.svg"
                increment={false}
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Pending Riders"
                backgroundColor="#FFB84633"
                data={1000}
                comparison="50"
                imgSrc="/assets/icons/yellowRiderIcon.svg"
                increment={true}
                loading={false}
              />
            </div>
            <div className="w-full">
              <DashboardCard
                heading="Total Not Servicable"
                backgroundColor="#F9493233"
                data={1000}
                comparison="5"
                imgSrc="/assets/icons/redRiderIcon.svg"
                increment={false}
                loading={false}
              />
            </div>
          </div>

          <div
            className="ml-2 w-[98%] mb-4"
            style={{
              boxShadow: "0px 7px 29px 0px #64646F33",
              borderRadius: "8px",
            }}
          >
            <RiderNominationTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default RiderNomination;
