import React, { useCallback, useState, useRef, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";

interface UploadFilesProps {
  width: string;
  onFileDrop: (file: File | null) => void;
  disableUpload: boolean;
}

const UploadFiles: React.FC<UploadFilesProps> = ({
  width,
  onFileDrop,
  disableUpload,
}) => {
  const baseStyle = {
    width: width,
    display: "flex",
    alignItems: "center",
    paddingBlock: "30px",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#ECEEFF",
    borderStyle: "dashed",
    backgroundColor: "#F4F4F4",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "90%",
    cursor: disableUpload ? "not-allowed" : "pointer",
  };

  const focusedStyle = { borderColor: "#2196f3" };
  const acceptStyle = { borderColor: "#00e676" };
  const rejectStyle = { borderColor: "#ff1744" };

  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const parseCSV = (
    file: File
  ): Promise<{ userId: string; restrictRemark: string }[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          resolve(results.data as { userId: string; restrictRemark: string }[]);
        },
        error: (error) => {
          reject(error.message);
        },
      });
    });
  };

  const transformData = (
    data: { userId: string; restrictRemark: string }[]
  ): { data: { userId: number; restrictRemark: string }[] } => {
    return {
      data: data.map(({ userId, restrictRemark }) => ({
        userId: parseInt(userId, 10),
        restrictRemark: restrictRemark?.trim(),
      })),
    };
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (disableUpload) return; // Prevent drop action if upload is disabled

      const newFile = acceptedFiles[0]; // Only accept one file
      setFile(newFile); // Replace previous file with the new one
      onFileDrop(newFile); // Notify parent component

      parseCSV(newFile).then((data) => {
        const keyValuePairs = transformData(data);
      });
    },
    [onFileDrop, disableUpload]
  );

  const handleRemoveFile = () => {
    setFile(null); // Remove the current file
    onFileDrop(null); // Notify parent component
  };

  useEffect(() => {
    // console.log("Updated file:", file);
  }, [file]);

  const openFileDialog = () => {
    if (disableUpload) return; // Prevent file dialog from opening if upload is disabled
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      noClick: true,
      accept: {
        "application/vnd.oasis.opendocument.spreadsheet": [
          ".xlsx",
          ".xls",
          ".csv",
        ],
      },
      multiple: false,
      disabled: disableUpload, // Disable dropzone if upload is disabled
    });

  const style1 = {
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  };

  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return `${str.slice(0, maxLength)}...`;
    }
    return str;
  };

  const MAX_CHARACTERS = 30;

  return (
    <>
      <div className="container">
        <div className="flex justify-center">
          <div {...getRootProps()} style={style1} className="justify-center">
            <div className="text-center">
              <div className="flex justify-center">
                <img src="/assets/icons/uploadFiles.svg" alt="upload-icon" />
              </div>
              <p className="mb-2 mt-2 text-[#414141] text-xs font-medium font-inter">
                Drag files to upload
              </p>
              <p className="mb-2 mt-2 text-[#A4A2A2] text-xs font-normal font-inter">
                or
              </p>
              <Button
                className="mb-2"
                variant="contained"
                sx={{
                  textTransform: "none",
                  px: 6,
                  mb: 2,
                  bgcolor: disableUpload ? "#E0E0E0" : "#06B681",
                  "&:hover": {
                    bgcolor: disableUpload ? "#E0E0E0" : "#06B68133",
                  },
                  fontSize: "14px",
                  cursor: disableUpload ? "not-allowed" : "pointer",
                }}
                onClick={openFileDialog}
                disabled={disableUpload} // Disable button if upload is disabled
              >
                Browse
              </Button>
              <input
                {...getInputProps()}
                ref={inputRef}
                style={{ visibility: "hidden" }}
              />
              <p className="mb-2 text-[#A4A2A2] font-inter text-xs font-normal">
                Supported file formats: xls, xlsx, csv
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          className="mt-2"
        >
          <ul style={{ listStyle: "none", padding: 0, flexGrow: 1 }}>
            {file && (
              <li className="flex items-center justify-between bg-[#F5F5F5] p-2 rounded mt-2">
                <div className="flex">
                  <img
                    src="/assets/icons/excelIcon.svg"
                    className="mr-1"
                    alt="file-icon"
                  />
                  <p className="font-inter font-normal text-[#455A64] text-sm">
                    {truncateString(file.name, MAX_CHARACTERS)}
                  </p>
                </div>
                <div>
                  <IconButton onClick={handleRemoveFile}>
                    <img src="/assets/icons/deleteIcon.svg" alt="delete-icon" />
                  </IconButton>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UploadFiles;
