import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { OrderStatusApi } from "../../API/OrderStatus";
import { Alert, IconButton, Snackbar } from "@mui/material";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import CallDriverModal from "./CallDriverModal";
const containerStyle = {
  width: "100%",
  height: "60vh",
};

const libraries: "places"[] = ["places"];

interface NearbyRiderModalProps {
  orderNumber: number;
  sourceLat: number;
  sourceLong: number;
}

export default function NearbyRiderModalMap({
  orderNumber,
  sourceLat,
  sourceLong,
}: NearbyRiderModalProps) {
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [nearbyDrivers, setNearbyDrivers] = useState<any>([]);
  const [selectedMarker, setSelectedMarker] = useState<number | null>(null);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [callStatus, setCallStatus] = useState("connecting");
  const handleTryAgain = () => {
    setCallStatus("connecting");
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  useEffect(() => {
    const scriptLoaded = window.google && window.google.maps;
    setIsApiLoaded(!!scriptLoaded);
  }, []);

  useEffect(() => {
    if (orderNumber) {
      OrderStatusApi.api
        .nearbyDriverList(orderNumber)
        .then((response) => {
          const drivers = Array.isArray(response.data.data)
            ? response.data.data
            : [response.data.data];
          setNearbyDrivers(drivers);
        })
        .catch((error) => {
          console.error("Failed to fetch nearby drivers", error);
        });
    }
  }, [orderNumber]);

  const handleReassign = (riderIdToUse: number) => {
    const remark = "Order manually re-assgined";
    OrderStatusApi.api
      .reAssignOrder(orderNumber, riderIdToUse, remark)
      .then((response) => {
        setShowSuccessSnackbar(true);
        setSnackbarMessage("Order reassigned successfully");
      })
      .catch((error) => {
        setShowErrorSnackbar(true);
        setSnackbarMessage(
          error.response.data.message || "Could not reassign order"
        );
      });
  };

  let dynamicCenter = {
    lat: sourceLat,
    lng: sourceLong,
  };

  if (nearbyDrivers.length > 0) {
    // Calculate average latitude and longitude
    const sumLat = nearbyDrivers.reduce(
      (accumulator: number, currentValue: any) =>
        accumulator + parseFloat(currentValue.latitude),
      0
    );
    const sumLng = nearbyDrivers.reduce(
      (accumulator: number, currentValue: any) =>
        accumulator + parseFloat(currentValue.longitude),
      0
    );
    dynamicCenter = {
      lat: sumLat / nearbyDrivers.length,
      lng: sumLng / nearbyDrivers.length,
    };
  }
  const handleMarkerClick = (index: number) => {
    setSelectedMarker(index);
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey="AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg"
        libraries={libraries}
        onLoad={() => setIsApiLoaded(true)}
      >
        {isApiLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={dynamicCenter}
            zoom={10}
          >
            <Marker
              position={{
                lat: sourceLat,
                lng: sourceLong,
              }}
              icon={{ url: "/assets/icons/yellow-map-icon.svg" }}
            ></Marker>
            {nearbyDrivers.map((driver: any, index: any) => (
              <Marker
                key={index}
                position={{
                  lat: driver.latitude,
                  lng: driver.longitude,
                }}
                icon={{
                  url:
                    driver.onDemandRider === 1
                      ? "/assets/icons/black-map-icon.svg"
                      : "/assets/icons/nearby-rider-map-icon.svg",
                }}
                options={{
                  zIndex: driver.onDemandRider === 1 ? 999 : 1,
                }}
                onClick={() => handleMarkerClick(index)}
              >
                {selectedMarker === index && (
                  <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                    <div>
                      <span>{driver.username}</span>
                      <span className="ml-1">({driver.phoneNumber})</span>
                      <div className="flex justify-center">
                        <div
                          className="bg-[#171F21] px-4 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
                          onClick={() => handleReassign(driver.userId)}
                        >
                          <span className="text-[#FFFFFF] font-medium font-inter">
                            Reassign
                          </span>
                        </div>
                        {driver.phoneNumber && (
                          // <CallDriverModal userId={driver.userId} />
                          <CallDriverModal
                            orderNo={orderNumber}
                            userId={driver.userId}
                            contactType={3}
                            modalButton="orderManagementRow"
                          />
                        )}
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
            <Snackbar
              open={showSuccessSnackbar}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {SnackbarMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={showErrorSnackbar}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {SnackbarMessage}
              </Alert>
            </Snackbar>
          </GoogleMap>
        ) : (
          <div>Loading map...</div>
        )}
      </LoadScript>
    </>
  );
}
