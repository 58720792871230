import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Sidebar.css";

interface MenuItem {
  route: string;
  icon: string;
  label: string;
}

const menuItems: MenuItem[] = [
  // {
  //   route: "/home",
  //   icon: "/assets/icons/Dashboard-icon.svg",
  //   label: "Dashboard",
  // },
  {
    route: "/order-management",
    icon: "/assets/icons/Order-Management.svg",
    label: "Order Management",
  },
  // {
  //   route: "/create-orders",
  //   icon: "/assets/icons/Create-order.svg",
  //   label: "Create Order",
  // },
  {
    route: "/track-orders",
    icon: "/assets/icons/Track-order.svg",
    label: "Track Order",
  },
  // { route: "/payments", icon: "/assets/icons/Payments.svg", label: "Payments" },
  // {
  //   route: "/wallet-transactions",
  //   icon: "/assets/icons/Wallet-transaction.svg",
  //   label: "Wallet Transaction",
  // },
  // {
  //   route: "/rider-nomination",
  //   icon: "/assets/icons/rider-nomination.svg",
  //   label: "Rider Nomination",
  // },
  {
    route: "/order-summaries",
    icon: "/assets/icons/order-summaries.svg",
    label: "Order Summary",
  },
  {
    route: "/rider-supply-health",
    icon: "/assets/icons/Rider-supply-health.svg",
    label: "Rider Supply Health",
  },
  {
    route: "/riders-cities",
    icon: "/assets/icons/Riders-cities.svg",
    label: "Rider's Cities",
  },
  {
    route: "/blacklisted-riders",
    icon: "/assets/icons/Blacklisted-riders.svg",
    label: "Blacklisted Riders",
  },
  {
    route: "/breached-orders",
    icon: "/assets/icons/Breached-orders.svg",
    label: "Breached Orders",
  },
  {
    route: "/polygon-stress",
    icon: "/assets/icons/Polygon-stress.svg",
    label: "Polygon Stress",
  },
  {
    route: "/merchant-serviceability",
    icon: "/assets/icons/Riders-cities.svg",
    label: "Merchant Serviceability",
  },
  {
    route: "/ticketing",
    icon: "/assets/icons/ticketing.svg",
    label: "Ticketing",
  },
  {
    route: "/merchant-cod",
    icon: "/assets/icons/Merchant-cod.svg",
    label: "Merchant COD",
  },
  {
    route: "/merchant-allocation-serviceability",
    icon: "/assets/icons/merchant-allocation.svg",
    label: "Merchant Allocation",
  },
  // {
  //   route: "/help-desk",
  //   icon: "/assets/icons/help-desk.svg",
  //   label: "Help Desk",
  // },
];

const SideBarNavigation: React.FC = () => {
  const navigateTo = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);

  const handleMenuItemClick = (route: string) => {
    navigateTo(route);
  };

  useEffect(() => {
    setActiveMenuItem(window.location.pathname);
  }, [window.location.pathname]);

  const getMenuStyles = (route: string) =>
    [
      "flex",
      "items-center",
      "gap-4",
      "rounded-[12px]",
      "px-6",
      "py-2",
      "mx-1 ",
      "cursor-pointer",
      route === activeMenuItem && "bg-[#06B681]",
      "hover:bg-[#06B681] hover:text-white",
    ].join(" ");
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 999, // adjust z-index as needed
        boxShadow: "0px 7px 29px 0px #64646F1A",
        overflowY: "auto", // Add scrollbar if content overflows
      }}
      className="text-[#020D53] text-[14px] pt-4 font-medium px-1 font-inter shadow-md shadow-[#64646F1A]  h-full sticky top-0 ..."
    >
      <div className="">
        <div className="font-semibold text-2xl text-[#0079FF] text-left m-2 mb-6 cursor-pointer">
          <a href="/order-management">
            <img src="/assets/icons/zyppItLogo.png" alt="zyppIt Logo" />
          </a>
        </div>
        <ul className="flex flex-col gap-2">
          {menuItems.map((menuItem) => (
            <li
              key={menuItem.route}
              className={getMenuStyles(menuItem.route)}
              onClick={() => handleMenuItemClick(menuItem.route)}
            >
              <img
                className={`h-4 ${
                  menuItem.route === activeMenuItem
                    ? "filter-white"
                    : "filter-[#171F21]"
                } hover:filter-white`}
                src={menuItem.icon}
                alt=""
              />
              <span
                className={
                  menuItem.route === activeMenuItem
                    ? "text-white text-xs"
                    : "text-[#171F21] text-xs"
                }
              >
                {menuItem.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBarNavigation;
