import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  Chip,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
  Switch,
  LinearProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { OrderOnDemandApi } from "../../../API/ZyppIt";
import { MerchantCODApi } from "../../../API/MerchantCOD";

export default function MerchantCODTable() {
  const [loading, setLoading] = useState(true);
  const [merchantData, setMerchantData] = useState<any>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<any>(81);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [merchantCodList, setMerchantCodList] = useState<any>([]);
  const [inputError, setInputError] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openLimitDialog, setOpenLimitDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [codLimits, setCodLimits] = useState<{
    [key: number]: number | string;
  }>({});
  const [inputErrors, setInputErrors] = useState<{ [key: number]: string }>({});

  const cityCodeMap: { [key: string]: string } = {
    BLR: "Bangalore",
    DL: "Delhi",
    MUM: "Mumbai",
    GGN: "Gurgaon",
    FDB: "Faridabad",
    GZB: "Ghaziabad",
    HYD: "Hyderabad",
    Noida: "Noida",
  };
  const filteredMerchantCodList = merchantCodList.filter((row: any) => {
    const cityName = cityCodeMap[row.city] || row.city;
    return cityName.toLowerCase().includes(searchVal?.toLowerCase() || "");
  });

  useEffect(() => {
    const api = new OrderOnDemandApi();
    api
      .merchantList()
      .then((e) => {
        setMerchantData(e.data.data);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, []);
  const handleTableData = () => {
    setLoading(true);
    MerchantCODApi.api
      .merchantCODList(selectedMerchant)
      .then((e) => {
        if (e.data && Array.isArray(e.data.data)) {
          setMerchantCodList(e.data.data);
          const initialCodLimits: { [key: number]: number | string } = {};
          e.data.data.forEach((row: any) => {
            initialCodLimits[row.id] = row.codLimit;
          });
          setCodLimits(initialCodLimits);
          setLoading(false);
        } else {
          setShowSnackbar(true);
          setMerchantCodList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowSnackbar(true);
        console.error("API call error: ", error);
      });
  };
  useEffect(() => {
    handleTableData();
  }, [selectedMerchant]);

  const handleConfirmToggle = () => {
    MerchantCODApi.api
      .updateMerchantCODStatus(currentRow?.id, !currentRow?.codStatus)
      .then((response) => {
        console.log(response);
        setShowSuccessSnackbar(true);
        setSnackbarMessage("COD Status updated");
      })
      .catch((error) => {
        console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Error while updating COD Status";
        setShowErrorSnackbar(true);
        setSnackbarMessage(errorMessage);
      });

    setOpenDialog(false);
  };

  const handleCancelToggle = () => {
    setOpenDialog(false);
    setCurrentRow(null);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccessSnackbar(false);
    handleTableData();
  };
  const handleErrorSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
  };
  const handleConfirmSave = () => {
    if (currentRow && codLimits[currentRow.id] !== undefined) {
      MerchantCODApi.api
        .updateMerchantCODLimit(currentRow.id, Number(codLimits[currentRow.id]))
        .then((response) => {
          setShowSuccessSnackbar(true);
          setSnackbarMessage("COD Limit updated");
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || "Error updating COD limit";
          setShowErrorSnackbar(true);
          setSnackbarMessage(errorMessage);
        });
    }

    setOpenLimitDialog(false);
  };

  const handleCancelSave = () => {
    setOpenLimitDialog(false);
  };
  const handleCodValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    rowId: number
  ) => {
    const inputValue = event.target.value;
    const numericInput = inputValue.replace(/\D/g, "");
    const maxLimit = 10000000;

    const numericValue = Number(numericInput);
    const valueToSet = numericValue > maxLimit ? maxLimit : numericValue;

    setCodLimits((prev) => ({
      ...prev,
      [rowId]: valueToSet,
    }));

    if (inputValue !== numericInput) {
      setInputErrors((prev) => ({
        ...prev,
        [rowId]: "Please enter only numbers",
      }));
    } else if (numericValue > maxLimit) {
      setInputErrors((prev) => ({
        ...prev,
        [rowId]: `Value cannot exceed ${maxLimit}`,
      }));
    } else {
      setInputErrors((prev) => ({
        ...prev,
        [rowId]: "",
      }));
    }
  };

  return (
    <>
      {loading ? (
        <LinearProgress
          sx={{
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#00CD5B",
            },
          }}
        />
      ) : (
        <>
          <TableContainer className="">
            <div className="flex items-center justify-between py-4 px-3 border-slate-200 border-solid">
              <div className="border-2 rounded-2xl px-2 ml-2">
                <label className="text-[#727597] items-center text-sm font-normal font-inter">
                  Merchant :
                </label>
                <select
                  name="merchant"
                  id="merchant"
                  className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[150px]"
                  value={selectedMerchant}
                  onChange={(e) => {
                    setSelectedMerchant(e.target.value);
                  }}
                >
                  {merchantData?.map((merchant: any) => (
                    <option
                      key={merchant.merchantId}
                      value={merchant.merchantId}
                    >
                      {merchant.merchantName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex">
                <div className="border-2 rounded-2xl px-2">
                  <InputBase
                    placeholder="Search by City"
                    className="flex-grow"
                    inputProps={{ "aria-label": "search" }}
                    value={searchVal}
                    onChange={(search) => setSearchVal(search.target.value)}
                  />
                  <SearchIcon
                    style={{ color: "#727597" }}
                    className="cursor-pointer ml-4"
                  />
                </div>
              </div>
            </div>
            <hr />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Merchant
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    City
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    COD
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    COD Limit
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMerchantCodList?.map((row: any, index: any) => (
                  <React.Fragment key={index}>
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <Chip
                          label={`${row?.merchant}`}
                          size="small"
                          style={{
                            backgroundColor: "#2D0F8A1F",
                            color: "#2D0F8A",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {cityCodeMap[row?.city]}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <Switch
                          checked={row?.codStatus === true}
                          onChange={() => {
                            setCurrentRow(row);
                            setOpenDialog(true);
                          }}
                          color="default"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#00CB60",
                              "&:hover": {
                                backgroundColor: "#00CB6033",
                              },
                            },
                            "& .MuiSwitch-switchBase": {
                              "&:hover": {
                                backgroundColor: "#00CB6033",
                              },
                            },

                            "& .MuiSwitch-track": {
                              backgroundColor: "#00CB6033",
                            },
                          }}
                        />
                      </TableCell>

                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <div className="border-[1px] border-[#ECEEFF] rounded-[50px] px-1 w-3/5">
                          <label
                            className={`items-center font-normal font-inter px-1 ${
                              row.codStatus === true
                                ? "text-[#575F6B]"
                                : "text-[#C2CCDE]"
                            }`}
                          >
                            ₹ <span className="text-[#ECEEFF]">|</span>
                          </label>
                          <InputBase
                            disabled={!row?.codStatus}
                            className="w-4/5"
                            value={codLimits[row.id]}
                            onChange={(e) => handleCodValue(e, row.id)}
                            error={!!inputError}
                          />
                        </div>
                        {inputErrors[row.id] && (
                          <Typography
                            color="error"
                            variant="caption"
                            display="block"
                            style={{ marginTop: "4px" }}
                          >
                            {inputErrors[row.id]}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <Button
                          className="mb-2"
                          variant="contained"
                          onClick={() => {
                            setCurrentRow(row);
                            setOpenLimitDialog(true);
                          }}
                          disabled={codLimits[row.id] == row.codLimit}
                          sx={{
                            textTransform: "none",
                            px: 3,
                            py: 1,
                            bgcolor: "#171F21",
                            "&:hover": { bgcolor: "#171F2180" },
                            borderRadius: "50px",
                          }}
                        >
                          Save
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            <Dialog open={openDialog} onClose={handleCancelToggle}>
              <DialogTitle>Update COD Status</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to{" "}
                  {currentRow?.codStatus ? "disable" : "enable"} COD for{" "}
                  {currentRow?.merchant} {currentRow?.city}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ textTransform: "none", color: "#171F21", py: 1, px: 5 }}
                  onClick={handleCancelToggle}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    ml: 3,
                    color: "white",
                    bgcolor: "#171F21",
                    borderRadius: "50px",
                    textTransform: "none",
                    py: 1,
                    px: 5,
                    "&:hover": { bgcolor: "#171F2180" },
                  }}
                  onClick={handleConfirmToggle}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={openLimitDialog} onClose={handleCancelSave}>
              <DialogTitle>Update COD Limit</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to update COD Limit for{" "}
                  {currentRow?.merchant} {currentRow?.city}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ textTransform: "none", color: "#171F21", py: 1, px: 5 }}
                  onClick={handleCancelSave}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    ml: 3,
                    color: "white",
                    bgcolor: "#171F21",
                    borderRadius: "50px",
                    textTransform: "none",
                    py: 1,
                    px: 5,
                    "&:hover": { bgcolor: "#171F2180" },
                  }}
                  onClick={handleConfirmSave}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </TableContainer>
          <Snackbar
            open={showSuccessSnackbar}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {SnackbarMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={showErrorSnackbar}
            autoHideDuration={3000}
            onClose={handleErrorSnackbarClose}
          >
            <Alert
              onClose={handleErrorSnackbarClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {SnackbarMessage}
            </Alert>
          </Snackbar>

          <Snackbar
            open={showSnackbar}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              sx={{ width: "100%" }}
              onClose={handleClose}
              severity="error"
            >
              No data found
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}
