import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import { RiderCitiesApi } from "../../../API/RidersCities";

import { Typography, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Header from "../../Layouts/AppHeader/Header";
import CityCard from "../../Layouts/CityCard/CityCard";

function RidersCities() {
  const [cityData, setCityData] = useState<any>([]);
  useEffect(() => {
    RiderCitiesApi.api
      .riderCitiesLocalityList()
      .then((e) => {
        setCityData(e.data.data.citiesData);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, []);
  return (
    <>
      <div className="flex">
        <div className="w-1/6 mr-3 ml-3">
          <SideBarNavigation />
        </div>
        <div className="w-5/6">
          <div className="pb-4 pr-4 pl-2 mt-6">
            <Header merchantLogoUrl="" />
            <div className="flex justify-between mt-8">
              <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-inter">
                Rider's Cities
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  sx={{ mt: 4 }}
                >
                  <Typography
                    color="#ACACAC"
                    sx={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    Riders Cities
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div
            className="ml-2 mb-4 w-[98%] pb-3"
            style={{
              borderRadius: "8px",
            }}
          >
            {cityData.map((city: any, index: any) => (
              <CityCard key={index} cityData={city} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default RidersCities;
