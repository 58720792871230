import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Chip,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RiderNominationApi } from "../../API/RiderNomination";
import ReturnToDashboardModal from "./ReturnToDashboardModal";
import { OrderStatusApi } from "../../API/OrderStatus";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

interface UnassignOrderModalProps {
  orderNumber: number;
}

export default function UnassignOrderModal({
  orderNumber,
}: UnassignOrderModalProps) {
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState<number | undefined>();
  const [comments, setComments] = useState<string>("");

  // State to hold the status

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  const handleComments = (e: any) => {
    setComments(e.target.value);
  };
  const getStatusLabel = (status: number | undefined) => {
    if (status === 1) {
      return "Approved";
    } else if (status === 2) {
      return "Rejected";
    } else {
      return "Not Serviceable";
    }
  };
  const getStatusColor = (status: number | undefined) => {
    if (status === 1) {
      return "#00CB60";
    } else {
      return "#F94932";
    }
  };
  const getStatusBgColor = (status: number | undefined) => {
    if (status === 1) {
      return "#00CB6026";
    } else {
      return "#F9493226";
    }
  };

  const handleUnassignOrder = () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const remark = comments;
    OrderStatusApi.api
      .unAssignOrder(orderNumber, remark)
      .then((response) => {
        console.log(response);
        setShowSuccessSnackbar(true);
        setSnackbarMessage("Order unassigned successfully");
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Failed to unassign order";
        setShowErrorSnackbar(true);
        setSnackbarMessage(errorMessage);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <div
        className="bg-[#0058BA] px-4 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
        onClick={handleOpen}
      >
        <span className="text-[#FFFFFF] text-sm font-medium font-inter">
          Unassign
        </span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-end mb-4">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="px-6">
              <Typography
                id="modal-modal-description"
                sx={{ pb: 2 }}
                className="text-[#414141] text-xl"
              >
                Remarks
              </Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                sx={{ pb: 2 }}
                rows={5}
                placeholder="Remarks"
                fullWidth
                onChange={handleComments}
              />
            </div>
            <div className="flex justify-center mt-4 items-center pb-4">
              <Button
                sx={{ textTransform: "none", color: "#171F21", py: 1, px: 8 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleUnassignOrder}
                disabled={isSubmitting || !comments}
                sx={{
                  ml: 3,
                  color: "white",
                  bgcolor: "#171F21",
                  borderRadius: "50px",
                  textTransform: "none",
                  py: 1,
                  px: 8,
                  "&:hover": { bgcolor: "#171F2180" },
                }}
              >
                Un - assign
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
