import React from "react";
import { Routes } from "../states/States";
import Home from "../Views/Home";
import TrackOrder from "../Components/UI/TrackOrder";
import SignIn from "../login";
import Dashboard from "../Components/UI/Dashboard";
import OrderManagement from "../Components/UI/OrderManagement";
import Payment from "../Components/UI/Payments";
import WalletTransaction from "../Components/UI/WalletTransaction";
import RateCard from "../Components/UI/RateCard";
import CreateOrder from "../Components/UI/CreateOrders";
import BulkOrder from "../Components/UI/BulkOrders";
import AddStore from "../Components/UI/AddStore";
import ForgotPassword from "../Components/UI/ForgotPassword";
import RiderNomination from "../Components/UI/RiderNomination";
import HelpDesk from "../Components/UI/HelpDesk";
import OrderSummaries from "../Components/UI/OrderSummaries";
import { Navigate } from "react-router-dom";
import RiderSupplyHealth from "../Components/UI/RiderSupplyHealth";
import RidersCities from "../Components/UI/RidersCities";
import BlacklistedRiders from "../Components/UI/BlacklistedRiders";
import BreachedOrders from "../Components/UI/BreachedOrders";
import PolygonStress from "../Components/UI/PolygonStress";
import MerchantServiceability from "../Components/UI/MerchantServiceability";
import Ticketing from "../Components/UI/Ticketing";
import MerchantCOD from "../Components/UI/MerchantCOD";
import MerchantAllocationServiceability from "../Components/UI/MerchantAllocationServiceability";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = localStorage.getItem("token");
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const CommonRoutes: Array<Routes> = [
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/track-orders",
    element: (
      <RequireAuth>
        <TrackOrder />
      </RequireAuth>
    ),
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/order-management",
    element: (
      <RequireAuth>
        <OrderManagement />
      </RequireAuth>
    ),
  },
  {
    path: "/payments",
    element: <Payment />,
  },
  {
    path: "/wallet-transactions",
    element: <WalletTransaction />,
  },
  {
    path: "/rate-cards",
    element: <RateCard />,
  },
  {
    path: "/create-orders",
    element: <CreateOrder />,
  },
  {
    path: "/create-orders/bulk-order",
    element: <BulkOrder />,
  },
  {
    path: "/add-store",
    element: <AddStore />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/rider-nomination",
    element: <RiderNomination />,
  },
  {
    path: "/help-desk",
    element: <HelpDesk />,
  },
  {
    path: "/order-summaries",
    element: (
      <RequireAuth>
        <OrderSummaries />
      </RequireAuth>
    ),
  },
  {
    path: "/rider-supply-health",
    element: <RiderSupplyHealth />,
  },
  {
    path: "/riders-cities",
    element: <RidersCities />,
  },
  {
    path: "/blacklisted-riders",
    element: <BlacklistedRiders />,
  },
  {
    path: "/breached-orders",
    element: <BreachedOrders />,
  },
  {
    path: "/polygon-stress",
    element: <PolygonStress />,
  },
  {
    path: "/merchant-serviceability",
    element: <MerchantServiceability />,
  },
  {
    path: "/ticketing",
    element: <Ticketing />,
  },
  {
    path: "/merchant-cod",
    element: <MerchantCOD />,
  },
  {
    path: "/merchant-allocation-serviceability",
    element: <MerchantAllocationServiceability />,
  },
];
export const Router: Array<Routes> = [
  ...CommonRoutes,
  /**
   *@description
   * Url to Embeded in Iframe
   *
   */
  // {
  //   path: "", // test route
  //   element: <Header />,
  //   children: [
  //     ...CommonRoutes,
  //     {
  //       path: "*", // test route
  //       element: <div>NOT FOUND</div>,
  //     },
  //   ],
  // },
];
