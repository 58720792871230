import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Tooltip,
  IconButton,
} from "@mui/material";
import { OrderStatusApi } from "../../API/OrderStatus";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  py: 1,
  px: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

interface CallModalProps {
  userId: number | null;
  orderNo: number;
  contactType: number;
  modalButton: string;
}

const CallDriverModal: React.FC<CallModalProps> = ({
  userId,
  orderNo,
  contactType,
  modalButton,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    handleCallRider();
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
  };
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccessSnackbar(false);
  };

  const handleCallRider = () => {
    setLoading(true);
    OrderStatusApi.api
      .calling(contactType, orderNo, userId)
      .then((response) => {
        console.log(response);
        if (response.code === 2000) {
          setShowSuccessSnackbar(true);
          handleClose();
          setLoading(false);
        } else {
          setErrorMessage(response.message);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Failed to Call";
        setErrorMessage(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const renderOpenButton = () => {
    if (modalButton === "orderManagementCust") {
      return (
        <Tooltip title="Call Customer">
          <IconButton onClick={handleOpen}>
            <PhoneForwardedIcon sx={{ color: "#00CD5B" }} />
          </IconButton>
        </Tooltip>
      );
    } else if (modalButton === "riderTicket") {
      // Render another type of button for a different modalButton value
      return (
        <div
          className="bg-[#fff] border border-[1px] border-[#171F21] ml-1 px-4 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
          onClick={handleOpen}
        >
          <div className="flex text-[#171F21] items-center font-medium font-inter">
            <img
              src="/assets/icons/call-icon.svg"
              className="mr-2"
              style={{ height: "16px" }}
            />
            Call Rider
          </div>
        </div>
      );
    } else if (modalButton === "customerTicket") {
      // Render another type of button for a different modalButton value
      return (
        <div
          className="bg-[#fff] border border-[1px] border-[#171F21] ml-1 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
          onClick={handleOpen}
        >
          <div className="flex text-[#171F21] items-center font-medium font-inter">
            <img
              src="/assets/icons/call-icon.svg"
              className="mr-2"
              style={{ height: "16px" }}
            />
            Call Customer
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="bg-[#fff] border border-[1px] border-[#171F21] ml-1 px-4 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
          onClick={handleOpen}
        >
          <div className="flex text-[#171F21] items-center font-medium font-inter">
            <img
              src="/assets/icons/call-icon.svg"
              className="mr-2"
              style={{ height: "16px" }}
            />
            Call
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      {renderOpenButton()}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="flex w-full justify-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {loading ? (
            <div className="flex justify-center p-2 py-4">
              <CircularProgress sx={{ color: "#00CD5B" }} />
            </div>
          ) : (
            <div className="py-2">
              {errorMessage && (
                <Typography
                  variant="body1"
                  sx={{ textAlign: "center" }}
                  color="error"
                  gutterBottom
                >
                  {errorMessage}
                </Typography>
              )}
            </div>
          )}
          <Button
            variant="contained"
            onClick={handleCallRider}
            disabled={loading}
            sx={{
              bgcolor: "#171F21",
              textTransform: "none",
              px: 4,
              py: 1,
              borderRadius: "50px",
              color: "white",
              "&:hover": { bgcolor: "#171F2180" },
            }}
          >
            Try Again
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Call initiated
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CallDriverModal;
