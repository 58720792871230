import axios, { AxiosInstance, AxiosResponse } from "axios";
import { allocationServiceabilityResponseProps } from "../@Types/DRO/AllocationServiceabilityResponse";
export class AllocationServiceabilityApi {
  public static api = new AllocationServiceabilityApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://stag-ood.bcykal.com/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }

  merchantAllocationList(
    merchantId: number
  ): Promise<AxiosResponse<allocationServiceabilityResponseProps>> {
    let apiUrl = "mobycy/on-demand/dashboard/v1/merchant-city/get-list";
    return this.instance.get<allocationServiceabilityResponseProps>(apiUrl, {
      params: {
        merchantId: merchantId,
      },
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  updateMerchantAllocationType(
    id: number,
    clientType: number,
    allocationType: number
  ): Promise<any> {
    return this.instance
      .post<any>(
        `mobycy/on-demand/dashboard/v1/merchant-city/save`,
        {
          id,
          clientType,
          allocationType,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}
