import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const libraries: "places"[] = ["places"];

const iconUrls: Record<number, string> = {
  "-3": "/assets/icons/red-map-icon.svg",
  "-2": "/assets/icons/red-map-icon.svg",
  "-1": "/assets/icons/red-map-icon.svg",
  "0": "/assets/icons/yellow-map-icon.svg",
  "1": "/assets/icons/yellow-map-icon.svg",
  "2": "/assets/icons/yellow-map-icon.svg",
  "3": "/assets/icons/yellow-map-icon.svg",
  "4": "/assets/icons/yellow-map-icon.svg",
  "5": "/assets/icons/yellow-map-icon.svg",
  "6": "/assets/icons/green-map-icon.svg",
  "7": "/assets/icons/red-map-icon.svg",
  "8": "/assets/icons/red-map-icon.svg",
  "9": "/assets/icons/red-map-icon.svg",
  "10": "/assets/icons/red-map-icon.svg",
};
function GoogleMapApi({
  trackOrdersData,
  mapProps = {},
  children = <></>,
}: any) {
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 28.7136, lng: 77.1294 });

  useEffect(() => {
    const scriptLoaded = window.google && window.google.maps;
    setIsApiLoaded(!!scriptLoaded);
  }, []);
  useEffect(() => {
    const calculateCenter = (data: any[]) => {
      if (data.length === 0) return;

      const latSum = data.reduce(
        (acc, curr) => acc + parseFloat(curr?.destinationLat),
        0
      );
      const lngSum = data.reduce(
        (acc, curr) => acc + parseFloat(curr?.destinationLong),
        0
      );
      const avgLat = latSum / data.length;
      const avgLng = lngSum / data.length;

      setMapCenter({ lat: avgLat, lng: avgLng });
    };

    calculateCenter([
      ...trackOrdersData,
      // ...trackOrdersData1,
      // ...trackOrdersData2,
    ]);
  }, [trackOrdersData]);
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg"
      libraries={libraries}
      onLoad={() => setIsApiLoaded(true)}
    >
      {isApiLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={12}
          {...mapProps}
        >
          {[
            ...trackOrdersData?.map(
              (order: any, index: React.Key | null | undefined) => (
                <Marker
                  key={`0-${index}`}
                  position={{
                    lat: parseFloat(order?.destinationLat),
                    lng: parseFloat(order?.destinationLong),
                  }}
                  icon={{ url: iconUrls[order?.orderStatus] }}
                />
              )
            ),
          ]}
        </GoogleMap>
      ) : (
        <div>Loading map...</div>
      )}
      {children}
    </LoadScript>
  );
}

export default GoogleMapApi;
