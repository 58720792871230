import React, { useEffect, useState } from "react";
import TableImplementation from "../../Layouts/Table";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import DashboardCard from "../../Layouts/DashboardCard";
import Header from "../../Layouts/AppHeader/Header";
import {
  Typography,
  Breadcrumbs,
  Button,
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
  Menu,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router";
import { dashBoardFrontCardResponse } from "../../../@Types/DRO/HomePageResponse";
import { OrderOnDemandApi } from "../../../API/ZyppIt";
import DatePicker from "react-datepicker";
import { Logout } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import BlacklistedRidersTable from "../../Layouts/BlacklistedRidersTable";
import BanRidersModal from "../../Modals/BanRidersModal";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import BreachedOrdersTable from "../../Layouts/BreachedOrdersTable";
import BreachedOrderCard from "../../Layouts/BreachedOrdersCard";

function BreachedOrders() {
  return (
    <>
      <div className="flex">
        <div className="w-1/6 mr-3 ml-4">
          <SideBarNavigation />
        </div>
        <div className="w-5/6">
          <div className="pb-4 pr-4 pl-2 mt-6">
            <Header merchantLogoUrl="" />
            <div className="flex justify-between mt-8">
              <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-inter">
                Breached Orders
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  sx={{ mt: 4 }}
                >
                  <Typography
                    color="#717171"
                    sx={{ fontSize: "12px", fontWeight: "700" }}
                  >
                    Breached Orders
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div>
              <BreachedOrderCard />
            </div>
          </div>
          <div
            className="ml-2 w-[98%]"
            style={{
              boxShadow: "0px 7px 29px 0px #64646F33",
              borderRadius: "8px",
            }}
          >
            <BreachedOrdersTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default BreachedOrders;
