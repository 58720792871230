import React, { useEffect, useState, useCallback } from "react";
import {
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
} from "@mui/material";
// import "animate.css/animate.min.css";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import TicketExtraDetails from "../TicketExtraDetails";
import { SosTicketingApi } from "../../../API/SosTicketing";

export default function TicketingTable({
  startDate,
  endDate,
  navigateSearch,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
  navigateSearch: string | null;
}) {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const handleExpandToggle = (id: string) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter((id) => id !== id)
        : [...prevExpandedRows, id]
    );
  };
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | undefined>(
    navigateSearch ?? undefined
  );
  const [selectedSubtype, setSelectedSubtype] = useState<any>(-1);
  const [ticketsData, setTicketsData] = useState<any>();
  const [subtypeData, setSubtypeData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<string>("-2");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const pageRange = 5;
  const getStatusChipData = (
    orderStatus: number
  ): { text: string; color: string; bgcolor: string } => {
    switch (orderStatus) {
      case 0:
        return { text: "Open", color: "#FFB846", bgcolor: "#FFF6E8" };
      case 1:
        return { text: "In Progress", color: "#00CB60", bgcolor: "#00CB6026" };
      case 2:
        return {
          text: "Closed",
          color: "#FF725E",
          bgcolor: "#FF725E33",
        };
      default:
        return { text: "", color: "#171F21", bgcolor: "#171F2126" }; // Default values
    }
  };
  useEffect(() => {
    if (navigateSearch != null) {
      setSearchVal(navigateSearch);
    }
  }, [navigateSearch]);
  const fetchOrderData = useCallback(
    debounce((searchVal: any) => {
      const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
      if (endDate) {
        SosTicketingApi.api
          .ticketDetailsList(
            selectedSubtype,
            startDate,
            endDate,
            selectedStatus,
            page + 1,
            apiUrl
          )
          .then((response) => {
            if (response.data && Array.isArray(response.data.data.ticketList)) {
              setTicketsData(response.data.data.ticketList);
              setTotalCount(response.data.data.listCount);
            } else {
              setShowSnackbar(true);
              setTicketsData([]);
            }
          })
          .catch((error) => {
            console.error("API call error: ", error);
            setShowSnackbar(true);
            setTicketsData([]);
          });
      }
    }, 500),
    [selectedSubtype, startDate, endDate, selectedStatus, page]
  );

  useEffect(() => {
    fetchOrderData(searchVal);
  }, [searchVal, page, selectedStatus, selectedSubtype, endDate]);
  useEffect(() => {
    const api = new SosTicketingApi();
    api
      .subTypeList()
      .then((e) => {
        setSubtypeData(e.data.data);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, []);
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    if (pageCount === 1) {
      setPage(0);
    } else {
      const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
      setPage(newPageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const isTimeExceeded = (assignedTime: string) => {
    const currentTime = new Date();
    const assignedTimeDate = new Date(assignedTime);
    const timeDifference =
      (currentTime.getTime() - assignedTimeDate.getTime()) / 1000;
    return timeDifference > 240;
  };
  useEffect(() => {
    setPage(0);
  }, [searchVal, selectedStatus, selectedSubtype]);
  return (
    <>
      <TableContainer className="">
        <div className="flex justify-between mx-4 mt-4 mb-4">
          <div className="flex">
            <div className="border-2 rounded-2xl px-2 mt-1">
              <label className="text-[#727597] text-sm font-normal font-inter">
                Status :{" "}
              </label>
              <select
                name="orderStatus"
                id="orderStatus"
                className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[150px]"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="-2">All</option>
                <option value="0">Open</option>
                <option value="1">In Progress</option>
                <option value="2">Closed</option>
              </select>
            </div>
            <div className="border-2 rounded-2xl px-2 mt-1 ml-3">
              <label className="text-[#727597] text-sm font-normal font-inter">
                Sub Type :{" "}
              </label>
              <select
                name="orderStatus"
                id="orderStatus"
                className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[320px]"
                value={selectedSubtype}
                onChange={(e) => setSelectedSubtype(e.target.value)}
              >
                <option value={-1}>All</option>
                {subtypeData?.map((subType: any) => (
                  <option key={subType.id} value={subType.id}>
                    {subType.ticketTypeLabel} ({subType.count})
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="border-2 rounded-2xl px-4 text-xs">
            <InputBase
              placeholder="Search by Ticket ID"
              className="flex-grow text-xs"
              value={searchVal}
              onChange={(search) => setSearchVal(search.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
            <SearchIcon
              style={{ color: "#727597" }}
              className=" cursor-pointer"
            />
          </div>
        </div>
        <hr />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                #
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Ticket ID
              </TableCell>

              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                Vehicle Code
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Rider Id & Name
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Created Time
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Order No.
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Status
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ticketsData?.map((row: any, index: any) => (
              <React.Fragment key={index}>
                <TableRow
                  key={index}
                  className={
                    expandedRows.includes(row?.orderId) ? "expanded-row" : ""
                  }
                >
                  <TableCell>
                    {row.orderStatus === 0 &&
                    isTimeExceeded(row.assignedTime) ? (
                      <div
                        style={{
                          position: "relative",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "6px",
                            left: "10px",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor: "#F94932",
                            animation: "ripple 1s infinite",
                          }}
                        >
                          <div className="solid-dot-red" />
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          backgroundColor: "#06B681",
                          margin: "auto",
                        }}
                      ></div>
                    )}
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {row.ticketId}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "12px",
                      fontWeight: "400",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      wordBreak: "break-word",
                      textOverflow: "ellipsis",
                      maxWidth: "140px",
                    }}
                  >
                    {row.vehicleCode}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {row.riderId && row.riderName
                      ? `${row.riderId}, ${row.riderName}`
                      : row.riderId
                      ? `${row.riderId},--`
                      : row.riderName
                      ? `--,${row.riderName}`
                      : "--"}
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {row.createdTime}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {row.orderNumber}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#414141",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    <Chip
                      label={getStatusChipData(row.status).text}
                      size="small"
                      sx={{ p: 1, width: "100%" }}
                      style={{
                        backgroundColor: getStatusChipData(row.status).bgcolor,
                        color: getStatusChipData(row.status).color,
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <button onClick={() => handleExpandToggle(row?.ticketId)}>
                      {expandedRows.includes(row?.ticketId) ? (
                        <img
                          src="/assets/icons/dropupIcon.svg"
                          alt=""
                          className="mt-2"
                        />
                      ) : (
                        <img
                          src="/assets/icons/dropdownIcon.svg"
                          alt=""
                          className="mt-2"
                        />
                      )}
                    </button>
                  </TableCell>
                </TableRow>
                {expandedRows.includes(row?.ticketId) && (
                  <TableRow className="expanded-content">
                    <TableCell colSpan={8}>
                      {/* Additional content when row is expanded */}
                      {/* You can render details or nested components here */}
                      <div className="w-full">
                        <TicketExtraDetails
                          ticketId={row?.ticketId}
                          riderUserId={row?.riderId}
                          orderNumber={row?.orderNumber}
                          status={row?.status}
                          searchTicket={navigateSearch}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pt-4 pb-2 pr-5">
        <div
          className="pagination"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="flex">
            <img
              src="/assets/icons/leftArrow.svg"
              alt=""
              onClick={() => handlePageChange(page - 1)}
            />
            {renderPageNumbers()}
            <img
              src="/assets/icons/rightArrow.svg"
              alt=""
              onClick={() => handlePageChange(page + 1)}
              className="ml-1 mr-6"
            />
          </div>
        </div>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
          No data found
        </Alert>
      </Snackbar>
    </>
  );
}
