import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Typography,
  Card,
  Chip,
  Button,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import { MerchantServiceabilityApi } from "../../../API/MerchantServiceability";

interface Locality {
  merchantId: number;
  merchantName: string;
  active: boolean;
}

interface CityData {
  cityCode: string;
  cityName: string;
  data: Locality[];
}

interface CityCardProps {
  cityData: CityData;
}

const MerchantCityCard: React.FC<CityCardProps> = ({ cityData }) => {
  const [selectedChips, setSelectedChips] = useState<Locality[]>([]);
  const [deselectedChips, setDeselectedChips] = useState<Locality[]>([]);

  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectAllCity, setSelectAllCity] = useState<boolean>(true);
  const [initialActiveLocalities, setInitialActiveLocalities] = useState<
    Locality[]
  >([]);
  const [initialInactiveLocalities, setInitialInactiveLocalities] = useState<
    Locality[]
  >([]);
  const [previousSelectedChips, setPreviousSelectedChips] = useState<
    Locality[]
  >([]);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [newlySelectedIds, setNewlySelectedIds] = useState<number[]>([]);
  const [newlyDeselectedIds, setNewlyDeselectedIds] = useState<number[]>([]);
  useEffect(() => {
    const activeLocalities = cityData.data.filter(
      (locality) => locality.active
    );
    setSelectedChips(activeLocalities);
    setInitialActiveLocalities(activeLocalities);
    setPreviousSelectedChips(activeLocalities);
    const deselected = cityData.data.filter(
      (locality) => !activeLocalities.includes(locality)
    );
    setInitialInactiveLocalities(deselected);
  }, [cityData]);

  useEffect(() => {
    const deselected = cityData.data.filter(
      (locality) => !selectedChips.includes(locality)
    );
    setDeselectedChips(deselected);

    const newSelectedIds = selectedChips
      .filter((chip) => !initialActiveLocalities.includes(chip))
      .map((chip) => chip.merchantId);
    setNewlySelectedIds(newSelectedIds);

    const newDeselectedIds = deselected
      .filter((chip) => !initialInactiveLocalities.includes(chip))
      .map((chip) => chip.merchantId);
    setNewlyDeselectedIds(newDeselectedIds);
  }, [selectedChips]);

  const handleChipClick = (locality: Locality) => {
    setSelectedChips((prev) => {
      const index = prev.findIndex(
        (chip) => chip.merchantId === locality.merchantId
      );
      if (index > -1) {
        return prev.filter((chip) => chip.merchantId !== locality.merchantId);
      } else {
        return [...prev, locality];
      }
    });
  };

  const handleCityNameClick = () => {
    if (!selectAllCity) {
      setSelectedChips(previousSelectedChips);
    } else {
      setPreviousSelectedChips([...selectedChips]);
      setSelectedChips([]);
    }
    setSelectAllCity(!selectAllCity);
  };

  const handleSelectAllToggle = () => {
    if (!selectAll) {
      setSelectedChips(cityData.data);
    } else {
      setSelectedChips(initialActiveLocalities);
    }
    setSelectAll(!selectAll);
  };
  const updateServiceability = () => {
    const combinedIds = [...newlySelectedIds, ...newlyDeselectedIds];

    const requestBody = combinedIds.map((id) => {
      const locality = cityData.data.find((loc) => loc.merchantId === id);
      return {
        merchantId: locality?.merchantId,
        merchantName: locality?.merchantName,
        cityCode: cityData.cityCode,
        active: selectedChips.some((chip) => chip.merchantId === id),
      };
    });
    MerchantServiceabilityApi.api
      .updateMerchantServiceability(requestBody)
      .then((response) => {
        console.log(response);
        setShowSuccessSnackbar(true);
        setSnackbarMessage("Serviceability Updated");
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Failed";
        console.error(error);
        setShowErrorSnackbar(true);
        setSnackbarMessage(errorMessage);
      });
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  return (
    <>
      <div className="flex">
        <Card
          sx={{
            mb: 2,
            mr: 2,
            boxShadow: "0px 7px 29px 0px #64646F33",
            height: "100%",
            width: "100%",
          }}
          className={`rounded-lg border border-[#ECEEFF]`}
        >
          <div className="border-b flex w-full justify-between align-center">
            <div className="p-2 flex items-center">
              {initialActiveLocalities.length > 0 && (
                <Checkbox
                  checked={selectAllCity}
                  onChange={handleCityNameClick}
                  sx={{
                    "&&": { p: 0, mr: 1 },
                    color: "#06B681",
                    "&.Mui-checked": {
                      color: "#06B681",
                      p: 0,
                    },
                  }}
                />
              )}
              <Typography
                className="text-xl ml-2 font-bold"
                sx={{ color: "#414141" }}
              >
                {cityData.cityName}
              </Typography>
            </div>
            <div className="p-2 flex items-center">
              <Checkbox
                checked={selectAll}
                onClick={handleSelectAllToggle}
                sx={{
                  "&&": { p: 0, mr: 1 },
                }}
              />
              <Typography
                className="text cursor-pointer"
                sx={{ color: "#414141" }}
              >
                Select All
              </Typography>
            </div>
          </div>
          <div className="p-2">
            <>
              {cityData.data.map((locality) => (
                <Chip
                  key={locality.merchantId}
                  label={locality.merchantName}
                  clickable
                  onClick={() => handleChipClick(locality)}
                  sx={{
                    m: 1,
                    backgroundColor: selectedChips.some(
                      (chip) => chip.merchantId === locality.merchantId
                    )
                      ? "#06B681"
                      : "#FFFFFF",
                    color: selectedChips.some(
                      (chip) => chip.merchantId === locality.merchantId
                    )
                      ? "#FFFFFF"
                      : "#171F21",
                    border: `1px solid ${
                      selectedChips.some(
                        (chip) => chip.merchantId === locality.merchantId
                      )
                        ? "#06B681"
                        : "#171F21"
                    }`,
                  }}
                />
              ))}
            </>
            <div className="flex justify-end">
              <Button
                variant="contained"
                onClick={updateServiceability}
                sx={{
                  bgcolor: "#171F21",
                  textTransform: "none",
                  px: 4,
                  py: 1,
                  borderRadius: "50px",
                  color: "white",
                  "&:hover": { bgcolor: "#171F2180" },
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </Card>
        <Snackbar
          open={showSuccessSnackbar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {SnackbarMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={showErrorSnackbar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {SnackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default MerchantCityCard;
