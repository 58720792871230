import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import _ from "lodash";
import {
  Button,
  IconButton,
  TextField,
  Snackbar,
  Alert,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { SosTicketingApi } from "../../API/SosTicketing";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

interface UpdateTicketModalProps {
  ticketId: number;
  ticketType: number;
  modalType: string;
  disabled: boolean;
  subTypeListId: number;
}
export default function UpdateTicketModal({
  ticketId,
  ticketType,
  modalType,
  disabled,
  subTypeListId,
}: UpdateTicketModalProps) {
  const [open, setOpen] = useState(false);
  const [comments, setComments] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [selectedTicketTypeId, setSelectedTicketTypeId] = useState(ticketType);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setComments("");
  };
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [subtypeData, setSubtypeData] = useState<any>([]);

  useEffect(() => {
    const api = new SosTicketingApi();
    api
      .subTypeList()
      .then((e) => {
        setSubtypeData(e.data.data);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, []);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  const handleErrorSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setComments(value);
    if (value.length > 255) {
      setError("Remarks cannot exceed 255 characters");
    } else {
      setError("");
    }
  };

  const handleTicketTypeChange = (typeId: number) => {
    setSelectedTicketTypeId(typeId);
    handlePopoverClose();
    if (selectedTicketTypeId === ticketType) {
      handleOpen();
    }
  };

  const debouncedApiCall = useCallback(
    _.debounce((apiCall: () => void) => apiCall(), 5000, {
      leading: true,
      trailing: true,
    }),
    []
  );

  const handleCloseTicket = () => {
    debouncedApiCall(() => {
      SosTicketingApi.api
        .closeTicket(ticketId, comments)
        .then((response) => {
          console.log(response);
          setShowSuccessSnackbar(true);
          setSnackbarMessage("Ticket has been closed");
        })
        .catch((error) => {
          console.error(error);
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Failed to close ticket";
          setShowErrorSnackbar(true);
          setSnackbarMessage(errorMessage);
        });
    });
  };

  const handleCancelTicket = () => {
    debouncedApiCall(() => {
      SosTicketingApi.api
        .cancelTicket(ticketId, comments)
        .then((response) => {
          console.log(response);
          setShowSuccessSnackbar(true);
          setSnackbarMessage("Ticket has been cancelled");
        })
        .catch((error) => {
          console.error(error);
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Failed to cancel ticket";
          setShowErrorSnackbar(true);
          setSnackbarMessage(errorMessage);
        });
    });
  };

  const handleAddComments = () => {
    debouncedApiCall(() => {
      SosTicketingApi.api
        .addTicketComment(ticketId, comments)
        .then((response) => {
          console.log(response);
          setShowSuccessSnackbar(true);
          setSnackbarMessage("Comments have been added");
        })
        .catch((error) => {
          console.error(error);
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Failed to add comments";
          setShowErrorSnackbar(true);
          setSnackbarMessage(errorMessage);
        });
    });
  };

  const handleUpdateType = () => {
    debouncedApiCall(() => {
      SosTicketingApi.api
        .updateTicketType(ticketId, selectedTicketTypeId, comments)
        .then((response) => {
          console.log(response);
          setShowSuccessSnackbar(true);
          setSnackbarMessage("Ticket Type has been updated");
        })
        .catch((error) => {
          console.error(error);
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Failed to update ticket type";
          setShowErrorSnackbar(true);
          setSnackbarMessage(errorMessage);
        });
    });
  };

  return (
    <div>
      {modalType === "Close Ticket" ? (
        <div
          className="bg-[#F94932] px-4 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
          onClick={handleOpen}
        >
          <span className="text-[#FFFFFF] text-sm font-medium font-inter">
            Close Ticket
          </span>
        </div>
      ) : modalType === "Cancel Order/Ticket" ? (
        <div
          className="bg-[#D2042D] px-2 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
          onClick={handleOpen}
        >
          <span className="text-[#FFFFFF] text-[13px] font-medium font-inter">
            Cancel Ticket/Order
          </span>
        </div>
      ) : modalType === "Update Ticket Type" ? (
        <>
          <InputLabel
            shrink={true}
            style={{
              color: disabled === true ? "#00000060" : "#000000",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Sub issue Name
          </InputLabel>
          <div
            className={`border-b-[1px] w-full border-dotted border-[#000000] mr-2 ${
              disabled === true ? "border-[#00000060]" : "border-[#000000]"
            }`}
            onClick={disabled === true ? undefined : handlePopoverOpen}
          >
            <div className="flex justify-between">
              <div
                className={`font-sans font-normal pb-[0.100rem] min-w-[300px] ${
                  disabled === true
                    ? "cursor-default text-[#00000060]"
                    : "cursor-pointer text-[#000000]"
                }`}
              >
                {subtypeData.find(
                  (ticket: any) => ticket.id === selectedTicketTypeId
                )?.ticketTypeLabel || "--"}
              </div>
              <ArrowDropDownIcon
                sx={{
                  color: disabled === true ? "#00000060" : "#000000",
                }}
              />
            </div>
          </div>
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <List>
              {subtypeData
                ?.filter((ticket: any) => ticket.ticketType === subTypeListId)
                .map((subType: any) => (
                  <ListItem
                    key={subType.id}
                    disablePadding
                    className="hover:bg-[#29CE57] cursor-pointer hover:text-[#fff] text-black"
                    onClick={() => handleTicketTypeChange(subType.id)}
                  >
                    <ListItemButton>
                      <ListItemText primary={subType.ticketTypeLabel} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Popover>
        </>
      ) : (
        <Button
          sx={{ textTransform: "none", color: "#06B681" }}
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Add Comments
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-between items-between mb-4">
              <div className="font-medium font-inter">{modalType}</div>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <FormControl required fullWidth>
              <TextField
                multiline
                sx={{ pb: 2 }}
                rows={5}
                required
                placeholder="Remarks"
                fullWidth
                onChange={handleTextFieldChange}
                inputProps={{ maxLength: 256 }}
                error={!!error}
                helperText={error}
                value={comments}
              />
            </FormControl>
            <div className="flex justify-center mt-4 items-center pb-4">
              <Button
                sx={{ textTransform: "none", color: "#171F21", py: 1, px: 8 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              {modalType === "Close Ticket" ? (
                <Button
                  variant="contained"
                  onClick={handleCloseTicket}
                  sx={{
                    ml: 3,
                    color: "white",
                    bgcolor: "#171F21",
                    borderRadius: "50px",
                    textTransform: "none",
                    py: 1,
                    px: 8,
                    "&:hover": { bgcolor: "#171F2180" },
                  }}
                  disabled={!comments.replace(/\s/g, "")}
                >
                  Close Ticket
                </Button>
              ) : modalType === "Cancel Order/Ticket" ? (
                <Button
                  variant="contained"
                  onClick={handleCancelTicket}
                  sx={{
                    ml: 3,
                    color: "white",
                    bgcolor: "#171F21",
                    borderRadius: "50px",
                    textTransform: "none",
                    py: 1,
                    px: 8,
                    "&:hover": { bgcolor: "#171F2180" },
                  }}
                  disabled={!comments.replace(/\s/g, "")}
                >
                  Cancel Ticket/Order
                </Button>
              ) : modalType === "Update Ticket Type" ? (
                <Button
                  variant="contained"
                  onClick={handleUpdateType}
                  sx={{
                    ml: 3,
                    color: "white",
                    bgcolor: "#171F21",
                    borderRadius: "50px",
                    textTransform: "none",
                    py: 1,
                    px: 8,
                    "&:hover": { bgcolor: "#171F2180" },
                  }}
                  disabled={!comments.replace(/\s/g, "")}
                >
                  Update Ticket Type
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleAddComments}
                  sx={{
                    ml: 3,
                    color: "white",
                    bgcolor: "#171F21",
                    borderRadius: "50px",
                    textTransform: "none",
                    py: 1,
                    px: 8,
                    "&:hover": { bgcolor: "#171F2180" },
                  }}
                  disabled={!comments.replace(/\s/g, "")}
                >
                  Add Comments
                </Button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert
          onClose={handleErrorSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
