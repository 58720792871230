import React from "react";
import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import WalletTransactionTable from "../../Layouts/WalletTransactionTable";
import DashboardCard from "../../Layouts/DashboardCard";
import Header from "../../Layouts/AppHeader/Header";
import { Typography, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AddMoneyCard from "../../Layouts/AddMoneyCard";

function WalletTransaction() {
  return (
    <>
      <div className="flex">
        <div className="w-1/6 mr-3">
          <SideBarNavigation />
        </div>
        <div className="w-5/6">
          <div className="pb-4 pl-2 pr-4 mt-6">
            <Header merchantLogoUrl="" />

            <div className="font-medium	text-[32px] text-[#1E1E1E] text-left font-inter mt-8">
              Wallet Transactions
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mt: 4 }}
              >
                <Typography
                  color="#ACACAC"
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                >
                  <a href="/home">Dashboard</a>
                </Typography>
                <Typography
                  color="#717171"
                  sx={{ fontSize: "12px", fontWeight: "700" }}
                >
                  Wallet Transactions
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className="flex mb-1 justify-between pr-5">
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Wallet Balance"
                backgroundColor="#00CB6033"
                data={1000}
                comparison="50"
                imgSrc="/assets/icons/bagIcon.svg"
                increment={true}
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Amount Collected"
                backgroundColor="#00CB6033"
                data={1000}
                comparison="50"
                imgSrc="/assets/icons/bagIcon.svg"
                increment={false}
                loading={false}
              />
            </div>
            <div className="w-full mr-2">
              <DashboardCard
                heading="Total Amount Debited"
                backgroundColor="#00CB6033"
                data={1000}
                comparison="50"
                imgSrc="/assets/icons/bagIcon.svg"
                increment={false}
                loading={false}
              />
            </div>
            <div className="w-full">
              <AddMoneyCard />
            </div>
          </div>

          <div
            className="ml-2 w-[98%]"
            style={{
              boxShadow: "0px 7px 29px 0px #64646F33",
              borderRadius: "8px",
            }}
          >
            <WalletTransactionTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default WalletTransaction;
