import axios, { AxiosInstance, AxiosResponse } from "axios";
import { NominationListResponse } from "../@Types/DRO/NominationListResponse";
import { NominationUpdateBody } from "../@Types/DTO/NominationUpdateBody";

export class RiderNominationApi {
  public static api = new RiderNominationApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "http://34.93.87.109:9000",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  getToken() {
    return localStorage.getItem("access_token");
  }

  nominationListApi(page: number,searchParam: string ): Promise<NominationListResponse> {
    const headers = {
      Authorization: `Bearer ${this.getToken()}`,
      page: page,
      pageSize: 20,
    };
    let apiUrl = "/oms/api/v1/consumer/get/nominationList";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    return this.instance
      .get<NominationListResponse>(apiUrl, {
        headers: headers,
      })
      .then((e) => {
        return e.data;
      });
  }
  nominationUpdateApi(body: NominationUpdateBody): Promise<AxiosResponse<any>> {
    const config = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    };

    return this.instance.post<any>(
      "/oms/api/v1/consumer/nomination/status/update",
      body,
      config
    );
  }
}
