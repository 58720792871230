import React, { useEffect, useState, useCallback } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  InputAdornment,
  TextField,
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  InputBase,
} from "@mui/material";
import "./TableCss.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OrderExpandedRowDetails from "../OrderExpandedRowDetails/OrderExpandedRowDetails";
// import "animate.css/animate.min.css";
import Chip from "@mui/material/Chip";
import { OrderOnDemandApi } from "../../../API/ZyppIt";
import SearchIcon from "@mui/icons-material/Search";
import StatusPopover from "../../Modals/OrderStatusPopover";
import { OrderListApi } from "../../../API/FrontPageTable";
import { debounce } from "lodash";

export default function TableImplementation({
  startDate,
  endDate,
  navigateSearch,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
  navigateSearch: string | null;
}) {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const handleExpandToggle = (id: string) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter((id) => id !== id)
        : [...prevExpandedRows, id]
    );
  };
  const [merchantData, setMerchantData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | undefined>(
    navigateSearch ?? undefined
  );
  // const [paramSearchVal, setParamSearchVal] = useState<string | undefined>(
  //   undefined
  // );
  const [selectedMerchant, setSelectedMerchant] = useState<any>(-1);
  const [ordersData, setOrdersData] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<string>("11");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const pageRange = 5;
  const getStatusChipData = (
    orderStatus: number
  ): { text: string; color: string; bgcolor: string } => {
    switch (orderStatus) {
      case -3:
        return {
          text: "System Cancel",
          color: "#FF725E",
          bgcolor: "#FF725E33",
        };
      case -2:
        return { text: "Rider Cancel", color: "#FF725E", bgcolor: "#FF725E33" };
      case -1:
        return {
          text: "Source cancel",
          color: "#FF725E",
          bgcolor: "#FF725E33",
        };
      case 0:
        return { text: "Unassigned", color: "#FFB846", bgcolor: "#FFF6E8" };
      case 1:
        return { text: "Assigned", color: "#FFB846", bgcolor: "#FFF6E8" };
      case 2:
        return {
          text: "Going for Pickup",
          color: "#FFB846",
          bgcolor: "#FFF6E8",
        };
      case 3:
        return { text: "Reached Pickup", color: "#FFB846", bgcolor: "#FFF6E8" };
      case 4:
        return { text: "Order Picked", color: "#FFB846", bgcolor: "#FFF6E8" };
      case 5:
        return { text: "Reached Gate", color: "#FFB846", bgcolor: "#FFF6E8" };
      case 6:
        return {
          text: "Order Delivered",
          color: "#00CB60",
          bgcolor: "#00CB6026",
        };
      case 7:
        return {
          text: "Mark Undelivered",
          color: "#FF725E",
          bgcolor: "#FF725E33",
        };
      case 8:
        return {
          text: "Back to Hub",
          color: "#FF725E",
          bgcolor: "#FF725E33",
        };
      case 9:
        return {
          text: "RTO Reached Hub",
          color: "#FF725E",
          bgcolor: "#FF725E33",
        };
      case 10:
        return {
          text: "Order Undelivered",
          color: "#FF725E",
          bgcolor: "#FF725E33",
        };
      default:
        return { text: "", color: "#171F21", bgcolor: "#171F2126" }; // Default values
    }
  };
  useEffect(() => {
    if (navigateSearch != null) {
      setSearchVal(navigateSearch);
    }
  }, [navigateSearch]);
  // console.log("ordersData", ordersData);
  const fetchOrderData = useCallback(
    debounce((searchVal: any) => {
      const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
      setLoading(true);
      if (endDate) {
        OrderListApi.api
          .frontPageOrderDetailsList(
            selectedMerchant,
            startDate,
            endDate,
            selectedStatus,
            page + 1,
            apiUrl
          )
          .then((response) => {
            setLoading(false);
            if (
              response.data &&
              Array.isArray(response.data.data.orderDetailsList)
            ) {
              setOrdersData(response.data.data.orderDetailsList);
              setTotalCount(response.data.data.listCount);
            } else {
              setShowSnackbar(true);
              setOrdersData([]);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error("API call error: ", error);
            setShowSnackbar(true);
            setOrdersData([]);
          });
      }
    }, 500),
    [selectedMerchant, startDate, endDate, selectedStatus, page]
  );

  useEffect(() => {
    fetchOrderData(searchVal);
  }, [searchVal, page, selectedStatus, selectedMerchant, endDate]);
  useEffect(() => {
    const api = new OrderOnDemandApi();
    api
      .merchantList()
      .then((e) => {
        setMerchantData(e.data.data);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, []);
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    if (pageCount === 1) {
      setPage(0);
    } else {
      const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
      setPage(newPageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const isTimeExceeded = (assignedTime: string) => {
    const currentTime = new Date();
    const assignedTimeDate = new Date(assignedTime);
    const timeDifference =
      (currentTime.getTime() - assignedTimeDate.getTime()) / 1000;
    return timeDifference > 240;
  };
  useEffect(() => {
    setPage(0);
  }, [searchVal, selectedStatus, selectedMerchant]);

  return (
    <>
      {/* {loading ? (
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          ) : (
            <> */}
      <TableContainer className="">
        <div className="flex justify-between mx-4 mt-4 mb-4">
          <div className="flex">
            <div className="border-2 rounded-2xl px-2 mt-1">
              <label className="text-[#727597] text-sm font-normal font-inter">
                Status :{" "}
              </label>
              <select
                name="orderStatus"
                id="orderStatus"
                className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[150px]"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="11">All</option>
                <option value="0">Unassigned</option>
                <option value="1">Assigned</option>
                <option value="2">Going For Pickup</option>
                <option value="3">Reached Pickup</option>
                <option value="4">Order Picked</option>
                <option value="5">Reached Gate</option>
                <option value="6">Order Delivered</option>
                {/* <option value="7">Mark Undelivered</option> */}
                <option value="8">Back to Hub</option>
                <option value="9">RTO Reached Hub</option>
                <option value="10">Order Undelivered</option>
              </select>
            </div>
            <div className="border-2 rounded-2xl px-2 ml-2 mt-1">
              <label className="text-[#727597] items-center text-sm font-normal font-inter">
                Merchant :
              </label>
              <select
                name="locality"
                id="locality"
                className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[150px]"
                value={selectedMerchant}
                onChange={(e) => {
                  setSelectedMerchant(e.target.value);
                }}
              >
                <option value={-1}>All</option>
                {merchantData?.map((merchant: any) => (
                  <option key={merchant.merchantId} value={merchant.merchantId}>
                    {merchant.merchantName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="border-2 rounded-2xl px-4 text-xs">
            <InputBase
              placeholder="Search Order Id, Ref. Id"
              className="flex-grow text-xs"
              value={searchVal}
              onChange={(search) => setSearchVal(search.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
            <SearchIcon
              style={{ color: "#727597" }}
              className=" cursor-pointer"
            />
          </div>
        </div>
        <hr />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                #
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Order Id
              </TableCell>

              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                Order Ref Number
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Order Date & Time
              </TableCell>
              {/* <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Rider ID
              </TableCell> */}
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Rider Id & Name
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                City
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Merchant Name
              </TableCell>
              <TableCell
                style={{
                  color: "#ACACAC",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                Status
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersData
              // ?.filter(
              //   (row: any) =>
              //     selectedStatus === "8" ||
              //     row.orderStatus.toString() === selectedStatus
              // )
              // ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              ?.map((row: any, index: any) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={index}
                    className={
                      expandedRows.includes(row?.orderId) ? "expanded-row" : ""
                    }
                  >
                    <TableCell>
                      {row.orderStatus === 0 &&
                      isTimeExceeded(row.assignedTime) ? (
                        <div
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "6px",
                              left: "10px",
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              backgroundColor: "#F94932",
                              animation: "ripple 1s infinite",
                            }}
                          >
                            <div className="solid-dot-red" />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "#06B681",
                            margin: "auto",
                          }}
                        ></div>
                      )}
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        fontWeight: "400",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        wordBreak: "break-word",
                        textOverflow: "ellipsis",
                        maxWidth: "140px",
                      }}
                    >
                      {row.orderReferenceNumber}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {row.orderCreationTime}
                    </TableCell>
                    {/* <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {row?.riderUserId}
                    </TableCell> */}
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {row.riderUserId && row.riderName
                        ? `${row.riderUserId}, ${row.riderName}`
                        : row.riderUserId
                        ? `${row.riderUserId},--`
                        : row.riderName
                        ? `--,${row.riderName}`
                        : "--"}
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {row?.city}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      <Chip
                        label={`${row.merchantName}`}
                        size="small"
                        sx={{ p: 1 }}
                        style={{
                          backgroundColor: "#E9E9E966",
                          color: "#414141",

                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {(row.orderStatus === 1 ||
                        row.orderStatus === 2 ||
                        row.orderStatus === 3 ||
                        row.orderStatus === 4 ||
                        row.orderStatus === 5 ||
                        row.orderStatus === 7 ||
                        row.orderStatus === 8 ||
                        row.orderStatus === 9) && (
                        <StatusPopover
                          orderStatus={row?.orderStatus}
                          orderNumber={row?.orderId}
                        />
                      )}
                      {row.orderStatus === 0 ||
                      row.orderStatus === -1 ||
                      row.orderStatus === -2 ||
                      row.orderStatus === -3 ||
                      row.orderStatus === 6 ||
                      row.orderStatus === 10 ? (
                        <Chip
                          label={getStatusChipData(row.orderStatus).text}
                          size="small"
                          sx={{ p: 1, width: "100%" }}
                          style={{
                            backgroundColor: getStatusChipData(row.orderStatus)
                              .bgcolor,
                            color: getStatusChipData(row.orderStatus).color,
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <button onClick={() => handleExpandToggle(row?.orderId)}>
                        {expandedRows.includes(row?.orderId) ? (
                          <img
                            src="/assets/icons/dropupIcon.svg"
                            alt=""
                            className="mt-2"
                          />
                        ) : (
                          <img
                            src="/assets/icons/dropdownIcon.svg"
                            alt=""
                            className="mt-2"
                          />
                        )}
                      </button>
                    </TableCell>
                  </TableRow>
                  {expandedRows.includes(row?.orderId) && (
                    <TableRow className="expanded-content">
                      <TableCell colSpan={8}>
                        {/* Additional content when row is expanded */}
                        {/* You can render details or nested components here */}
                        <div className="w-full">
                          <OrderExpandedRowDetails
                            ordersNo={row?.orderId}
                            riderUserId={row?.riderUserId}
                            merchantName={row?.merchantName}
                            orderReferenceNumber={row?.orderReferenceNumber}
                            riderName={row?.riderName}
                            orderStatus={row?.orderStatus}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pt-4 pb-2 pr-5">
        <div
          className="pagination"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="flex">
            <img
              src="/assets/icons/leftArrow.svg"
              alt=""
              onClick={() => handlePageChange(page - 1)}
            />
            {renderPageNumbers()}
            <img
              src="/assets/icons/rightArrow.svg"
              alt=""
              onClick={() => handlePageChange(page + 1)}
              className="ml-1 mr-6"
            />
          </div>
        </div>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
          No data found
        </Alert>
      </Snackbar>
      {/* </>
          )} */}
    </>
  );
}
