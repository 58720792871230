import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "60vh",
};

const libraries: "places"[] = ["places"];

interface Event {
  statusName: string;
  createdAt: string;
  riderId?: number;
  riderName?: string;
  latitude?: number;
  longitude?: number;
  createdByName?: string;
  createdBy?: number;
  remarks?: string;
}

interface TrackOrderModalMapProps {
  sourceLat: number;
  sourceLong: number;
  destinationLat: number;
  destinationLong: number;
  data: {
    events: Event[];
  };
  mapProps?: object;
  children?: React.ReactNode;
}

function TrackOrderModalMap({
  sourceLat,
  sourceLong,
  destinationLat,
  destinationLong,
  data,
  mapProps = {},
  children = <></>,
}: TrackOrderModalMapProps) {
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [center, setCenter] = useState({ lat: sourceLat, lng: sourceLong });

  useEffect(() => {
    const scriptLoaded = window.google && window.google.maps;
    setIsApiLoaded(!!scriptLoaded);
  }, []);

  useEffect(() => {
    if (!selectedEvent) {
      setCenter((prevCenter) => prevCenter); // Preserve the current center when InfoWindow is closed
    }
  }, [selectedEvent]);

  const handleMarkerClick = useCallback((event: Event) => {
    setSelectedEvent(event);
    if (event.latitude && event.longitude) {
      setCenter({ lat: event.latitude, lng: event.longitude });
    }
  }, []);

  const path = [
    { lat: sourceLat, lng: sourceLong },
    ...data.events
      .filter((event) => event.latitude && event.longitude)
      .map((event) => ({ lat: event.latitude!, lng: event.longitude! })),
    { lat: destinationLat, lng: destinationLong },
  ];

  const mapStyles = [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [{ saturation: -100 }, { lightness: 50 }],
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#c9c9c9" }, { lightness: 50 }],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{ saturation: -100 }, { lightness: 50 }],
    },
  ];

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg"
      libraries={libraries}
      onLoad={() => setIsApiLoaded(true)}
    >
      {isApiLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          options={{ styles: mapStyles }}
          {...mapProps}
        >
          <Marker
            position={{
              lat: sourceLat,
              lng: sourceLong,
            }}
            icon={{ url: "/assets/icons/yellow-map-icon.svg" }}
          />
          {data?.events.map((event, index) =>
            event.latitude && event.longitude ? (
              <Marker
                key={index}
                position={{
                  lat: event.latitude,
                  lng: event.longitude,
                }}
                icon={{ url: "/assets/icons/scooter-icon.svg" }}
                onClick={() => handleMarkerClick(event)}
              />
            ) : null
          )}
          <Marker
            position={{
              lat: destinationLat,
              lng: destinationLong,
            }}
            icon={{ url: "/assets/icons/green-map-icon.svg" }}
          />
          {selectedEvent && (
            <InfoWindow
              position={{
                lat: selectedEvent.latitude!,
                lng: selectedEvent.longitude!,
              }}
              onCloseClick={() => setSelectedEvent(null)}
            >
              <div>
                <h3>{selectedEvent.statusName}</h3>
                <p>{selectedEvent.createdAt}</p>
              </div>
            </InfoWindow>
          )}
          <Polyline
            path={path}
            options={{
              strokeColor: "#06B681",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }}
          />
        </GoogleMap>
      ) : (
        <div>Loading map...</div>
      )}
      {children}
    </LoadScript>
  );
}

export default TrackOrderModalMap;
