import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

export default function HomeTrackOrders() {
  return (
    <>
      <Box
        sx={{
          "& > :not(style)": {
            my: 1,
            width: 499,
            height: 450,
          },
        }}
      >
        <Paper
          elevation={6}
          sx={{ boxShadow: "0px 7px 29px 0px #64646F33", borderRadius: "8px" }}
        >
          <div className="p-4 font-inter font-medium text-lg text-[#000000]">
            <p className="text-left mb-4">Track Orders</p>
            {/* <GoogleMapApi /> */}
          </div>
        </Paper>
      </Box>
    </>
  );
}
