import SideBarNavigation from "../../Layouts/Sidebar/Sidebar";
import Header from "../../Layouts/AppHeader/Header";
import { Typography, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import MerchantCODTable from "../../Layouts/MerchantCODTable";

function MerchantCOD() {
  return (
    <>
      <div className="flex">
        <div className="w-1/6 mr-3 ml-4">
          <SideBarNavigation />
        </div>
        <div className="w-5/6">
          <div className="pb-4 pr-4 pl-2 mt-6">
            <Header merchantLogoUrl="" />
            <div className="flex justify-between mt-8">
              <div className="font-medium text-left text-[32px] text-[#1E1E1E] font-inter">
                Merchant COD
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  sx={{ mt: 4 }}
                >
                  <Typography
                    color="#717171"
                    sx={{ fontSize: "12px", fontWeight: "700" }}
                  >
                    Merchant COD
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div
            className="ml-2 w-[98%] pb-4"
            style={{
              boxShadow: "0px 7px 29px 0px #64646F33",
              borderRadius: "8px",
            }}
          >
            <MerchantCODTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default MerchantCOD;
