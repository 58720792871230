import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { BreachedOrdersApi } from "../../../API/BreachedOrders";
import { useNavigate } from "react-router-dom";

export default function BreachedOrdersTable() {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>("0");
  const [breachedOrdersData, setBreachedOrdersData] = useState<any>([]);

  // Define status labels
  const statusLabels: { [key: string]: string } = {
    "0": "Unassigned to Assigned",
    "1": "Assigned to Going for Pickup",
    "2": "Going For Pickup to Reached Hub",
    "3": "Reached Hub to Order Picked",
    "4": "Order Picked to Reached Destination",
    "5": "Reached Destination to Order Delivered",
  };

  useEffect(() => {
    const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
    BreachedOrdersApi.api
      .breachedOrdersList(selectedStatus, apiUrl)
      .then((e) => {
        setBreachedOrdersData(e.data.data.orderDetailsList);
        if (e.data && Array.isArray(e.data.data.orderDetailsList)) {
          setBreachedOrdersData(e.data.data.orderDetailsList);
        } else {
          setShowSnackbar(true);
          setBreachedOrdersData([]);
        }
      })
      .catch((error) => {
        setShowSnackbar(true);
        console.error("API call error: ", error);
      });
  }, [selectedStatus, searchVal]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  return (
    <>
      <>
        <TableContainer className="">
          <div className="flex items-center justify-between py-4 px-3 border-slate-200 border-solid">
            <div className="border-2 rounded-2xl px-2 mt-1 mr-2">
              <label className="text-[#727597] text-sm font-normal font-inter">
                Status :{" "}
              </label>
              <select
                name="orderStatus"
                id="orderStatus"
                className="text-[#727597] text-sm font-normal font-inter px-1 py-1 min-w-[270px]"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="0">Unassigned to Assigned</option>
                <option value="1">Assigned to Going for Pickup</option>
                <option value="2">Going For Pickup to Reached Hub</option>
                <option value="3">Reached Hub to Order Picked</option>
                <option value="4">Order Picked to Reached Destination</option>
                <option value="5">
                  Reached Destination to Order Delivered
                </option>
              </select>
            </div>
            <div className="border-2 rounded-2xl px-4">
              <InputBase
                type="number"
                placeholder="Search by Order Id or Ref."
                className="flex-grow"
                inputProps={{ "aria-label": "search" }}
                value={searchVal}
                onChange={(search) => setSearchVal(search.target.value)}
              />
              <SearchIcon
                style={{ color: "#727597" }}
                className=" cursor-pointer ml-4"
              />
            </div>
          </div>
          <hr />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Order Id
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Order Ref.
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Current Status
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  City
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Start Time
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Breach Time
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Merchant
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {breachedOrdersData?.map((row: any, index: any) => (
                <React.Fragment key={index}>
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.orderReferenceNumber}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <Chip
                        label={statusLabels[row.status] || "Unknown Status"}
                        size="small"
                        style={{
                          backgroundColor: "#E8FFF8",
                          color: "#06B681",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.city}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.eventTime}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.breachTime} min
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <Chip
                        label={`${row.merchantName}`}
                        size="small"
                        style={{
                          backgroundColor: "#2D0F8A1F",
                          color: "#2D0F8A",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <RemoveRedEyeOutlinedIcon
                        onClick={() =>
                          navigate(`/order-management?search=${row.orderId}`)
                        }
                        fontSize="medium"
                        className="cursor-pointer"
                        sx={{ color: "#171F21" }}
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
    </>
  );
}
