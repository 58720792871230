import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, IconButton, TextField, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OrderStatusApi } from "../../API/OrderStatus";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

interface ReassignOrderModalProps {
  orderId: number;
  riderId: number;
}

export default function ReassignOrderModal({
  orderId,
  riderId,
}: ReassignOrderModalProps) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<number | undefined>();
  const [comments, setComments] = useState<string>("");
  const [inputRiderId, setInputRiderId] = useState<number | string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [inputError, setInputError] = useState<string>("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };

  const handleComments = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComments(e.target.value);
  };

  const handleRiderIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!inputValue) {
      setInputRiderId("");
      setInputError("");
    }
    const numericInput = inputValue.replace(/\D/g, "");

    setInputRiderId(numericInput);
    if (inputValue !== numericInput) {
      setInputError("Please enter only numbers");
    } else {
      setInputError("");
    }
  };

  const handleReassignOrder = () => {
    if (isSubmitting) {
      console.error("Invalid Rider ID");
      return;
    }

    setIsSubmitting(true);
    const remark = comments;
    const riderIdToUse = Number(inputRiderId);
    OrderStatusApi.api
      .reAssignOrder(orderId, riderIdToUse, remark)
      .then((response) => {
        console.log(response);
        setShowSuccessSnackbar(true);
        setSnackbarMessage("Order reassigned successfully");
        handleClose();
      })
      .catch((error) => {
        // console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Failed to reassign order";
        setShowErrorSnackbar(true);
        setSnackbarMessage(errorMessage);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <div
        className="bg-[#FFB846] px-4 py-2 rounded-3xl flex mt-4 cursor-pointer justify-center"
        onClick={handleOpen}
      >
        <span className="text-[#FFFFFF] text-sm font-medium font-inter">
          Re-assign
        </span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-end mb-4">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="px-6">
              <TextField
                id="modal-modal-description"
                sx={{ pb: 2 }}
                className="text-[#414141] text-xl"
                placeholder="User Id"
                value={inputRiderId}
                onChange={handleRiderIdChange}
                error={!!inputError}
                helperText={inputError}
              >
                Enter User ID
              </TextField>
              <div className="py-3">
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={5}
                  value={comments}
                  onChange={handleComments}
                  placeholder="Remarks"
                  fullWidth
                />
              </div>
            </div>
            <div className="flex justify-center mt-4 items-center pb-4">
              <Button
                sx={{ textTransform: "none", color: "#171F21", py: 1, px: 8 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleReassignOrder}
                disabled={isSubmitting || !inputRiderId || !comments}
                sx={{
                  ml: 3,
                  color: "white",
                  bgcolor: "#171F21",
                  borderRadius: "50px",
                  textTransform: "none",
                  py: 1,
                  px: 8,
                  "&:hover": { bgcolor: "#171F2180" },
                }}
              >
                {isSubmitting ? "Reassigning..." : "Reassign"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
