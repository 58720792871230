import axios, { AxiosInstance, AxiosResponse } from "axios";
import { RetryOrderResponse } from "../@Types/DRO/RetryOrderResponse";
import {
  CancelOrderResponse,
  NearbyDriverListResponse,
  OrderResponse,
} from "../@Types/DRO/CancelOrderResponse";

export class ONDCApi {
  public static api = new ONDCApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({});
  }
  getToken() {
    return localStorage.getItem("token");
  }
  cancelOrderONDC(
    orderReference: string,
    cancellation_reason_id: string,
    cancellation_reason: string | undefined
  ): Promise<AxiosResponse<CancelOrderResponse>> {
    let order_ids = [`${orderReference}`];
    return this.instance
      .post<any>(
        `https://ondc.zypp.app/internal/cancel_orders`,
        {
          order_ids,
          cancellation_reason_id,
          cancellation_reason,
        },
        {
          headers: {
            "Content-type": "application/json",
            "X-Internal-Authorization": "ondc-skip-auth",
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}
