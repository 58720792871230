import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import TrackOrderModalMap from "../Layouts/Maps/TrackOrderModalMap";
import TrackOrderTimeline from "../Layouts/TrackOrderTimeline";
import { OrderStatusApi } from "../../API/OrderStatus";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 960,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

export default function TrackOrderModal({
  sourceLat,
  sourceLong,
  destinationLat,
  destinationLong,
  riderLat,
  riderLong,
  orderNumber,
}: any) {
  const [open, setOpen] = React.useState(false);
  const [orderStatusTimeStamp, setOrderStatusTimeStamp] = useState<any>([]);

  useEffect(() => {
    if (orderNumber) {
      OrderStatusApi.api
        .trackOrderStatusTimeStamp(orderNumber)
        .then((response) => {
          setOrderStatusTimeStamp(response?.data.data);
        })
        .catch((error) => {
          console.error("Failed to fetch nearby drivers", error);
        });
    }
  }, [orderNumber]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  // console.log(sourceLat, sourceLong, destinationLat, destinationLong, "modal");

  return (
    <div>
      <div
        className="bg-[#00CB60] px-4 py-2 rounded-3xl flex items-center justify-center cursor-pointer"
        onClick={handleOpen}
      >
        <img className="h-4 mr-2" src="/assets/icons/trackIcon.svg" alt="" />
        <span className="text-[#FFFFFF] text-sm font-medium font-inter">
          Track Order
        </span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-between mb-1">
              <Typography className="font-medium font-inter">
                Track Your Orders
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="flex w-full">
              <div className="w-2/5 mr-2">
                <TrackOrderTimeline data={orderStatusTimeStamp} />
              </div>
              <div className="w-3/5">
                <TrackOrderModalMap
                  sourceLat={sourceLat}
                  sourceLong={sourceLong}
                  destinationLat={destinationLat}
                  destinationLong={destinationLong}
                  data={orderStatusTimeStamp}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
