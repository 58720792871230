import React from "react";
import TextField from "@mui/material/TextField";

export default function PaymentExtraDetails() {
  return (
    <>
      <div className="flex">
        <div>
          <TextField
            label="Merchant Name"
            id="standard-size-small"
            defaultValue=""
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>
        <div className="ml-2">
          <TextField
            label="Payment Mode"
            id="standard-size-small"
            defaultValue=""
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>
        <div className="ml-2">
          <TextField
            label="Hub Code"
            id="standard-size-small"
            type="number"
            defaultValue=""
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>
        <div className="ml-2">
          <TextField
            label="Rider Code"
            id="standard-size-small"
            type="number"
            defaultValue=""
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>

        <div className="ml-2">
          <TextField
            label="Rider Name"
            id="standard-size-small"
            defaultValue=""
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>
      </div>
      <div className="flex mt-8">
        <div>
          <TextField
            id="standard-number"
            label="BM Code"
            type="number"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>

        <div className="ml-2">
          <TextField
            id="standard-number"
            label="Payment Status"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>
        <div className="ml-2">
          <TextField
            id="standard-number"
            label="Time"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>

        <div className="ml-2">
          <TextField
            id="standard-number"
            label="Amount Paid"
            type="number"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>

        <div className="ml-2">
          <TextField
            label="Transaction Status"
            id="standard-size-small"
            defaultValue=""
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>
      </div>

      <div className="flex mt-8">
        <div className="">
          <TextField
            label="Remarks"
            id="standard-size-small"
            defaultValue=""
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>

        <div className="ml-2">
          <TextField
            label="Refund"
            id="standard-size-small"
            defaultValue=""
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>
      </div>
    </>
  );
}
