import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RiderNominationApi } from "../../API/RiderNomination";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 2,
};

interface LongMenuProps {
  openFn: (status: number) => void; // Updated type for openFn
  status1: number | undefined;
}

const LongMenu: React.FC<LongMenuProps> = ({ openFn, status1 }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [status, setStatus] = useState<number | undefined>(status1); // State to hold the status

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = (val: number) => {
    openFn(val);
    setStatus(val);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          fontWeight: 400,
          fontSize: "14px",
          color: "#A4A2A2",
          font: "Rubik",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem
          onClick={() => handleClickOpen(1)}
          sx={{
            color: "#00CB60",
            "&:hover": {
              backgroundColor: "#00CB60",
              color: "#fff",
            },
          }}
        >
          <DoneIcon className="mr-3" /> Accept
        </MenuItem>

        <MenuItem
          onClick={() => handleClickOpen(2)}
          sx={{
            color: "#F94932",
            "&:hover": {
              backgroundColor: "#F94932",
              color: "#fff",
            },
          }}
        >
          <ClearIcon className="mr-3" /> Decline
        </MenuItem>

        <MenuItem
          onClick={() => handleClickOpen(3)}
          sx={{
            color: "#F94932",
            "&:hover": {
              backgroundColor: "#F94932",
              color: "#fff",
            },
          }}
        >
          Not Serviceable
        </MenuItem>
      </Menu>
    </div>
  );
};

export default function ActionModal({ rowData }: any) {
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState<number | undefined>();
  const [comments, setComments] = useState<string>("");

  // State to hold the status

  const handleOpen = (status: number) => {
    setOpen(true);
    setStatus(status);
  };
  const handleClose = () => setOpen(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  const handleComments = (e: any) => {
    setComments(e.target.value);
  };
  const getStatusLabel = (status: number | undefined) => {
    if (status === 1) {
      return "Approved";
    } else if (status === 2) {
      return "Rejected";
    } else {
      return "Not Serviceable";
    }
  };
  const getStatusColor = (status: number | undefined) => {
    if (status === 1) {
      return "#00CB60";
    } else {
      return "#F94932";
    }
  };
  const getStatusBgColor = (status: number | undefined) => {
    if (status === 1) {
      return "#00CB6026";
    } else {
      return "#F9493226";
    }
  };
  const handleSubmit = async () => {
    const nominationApi = new RiderNominationApi();
    const responsebody = {
      nominationId: rowData?.nominationId,
      status: status,
      remark: comments,
    };
    // try {
    //   const apiresponse = await nominationApi.nominationUpdateApi(responsebody);
    //   setShowSuccessSnackbar(true);
    //   window.location.reload();
    // } catch (error: any) {
    //   setSnackbarMessage(error.response.data.errorCode);
    //   setShowErrorSnackbar(true);
    // }
    // console.log(responsebody);
  };
  return (
    <div>
      <LongMenu openFn={handleOpen} status1={status} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={style}>
          <div className="p-4">
            <div className="flex justify-end mb-4">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <p style={{ color: "#ACACAC" }}>Status</p>
            <div className="mt-2 mb-4">
              <Chip
                label={getStatusLabel(status)}
                size="small"
                sx={{ py: 1 }}
                style={{
                  backgroundColor: getStatusBgColor(status), // Use a function to get the color based on the status
                  color: getStatusColor(status),
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              />
            </div>
            <div className="mb-5 pt-4">
              <TextField
                label="Remarks"
                id="standard-size-small"
                defaultValue=""
                value={comments}
                required={status === 2 || status === 3}
                size="small"
                InputLabelProps={{
                  style: { color: "#ACACAC" },
                  shrink: true,
                }}
                variant="standard"
                className="w-full"
                onChange={handleComments}
              />
            </div>
            <div
              className="flex justify-between py-3 items-center"
              style={{
                border: "none",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#F94932",
                  color: "#fff",
                  width: "38%",
                  borderRadius: 30,
                  height: 50,
                }}
                autoFocus
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#0079FF",
                  color: "#fff",
                  width: "38%",
                  borderRadius: 30,
                  height: 50,
                }}
                autoFocus
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
