import React, { useState, useEffect } from "react";
import { Paper, Box, CircularProgress } from "@mui/material";
import { RiderSupplyDashboardCardProps } from "../../../@Types/ComponentsInterface/DashboardCardProps";
const extractStrokeColor = (backgroundColor: string) => {
  const rgbColor = backgroundColor.slice(0, -2);
  return rgbColor;
};

const DashboardCardRiderSupply: React.FC<RiderSupplyDashboardCardProps> = ({
  heading,
  backgroundColor,
  data,
  comparison,
  imgSrc,
  increment,
  loading,
  isSelected,
}) => {
  const textColor = increment ? "#00CB60" : "#F94932";
  const strokeColor = extractStrokeColor(backgroundColor);

  return (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
          width: "100%",
          height: "100%",
        },
      }}
    >
      <Paper
        elevation={6}
        sx={{
          boxShadow: isSelected
            ? `0px 7px 29px 0px ${backgroundColor}`
            : "0px 7px 29px 0px #64646F33",
          borderRadius: "8px",
        }}
      >
        <div className="flex items-start">
          <div className="mt-2 ml-3 mb-2">
            <svg
              width="3"
              height="75"
              viewBox="0 0 4 119"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2L1.99999 117"
                stroke={strokeColor}
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="w-full">
            <div className="flex justify-between items-start pt-3 px-2 text-wrap">
              <p className="text-[#727597] font-semibold text-[10px] font-inter text-wrap">
                {heading}
              </p>
              <img
                src={imgSrc}
                alt=""
                className="p-1"
                style={{ backgroundColor: backgroundColor, width: "20px" }}
              />
            </div>
            {loading ? (
              <div className="flex items-center px-2">
                <CircularProgress sx={{ color: strokeColor }} />
              </div>
            ) : (
              <>
                <p className="px-2 text-left font-inter text-2xl font-semibold text-[#1E1E1E]">
                  {data}
                </p>
                {/* <div className="flex items-center px-2 pt-3">
                  {increment ? (
                    <img src="/assets/icons/greenUpArrow.svg" alt="" />
                  ) : (
                    <img src="/assets/icons/redDownArrow.svg" alt="" />
                  )}
                  <p
                    className={`mr-1 ml-1 text-sm font-inter font-medium`}
                    style={{ color: textColor }}
                  >
                    {comparison}%
                  </p>
                  <p className="text-[#727597] font-inter text-xs font-medium">
                    vs Last Month
                  </p>
                </div> */}
              </>
            )}
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default DashboardCardRiderSupply;
