import React, { useState, useEffect } from "react";
import OrderExtraDetails from "../OrderExtraDetails/OrderExtraDetails";
import "animate.css/animate.min.css";
import UpdateOrderModal from "../../Modals/UpdateOrderModal";
import ReassignOrderModal from "../../Modals/ReassignOrderModal";
import { Cancel } from "@mui/icons-material";
import CancelOrderModal from "../../Modals/CancelOrderModal";
import UnassignOrderModal from "../../Modals/UnassignOrderModal";
import TrackOrderModal from "../../Modals/TrackOrderModal";
import NearbyRiderModal from "../../Modals/NearbyRidersModal";
import CallDriverModal from "../../Modals/CallDriverModal";
import { OrderListApi } from "../../../API/FrontPageTable";
import { CircularProgress } from "@mui/material";

export default function OrderExpandedRowDetails({
  ordersNo,
  riderUserId,
  merchantName,
  orderReferenceNumber,
  riderName,
  orderStatus,
}: {
  ordersNo: number;
  riderUserId: number;
  merchantName: string;
  orderReferenceNumber: string;
  riderName: string;
  orderStatus: number;
}) {
  // console.log(
  //   ordersData?.sourceLat,
  //   ordersData?.sourceLong,
  //   ordersData?.destinationLat,
  //   ordersData?.destinationLong,
  //   "extra"
  // );
  const [ordersData, setOrdersData] = useState<any>();
  useEffect(() => {
    OrderListApi.api
      .extraOrderDetailsList(ordersNo)
      .then((response) => {
        setOrdersData(response.data.data);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, [ordersNo]);
  if (!ordersData) {
    return (
      <div className="flex justify-center py-4">
        <CircularProgress sx={{ color: "#00cd5b" }} />
      </div>
    );
  }
  return (
    <>
      <div className="flex">
        <div className="w-5/6 mt-2">
          <OrderExtraDetails
            assignedTime={ordersData?.assignedTime}
            reachTime={ordersData?.reachTime}
            orderId={ordersNo}
            orderCreationTime={ordersData?.orderCreationTime}
            riderUserId={riderUserId}
            riderName={riderName}
            city={ordersData?.city}
            merchantName={merchantName}
            orderStatus={orderStatus}
            deliveryTime={ordersData?.deliveryTime}
            endTime={ordersData?.endTime}
            orderType={ordersData?.orderType}
            expectedDistance={ordersData?.expectedDistance}
            endInterval={ordersData?.endInterval}
            expectedTime={ordersData?.expectedTime}
            weightLimit={ordersData?.weightLimit}
            actualWeight={ordersData?.actualWeight}
            state={ordersData?.state}
            pincode={ordersData?.originCustPostalCode}
            riderMobile={ordersData?.riderMobile}
            riderAmount={ordersData?.riderAmount}
            orderAmount={ordersData?.orderAmount}
            destinationAddress={ordersData?.destinationAddress}
            sourceAddress={ordersData?.sourceAddress}
            paymentMode={ordersData?.paymentMode}
            customerName={ordersData?.customerName}
            customerMobile={ordersData?.customerMobile}
            orderReferenceNumber={orderReferenceNumber}
            orderCode={ordersData?.orderCode}
            sourceLat={ordersData?.sourceLat}
            sourceLong={ordersData?.sourceLong}
            destinationLat={ordersData?.destinationLat}
            destinationLong={ordersData?.destinationLong}
            agentId={ordersData?.agentId}
            agentName={ordersData?.agentName}
          />
        </div>
        <div className="ml-4">
          <TrackOrderModal
            orderNumber={ordersNo}
            sourceLat={ordersData?.sourceLat}
            sourceLong={ordersData?.sourceLong}
            destinationLat={ordersData?.destinationLat}
            destinationLong={ordersData?.destinationLong}
            riderLat={ordersData?.riderLat}
            riderLong={ordersData?.riderLong}
          />
          {orderStatus !== -1 &&
            orderStatus !== -2 &&
            orderStatus !== -3 &&
            orderStatus !== 6 &&
            orderStatus !== 10 && (
              <>
                <ReassignOrderModal orderId={ordersNo} riderId={riderUserId} />
                <UnassignOrderModal orderNumber={ordersNo} />
                <CancelOrderModal
                  orderNumber={ordersNo}
                  merchantName={merchantName}
                  orderReference={orderReferenceNumber}
                />
                <NearbyRiderModal
                  orderNumber={ordersNo}
                  sourceLat={ordersData?.sourceLat}
                  sourceLong={ordersData?.sourceLong}
                />
                {riderUserId && (
                  <CallDriverModal
                    orderNo={ordersNo}
                    userId={riderUserId}
                    contactType={3}
                    modalButton="orderManagementRow"
                  />
                )}

                <UpdateOrderModal
                  orderNumber={ordersNo}
                  sourceLat={ordersData?.sourceLat}
                  sourceLng={ordersData?.sourceLong}
                  destinationLat={ordersData?.destinationLat}
                  destinationLng={ordersData?.destinationLong}
                />
              </>
            )}
        </div>
      </div>
    </>
  );
}
