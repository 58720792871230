import axios, { AxiosInstance, AxiosResponse } from "axios";
import { RetryOrderResponse } from "../@Types/DRO/RetryOrderResponse";
import { OrderResponse } from "../@Types/DRO/CancelOrderResponse";
import {
  CityResponseProps,
  LocalityResponseProps,
  OngoingOrderResponseProps,
  OnlineUserResponseProps,
  OrderAndBroadcastResponseProps,
  OrderCardResponseProps,
  OrderTimeStampResponseProps,
} from "../@Types/DRO/OrderSummaryResponse";

export class OrderSummaryApi {
  public static api = new OrderSummaryApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://data.bcykal.com/tracker/ondemand",
      // headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  // getToken() {
  //   return localStorage.getItem("access_token");
  // }
  OngoingOrderListApi(
    merchant_id: number,
    start_date: string | undefined,
    end_date: string | undefined,
    locality_id: string[],
    page_number: number,
    searchParam: string,
    page_size: number = 20
  ): Promise<OngoingOrderResponseProps> {
    let apiUrl = "/ongoing/order/report";
    const daysParams =
      locality_id && locality_id.length > 0
        ? locality_id.map((loc) => `locality_id=${loc}`).join("&")
        : "";
    if (searchParam) {
      apiUrl += `${searchParam}&${daysParams}`;
    }
    if (searchParam === "") {
      apiUrl += `?${daysParams}`;
    }
    return this.instance
      .get<OngoingOrderResponseProps>(apiUrl, {
        params: {
          merchant_id,
          start_date,
          end_date,
          page_number,
          page_size,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  OnlineUsersListApi(
    page_number: number,
    searchParam: string,
    page_size: number = 20
  ): Promise<OnlineUserResponseProps> {
    let apiUrl = "/online/users/report";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    return this.instance
      .get<OnlineUserResponseProps>(apiUrl, {
        params: {
          page_number,
          page_size,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  orderAndBroadcastListApi(
    merchant_id: number,
    start_date: string | undefined,
    end_date: string | undefined,
    locality_id: string[],
    page_number: number,
    searchParam: string,
    page_size: number = 20
  ): Promise<OrderAndBroadcastResponseProps> {
    let apiUrl = "/orders/broadcasts/details";
    const daysParams =
      locality_id && locality_id.length > 0
        ? locality_id.map((loc) => `locality_id=${loc}`).join("&")
        : "";
    if (searchParam) {
      apiUrl += `${searchParam}&${daysParams}`;
    }
    if (searchParam === "") {
      apiUrl += `?${daysParams}`;
    }
    return this.instance
      .get<OrderAndBroadcastResponseProps>(apiUrl, {
        params: {
          merchant_id,
          start_date,
          end_date,
          page_number,
          page_size,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  orderTimeStampList(
    merchant_id: number,
    start_date: string | undefined,
    end_date: string | undefined,
    locality_id: string[],
    page_number: number,
    searchParam: string,
    page_size: number = 20
  ): Promise<OrderTimeStampResponseProps> {
    let apiUrl = "/order/timestamp/details";
    const daysParams =
      locality_id && locality_id.length > 0
        ? locality_id.map((loc) => `locality_id=${loc}`).join("&")
        : "";
    if (searchParam) {
      apiUrl += `${searchParam}&${daysParams}`;
    }
    if (searchParam === "") {
      apiUrl += `?${daysParams}`;
    }
    return this.instance
      .get<OrderTimeStampResponseProps>(apiUrl, {
        params: {
          merchant_id,
          start_date,
          end_date,
          page_number,
          page_size,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  orderCardDetails(
    merchant_id: number,
    start_date: string | undefined,
    end_date: string | undefined,
    locality_id: string[]
  ): Promise<OrderCardResponseProps> {
    let apiUrl = "/cards/info";
    const daysParams =
      locality_id && locality_id.length > 0
        ? locality_id.map((loc) => `locality_id=${loc}`).join("&")
        : "";
    if (daysParams) {
      apiUrl += `?${daysParams}`;
    }
    return this.instance
      .get<OrderCardResponseProps>(apiUrl, {
        params: {
          merchant_id,
          start_date,
          end_date,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  cityListApi(): Promise<CityResponseProps> {
    let apiUrl = "/city_code/details";
    return this.instance.get<CityResponseProps>(apiUrl).then((e) => {
      return e.data;
    });
  }
  localityListApi(): Promise<LocalityResponseProps> {
    let apiUrl = "/locality/details";
    return this.instance.get<LocalityResponseProps>(apiUrl).then((e) => {
      return e.data;
    });
  }
}
