import React from "react";
import { Box, Button } from "@mui/material";

export default function AddMoneyCard() {
  return (
    <>
      <Box
        sx={{
          "& > :not(style)": {
            m: 1,
            width: "100%",
            height: "100%",
            backgroundColor: "#0079FF",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 7px 29px 0px #64646F33",
            borderRadius: "8px",
          },
        }}
      >
        <div className="">
          <div className="pt-8">
            <p className="text-[#FFFFFF] text-sm font-normal">
              Add Money to Wallet
            </p>
          </div>
          <div className="pt-3">
            <Button
              className=""
              variant="contained"
              sx={{
                textTransform: "none",
                px: 4,
                bgcolor: "#FFFFFF",
                "&:hover": { bgcolor: "#FFFFFF" },
                fontSize: "14px",
                borderRadius: "30px",
                color: "#0079FF",
                boxShadow: "none",
              }}
            >
              <img
                src="/assets/icons/bluePlusIcon.svg"
                alt=""
                className="mr-2"
              />
              Add Money
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
}
