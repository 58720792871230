import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import "./style.css";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from "@mui/x-data-grid-generator";

const roles = ["Market", "Finance", "Development"];
const randomRole = () => {
  return randomArrayItem(roles);
};

const initialRows: GridRowsProp = [
  //   {
  //     id: randomId(),
  //     name: randomTraderName(),
  //     age: 25,
  //     joinDate: randomCreatedDate(),
  //     role: randomRole(),
  //   },
  //   {
  //     id: randomId(),
  //     name: randomTraderName(),
  //     age: 36,
  //     joinDate: randomCreatedDate(),
  //     role: randomRole(),
  //   },
  //   {
  //     id: randomId(),
  //     name: randomTraderName(),
  //     age: 19,
  //     joinDate: randomCreatedDate(),
  //     role: randomRole(),
  //   },
  //   {
  //     id: randomId(),
  //     name: randomTraderName(),
  //     age: 28,
  //     joinDate: randomCreatedDate(),
  //     role: randomRole(),
  //   },
  //   {
  //     id: randomId(),
  //     name: randomTraderName(),
  //     age: 23,
  //     joinDate: randomCreatedDate(),
  //     role: randomRole(),
  //   },
];

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        name: "",
        age: "",
        quantity: null,
        price: 0, // Set the default price to 0
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        sx={{ textTransform: "none", color: "#06B681" }}
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Add Items
      </Button>
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const totalRow: GridRowModel = {
    id: "total",
    name: "Total",
    quantity: null,
    i: "Total",
    price: rows.reduce(
      (total, row) => total + (row.price || 0) * Math.max(row.quantity || 1, 1),
      0
    ),
    isNew: false,
    editable: false,
    isRowSelectable: false,
    isTotalRow: true,
  };
  const isRowEditable = (params: any) => {
    // Disable editing for the "Total" row
    return params.id !== "total";
  };

  const columns: GridColDef[] = [
    {
      field: "i",
      headerName: "#",
      width: 110,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "description",
      headerName: "Description",
      width: 280,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "custom-header",
      editable: true,
      renderEditCell: (params) => {
        const isTotalRow = params.id === "total";

        if (isTotalRow) {
          return <div></div>;
        }

        return (
          <div className="flex items-center">
            <button
              onClick={() =>
                params.api.setEditCellValue({
                  id: params.id,
                  field: "quantity",
                  value: Math.max(1, params.value - 1),
                })
              }
            >
              <img src="/assets/icons/minus-icon.svg" alt="" />
            </button>
            <span className="mx-2">X {params.value}</span>
            <button
              onClick={() =>
                params.api.setEditCellValue({
                  id: params.id,
                  field: "quantity",
                  value: params.value + 1,
                })
              }
            >
              <img src="/assets/icons/plus-icon.svg" alt="" />
            </button>
          </div>
        );
      },
    },
    {
      field: "size",
      headerName: "Size",
      width: 140,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      headerAlign: "left",
      width: 140,
      headerClassName: "custom-header",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "custom-header",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (id === "total") {
          return [];
        }

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon sx={{ color: "#0079FF" }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: "#F94932" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        fontFamily: "inter",
        minHeight: 200,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        "& .custom-header": {
          fontWeight: 500,
          fontSize: "14px",
          fontFamily: "inter",
          color: "#ACACAC",
        },
        "& .total-row": {
          fontWeight: 600,
          fontSize: "16px",
          backgroundColor: "#414141",
        },
      }}
    >
      <DataGrid
        rows={[...rows, totalRow]}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableRowSelectionOnClick
        hideFooterPagination
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        components={{
          NoRowsOverlay: () => (
            <div style={{ textAlign: "center", padding: "20px" }}>
              No records available.
            </div>
          ),
        }}
        sx={{ border: "none" }}
      />
      <div className="flex justify-end">
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            bgcolor: "#171F21",
            py: 1,
            px: 3,
            "&:hover": { bgcolor: "#171F21" },
            borderRadius: "50px",
            fontSize: "18px",
          }}
        >
          Create Order
        </Button>
      </div>
    </Box>
  );
}
