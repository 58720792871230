import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CSVLink } from "react-csv";
import { RiderSupplyHealthApi } from "../../../API/RiderSupplyHealth";

export default function RiderSupplyTable({
  riderStatus,
  polygonIds,
}: {
  riderStatus: number;
  polygonIds: string[];
}) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [blacklistedRidersData, setBlacklistedRidersData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [riderCSVInfo, setRiderCSVInfo] = useState<any>();
  const pageRange = 5;
  function formatDateTime(datetime: string): string {
    const [date, time] = datetime.split(" ");
    return `${date} | ${time}`;
  }
  const getStatusChipData = (
    riderStatus: number
  ): { text: string; color: string; bgcolor: string } => {
    switch (riderStatus) {
      case 1:
        return {
          text: "On Duty",
          color: "#00CB60",
          bgcolor: "#00CB6033",
        };
      case 2:
        return {
          text: "Active",
          color: "#8B97FF",
          bgcolor: "#8B97FF33",
        };
      case 3:
        return {
          text: "Dedicated",
          color: "#171F21",
          bgcolor: "#171F2133",
        };
      case 4:
        return {
          text: "Stale",
          color: "#FFB846",
          bgcolor: "#FFB84633",
        };
      case 5:
        return {
          text: "Busy",
          color: "#F94932",
          bgcolor: "#F9493233",
        };
      default:
        return { text: "", color: "#fff", bgcolor: "#ffffff26" };
    }
  };
  const formatCSVData = (data: any) => {
    return data.map((row: any) => ({
      ...row,
      status: getStatusChipData(row.status).text,
    }));
  };

  useEffect(() => {
    const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
    RiderSupplyHealthApi.api
      .riderSupplyList(riderStatus, polygonIds, apiUrl, page + 1)
      .then((e) => {
        setBlacklistedRidersData(e.data.riderSupplyHealthDetails);
        if (e.data && Array.isArray(e.data.riderSupplyHealthDetails)) {
          setBlacklistedRidersData(e.data.riderSupplyHealthDetails);
          setTotalCount(e.data.listCount);
        } else {
          setShowSnackbar(true);
          setBlacklistedRidersData([]);
        }
      })
      .catch((error) => {
        setShowSnackbar(true);
        console.error("API call error: ", error);
      });
  }, [riderStatus, polygonIds, searchVal, page]);
  useEffect(() => {
    const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
    RiderSupplyHealthApi.api
      .riderSupplyListExport(riderStatus, polygonIds, apiUrl)
      .then((e) => {
        setRiderCSVInfo(formatCSVData(e.data));
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, [riderStatus, polygonIds]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    if (pageCount === 1) {
      setPage(0);
    } else {
      const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
      setPage(newPageNumber);
    }
  };
  const header = [
    { label: "Rider ID", key: "riderId" },
    { label: "Rider Name", key: "riderName" },
    { label: "Rider Mobile", key: "mobileNumber" },
    { label: "Locality", key: "localityName" },
    { label: "Status", key: "status" },
    { label: "Last Seen", key: "updateAt" },
  ];
  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <>
      {/* {loading ? (
        <LinearProgress
          sx={{
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#00CD5B",
            },
          }}
        />
      ) : ( */}
      <>
        <TableContainer className="">
          <div className="flex items-center justify-between py-4 px-3 border-slate-200 border-solid">
            <div>
              <Typography className="font-bold text-[#414141]">
                {getStatusChipData(riderStatus).text} Riders
              </Typography>
            </div>

            <div className="flex">
              <div className="border-2 rounded-2xl px-4">
                <InputBase
                  type="number"
                  placeholder="Search by Rider ID"
                  className="flex-grow"
                  inputProps={{ "aria-label": "search" }}
                  value={searchVal}
                  onChange={(search) => setSearchVal(search.target.value)}
                />
                <SearchIcon
                  style={{ color: "#727597" }}
                  className=" cursor-pointer ml-4"
                />
              </div>
              <div className="ml-2">
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    bgcolor: "#06B681",
                    px: 2,
                    mr: 2,
                    "&:hover": { bgcolor: "#06B681" },
                    borderRadius: "50px",
                    fontSize: "14px",
                  }}
                  disabled={searchVal !== null && searchVal.trim() !== ""}
                >
                  <CSVLink
                    data={riderCSVInfo || []}
                    headers={header}
                    filename={`Rider_info.csv`}
                  >
                    Export All Rider Details
                  </CSVLink>
                </Button>
              </div>
            </div>
          </div>
          <hr />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Rider ID
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Rider Name
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Mobile No
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Locality
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Last Seen
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blacklistedRidersData?.map((row: any, index: any) => (
                <React.Fragment key={index}>
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.riderId}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.riderName}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.mobileNumber}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.localityName}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <Chip
                        label={`${getStatusChipData(row.status).text}`}
                        size="small"
                        style={{
                          backgroundColor: getStatusChipData(row.status)
                            .bgcolor,
                          color: getStatusChipData(row.status).color,
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {formatDateTime(row.updateAt)}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="pt-4 pb-2 pr-5">
          <div
            className="pagination"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="flex">
              <img
                src="/assets/icons/leftArrow.svg"
                alt=""
                onClick={() => handlePageChange(page - 1)}
              />
              {renderPageNumbers()}
              <img
                src="/assets/icons/rightArrow.svg"
                alt=""
                onClick={() => handlePageChange(page + 1)}
                className="ml-1 mr-6"
              />
            </div>
          </div>
        </div>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
      {/* )} */}
    </>
  );
}
