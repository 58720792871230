import React, { useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Box,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OrderStatusApi } from "../../API/OrderStatus";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  text: string;
  color: string;
  bgcolor: string;
  status: number;
  orderNumber: number;
  orderStatus: number;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 1,
  px: 3,
};

const ModalComponent: React.FC<ModalProps> = ({
  open,
  onClose,
  text,
  color,
  bgcolor,
  status,
  orderNumber,
  orderStatus,
}) => {
  const [value, setValue] = React.useState("0");
  const [selected, setSelected] = useState();
  const [communicationFlag, setCommunicationFlag] = React.useState<number>(0);
  const [comments, setComments] = useState<string>("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommunicationFlag(parseInt(event.target.value));
  };
  const handleComments = (e: any) => {
    setComments(e.target.value);
  };
  const handleConfirmUpdate = () => {
    OrderStatusApi.api
      .updateOrderStatus(orderNumber, status, communicationFlag, comments)
      .then((response) => {
        setShowSuccessSnackbar(true);
        setSnackbarMessage("Order status updated successfully");
        onClose();
      })
      .catch((error) => {
        console.error("Error updating order status:", error);
        setShowErrorSnackbar(true);
        setSnackbarMessage(
          error.response.data.message || "Could not update order status"
        );
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-end mb-1">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="px-6">
            <Typography
              id="modal-modal-title"
              className="text-[#414141] text-xl text-center mb-4"
            >
              Status
            </Typography>
            <div className="flex justify-center mt-2">
              <div
                className="flex items-center justify-between cursor-pointer rounded-[50px] px-2 py-1"
                style={{ backgroundColor: bgcolor, color: color }}
              >
                <span className="pr-4"> {text || "Open Popover"} </span>
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.17711 8.79702C8.41664 9.48102 7.26264 9.48102 6.50217 8.79702L0.876442 3.737C-0.486595 2.51103 0.38064 0.25 2.21391 0.25L13.4654 0.25C15.2986 0.25 16.1659 2.51103 14.8028 3.737L9.17711 8.79702Z"
                    fill={color}
                  />
                </svg>
              </div>
            </div>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, px: 2, pb: 2 }}
              className="text-[#414141] text-xl text-center"
            >
              Do you want to communicate the changes to the client ?
            </Typography>
            <div>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={communicationFlag}
                onChange={handleChange}
              >
                <div className="flex justify-center">
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        sx={{
                          color: "#171F21",
                          "&.Mui-checked": {
                            color: "#171F21",
                          },
                        }}
                      />
                    }
                    sx={{ mr: 6 }}
                    label="No"
                  />
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        sx={{
                          color: "#171F21",
                          "&.Mui-checked": {
                            color: "#171F21",
                          },
                        }}
                      />
                    }
                    label="Yes"
                  />
                </div>
              </RadioGroup>
            </div>
            <div className="py-3">
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={5}
                placeholder="Remarks"
                fullWidth
                onChange={handleComments}
              />
            </div>
            <div className="flex justify-center pb-4">
              <Button
                variant="contained"
                onClick={handleConfirmUpdate}
                disabled={!comments}
                sx={{
                  mt: 2,
                  color: "white",
                  bgcolor: "#171F21",
                  borderRadius: "50px",
                  textTransform: "none",
                  py: 1,
                  px: 8,
                  "&:hover": { bgcolor: "#171F2180" },
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ModalComponent;
