import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

interface Rider {
  userid: string;
  latitude: string;
  longitude: string;
  rider_status: "Free Rider" | "Busy Rider" | "Stale Rider" | "Dedicated Rider";
}

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const libraries: "places"[] = ["places"];

const iconUrls = {
  "Free Rider": "/assets/icons/blue-map-icon.svg",
  "Busy Rider": "/assets/icons/red-map-icon.svg",
  "Stale Rider": "/assets/icons/yellow-map-icon.svg",
  "Dedicated Rider": "/assets/icons/black-map-icon.svg",
};

function GoogleRiderSupplyMapApi({
  riderData,
  mapProps = {},
  children = <></>,
  onMarkerClick,
}: {
  riderData: Rider[];
  mapProps?: any;
  children?: React.ReactNode;
  onMarkerClick: (rider: Rider) => void;
}) {
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 28.7136, lng: 77.1294 });

  useEffect(() => {
    const scriptLoaded = window.google && window.google.maps;
    setIsApiLoaded(!!scriptLoaded);
  }, []);
  useEffect(() => {
    const calculateCenter = (data: any[]) => {
      if (data.length === 0) return;

      const latSum = data.reduce(
        (acc, curr) => acc + parseFloat(curr?.latitude),
        0
      );
      const lngSum = data.reduce(
        (acc, curr) => acc + parseFloat(curr?.longitude),
        0
      );
      const avgLat = latSum / data.length;
      const avgLng = lngSum / data.length;

      setMapCenter({ lat: avgLat, lng: avgLng });
    };

    calculateCenter(riderData);
  }, [riderData]);
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg"
      libraries={libraries}
      onLoad={() => setIsApiLoaded(true)}
    >
      {isApiLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={12}
          {...mapProps}
        >
          {riderData.map((rider, index) => (
            <Marker
              key={index}
              position={{
                lat: parseFloat(rider.latitude),
                lng: parseFloat(rider.longitude),
              }}
              icon={{
                url: iconUrls[rider.rider_status],
              }}
              options={{
                zIndex: rider.rider_status === "Dedicated Rider" ? 999 : 1,
              }}
              onClick={() => onMarkerClick(rider)}
            />
          ))}
        </GoogleMap>
      ) : (
        <div>Loading map...</div>
      )}
      {children}
    </LoadScript>
  );
}

export default GoogleRiderSupplyMapApi;
