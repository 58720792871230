import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  extraOrderDetailsListResponse,
  frontPageOrderDetailsListResponse,
} from "../@Types/DRO/HomePageResponse";
import { OrderResponse } from "../@Types/DRO/CancelOrderResponse";
export class OrderListApi {
  public static api = new OrderListApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://stag-ood.bcykal.com/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }

  frontPageOrderDetailsList(
    merchantId: number,
    startDate: string | undefined,
    endDate: string | undefined,
    status: string,
    page: number,
    searchParam: string,
    pageSize: number = 20
  ): Promise<AxiosResponse<frontPageOrderDetailsListResponse>> {
    let apiUrl = "mobycy/on-demand/dashboard/v1/order-details";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    return this.instance.get<frontPageOrderDetailsListResponse>(apiUrl, {
      params: {
        merchantId,
        startDate,
        endDate,
        status,
        page,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  extraOrderDetailsList(
    orderNumber: number
  ): Promise<AxiosResponse<extraOrderDetailsListResponse>> {
    let apiUrl = "mobycy/on-demand/dashboard/v1/oms-order-detail";
    return this.instance.get<extraOrderDetailsListResponse>(apiUrl, {
      params: {
        orderNumber,
      },
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  tagAgent(orderNumber: number): Promise<any> {
    let apiUrl = `mobycy/on-demand/dashboard/v1/tag-agent?orderNumber=${orderNumber}`;
    return this.instance.post<any>(apiUrl, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
}
