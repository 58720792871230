import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BlacklistedRidersApi } from "../../../API/BlacklistedRiders";
import UnbanRidersModal from "../../Modals/UnbanRidersModal";

export default function BlacklistedRidersTable() {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>("1");
  const [blacklistedRidersData, setBlacklistedRidersData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const pageRange = 5;

  useEffect(() => {
    const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
    BlacklistedRidersApi.api
      .blacklistedRidersList(selectedStatus, page + 1, apiUrl)
      .then((e) => {
        setBlacklistedRidersData(e.data.data.restrictedRiders);
        if (e.data && Array.isArray(e.data.data.restrictedRiders)) {
          setBlacklistedRidersData(e.data.data.restrictedRiders);
          setTotalCount(e.data.data.listCount);
        } else {
          setShowSnackbar(true);
          setBlacklistedRidersData([]);
        }
      })
      .catch((error) => {
        setShowSnackbar(true);
        console.error("API call error: ", error);
      });
  }, [selectedStatus, searchVal, page]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    if (pageCount === 1) {
      setPage(0);
    } else {
      const newPageNumber = Math.max(0, Math.min(newPage, pageCount - 1));
      setPage(newPageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <>
      {/* {loading ? (
        <LinearProgress
          sx={{
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#00CD5B",
            },
          }}
        />
      ) : ( */}
      <>
        <TableContainer className="">
          <div className="flex items-center justify-between py-4 px-3 border-slate-200 border-solid">
            <div>
              <Typography className="font-bold text-[#414141]">
                Blacklisted Riders{" "}
              </Typography>
            </div>

            <div className="flex">
              <div className="border-2 rounded-2xl px-2 mt-1 mr-2">
                <label className="text-[#727597] text-sm font-normal font-inter">
                  Status :{" "}
                </label>
                <select
                  name="orderStatus"
                  id="orderStatus"
                  className="text-[#727597] text-sm font-normal font-inter px-1 py-1 min-w-[150px]"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="1">All</option>
                  <option value="0">Temporary</option>
                  <option value="-1">Permanent</option>
                </select>
              </div>
              <div className="border-2 rounded-2xl px-4">
                <InputBase
                  type="number"
                  placeholder="Search by User ID"
                  className="flex-grow"
                  inputProps={{ "aria-label": "search" }}
                  value={searchVal}
                  onChange={(search) => setSearchVal(search.target.value)}
                />
                <SearchIcon
                  style={{ color: "#727597" }}
                  className=" cursor-pointer ml-4"
                />
              </div>
            </div>
          </div>
          <hr />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  User Id
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Rider Name
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Rider Mobile No.
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Ban Type
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Banned By
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Rider City
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Time Stamp
                </TableCell>
                <TableCell
                  style={{
                    color: "#ACACAC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blacklistedRidersData?.map((row: any, index: any) => (
                <React.Fragment key={index}>
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.userId}
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.riderName}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.riderMobileNo}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <Chip
                        label={`${row.restrictType}`}
                        size="small"
                        style={{
                          backgroundColor: "#FEF3EB",
                          color: "#FF543E",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.restrictBy}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.riderCity}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.restrictTimeStamp}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#414141",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <UnbanRidersModal row={row} />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="pt-4 pb-2 pr-5">
          <div
            className="pagination"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="flex">
              <img
                src="/assets/icons/leftArrow.svg"
                alt=""
                onClick={() => handlePageChange(page - 1)}
              />
              {renderPageNumbers()}
              <img
                src="/assets/icons/rightArrow.svg"
                alt=""
                onClick={() => handlePageChange(page + 1)}
                className="ml-1 mr-6"
              />
            </div>
          </div>
        </div>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
      {/* )} */}
    </>
  );
}
